// Init Api
import initApi from "../api/config";

// Saga effect
import {all, takeLatest} from "redux-saga/effects";

// Services Sagas
import {settingsSagaStart} from "saga/settings";

// Pages Sagas
import {mainSagaStart} from "pages/main/saga";
import {authSagaStart} from "pages/auth/saga";
import {ordersStart} from "pages/orders/saga";
import {checkoutSagaStart} from "pages/checkout/saga";
import {projectsSagaStart} from "pages/projects/saga";
import {searchSagaStart} from "pages/search/saga/saga";
import {thesaurusSagaStart} from "pages/thesaurus/saga";
import {librariesSagaStart} from "pages/libraries/saga";
import {requestSagaStart} from "pages/request-form/saga";
import {contactUsSagaStart} from "pages/contact-us/saga";
import {sagaAccountStart} from "pages/account/sagas/saga";
import {moleculeProductStart} from "pages/molecule-product/saga";
import {resetPasswordSagaStart} from "pages/reset-password/saga";
import {forgotPasswordSagaStart} from "pages/forgot-password/saga";
import {librariesProductSagaStart} from "pages/libraries-product/saga";
import {librariesCompoundsSagaStart} from "pages/libraries-compounds/saga";

/* ------------- Types To Sagas ------------- */
export default function* root(store: object) {
	const Api = initApi(store);

	yield all([
		// Services
		takeLatest("STARTUP", settingsSagaStart, Api),

		// Pages
		takeLatest("STARTUP", ordersStart, Api),
		takeLatest("STARTUP", mainSagaStart, Api),
		takeLatest("STARTUP", authSagaStart, Api),
		takeLatest("STARTUP", searchSagaStart, Api),
		takeLatest("STARTUP", requestSagaStart, Api),
		takeLatest("STARTUP", sagaAccountStart, Api),
		takeLatest("STARTUP", checkoutSagaStart, Api),
		takeLatest("STARTUP", projectsSagaStart, Api),
		takeLatest("STARTUP", librariesSagaStart, Api),
		takeLatest("STARTUP", contactUsSagaStart, Api),
		takeLatest("STARTUP", thesaurusSagaStart, Api),
		takeLatest("STARTUP", moleculeProductStart, Api),
		takeLatest("STARTUP", resetPasswordSagaStart, Api),
		takeLatest("STARTUP", forgotPasswordSagaStart, Api),
		takeLatest("STARTUP", librariesProductSagaStart, Api),
		takeLatest("STARTUP", librariesCompoundsSagaStart, Api),
	]);
};
