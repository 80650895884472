import amplitude from "amplitude-js";

export const events = {
  session_start: 'session_start',
  forgot_password: 'forgot_password',
  sign_up: 'sign_up',
  error_page: 'error_page',
  cookie_notice_popup: 'cookie_notice_popup',
  cookie_notice_accept: 'cookie_notice_accept',
  contact_us: 'contact_us',
  privacy_policy: 'privacy_policy',
  home_click: 'home_click',
  login_click: 'login_click',
  login_success: 'login_success',
  login_failure: 'login_failure',
  logout: 'logout',
  profile_changepass_click: 'profile_changepass_click',
  donwload_sdf_click: 'donwload_sdf_click',
  donwload_xls_click: 'donwload_xls_click',
  donwload_pdf_click: 'donwload_pdf_click',

  molecule_product: 'molecule_product',
  buy_on_enamine_store: 'buy_on_enamine_store',

  compound_libraries: 'compound_libraries',
  library: 'library',
  services: 'services',
  search: 'search',
  publications: 'publications',
  terms_of_service: 'terms_of_service',

  request_form: 'request_form',
  request_form_send: 'request_form_send',
  not_found: 'not_found',
  verify_user: 'verify_user',

  resend_verify: 'resend_verify',
  resend_verify_error: 'resend_verify_error',
  resend_verify_success: 'resend_verify_success',

  about: 'about',
  create_project: 'create_project',
  add_molecule_to_project: 'add_molecule_to_project',
};

// Key
export const hjsv = 6;
export const hjid = 3410311;
export const REACT_APP_AMPLITUDE_KEY = '213194e2087c58dbef010bdb592febd0';
export const amplitudeInstance = amplitude.getInstance();

export default {
  events,
  amplitudeInstance,
}