import {call, put} from "redux-saga/effects";

// Actions
import * as actionsCreator from "pages/search/reducer";

// Small sagas request to search by compounds
export function* searchRequest(api: any, data: any): Generator<any, void, any> {
	try {
		return yield call(api.request, 'search', data);
	} catch (e) {
		console.log('ERROR searchRequest: !!!', e);
	}
}

// Small sagas request to search by libraries
export function* librariesRequest(api: any, data: any): Generator<any, void, any> {
	try {
		return yield call(api.request, 'searchLib', data);
	} catch (e) {
		console.log('ERROR librariesRequest: !!!', e);
	}
}

// Small saga request to compound substructure search
export function* compoundsSubstructureRequest(api: any, data: any): Generator<any, void, any> {
	try {
		const res = yield call(api.request, 'compoundsSubstructure', data.payload);

		if (!res?.ok) {
			yield put(actionsCreator.compoundsSubstructureError());
		} else {
			yield put(actionsCreator.compoundsSubstructureSuccess(res.data || {}));
		}
	} catch (e) {
		console.log('ERROR compoundsSubstructureRequest: !!!', e);
	}
}

// Small saga request to compound similarity search
export function* compoundsSimilarityRequest(api: any, data: any): Generator<any, void, any> {
	try {
		const res = yield call(api.request, 'compoundsSimilarity', data.payload);

		if (!res?.ok) {
			yield put(actionsCreator.compoundsSimilarityError());
		} else {
			yield put(actionsCreator.compoundsSimilaritySuccess(res.data || {}));
		}
	} catch (e) {
		console.log('ERROR compoundsSimilarityRequest: !!!', e);
	}
}

// Small saga request to compound similarity search
export function* compoundsFullfragmentRequest(api: any, data: any): Generator<any, void, any> {
	try {
		const res = yield call(api.request, 'compoundsFullfragment', data.payload);

		if (!res?.ok) {
			yield put(actionsCreator.compoundsFullfragmentError());
		} else {
			yield put(actionsCreator.compoundsFullfragmentSuccess(res.data || {}));
		}
	} catch (e) {
		console.log('ERROR compoundsFullfragmentRequest: !!!', e);
	}
}

// Small saga request to linkers substructure search
export function* linkersSubstructureRequest(api: any, data: any): Generator<any, void, any> {
	try {
		const res = yield call(api.request, 'linkersSubstructure', data.payload);

		if (!res?.ok) {
			yield put(actionsCreator.linkersSubstructureError());
		} else {
			yield put(actionsCreator.linkersSubstructureSuccess(res.data || {}));
		}
	} catch (e) {
		console.log('ERROR linkersSubstructureRequest: !!!', e);
	}
}

// Small saga request to linkers similarity search
export function* linkersSimilarityRequest(api: any, data: any): Generator<any, void, any> {
	try {
		const res = yield call(api.request, 'linkersSimilarity', data.payload);

		if (!res?.ok) {
			yield put(actionsCreator.linkersSimilarityError());
		} else {
			yield put(actionsCreator.linkersSimilaritySuccess(res.data || {}));
		}
	} catch (e) {
		console.log('ERROR linkersSimilarityRequest: !!!', e);
	}
}

// Small saga request to linkers similarity search
export function* linkersFullfragmentRequest(api: any, data: any): Generator<any, void, any> {
	try {
		const res = yield call(api.request, 'linkersFullfragment', data.payload);

		if (!res?.ok) {
			yield put(actionsCreator.linkersFullfragmentError());
		} else {
			yield put(actionsCreator.linkersFullfragmentSuccess(res.data || {}));
		}
	} catch (e) {
		console.log('ERROR linkersFullfragmentRequest: !!!', e);
	}
}
