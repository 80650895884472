import React, {useCallback, useEffect, useState} from "react";

// Redux connect
import {useAppSelector} from "lib/hooks/useDispatch";

// Redirect
import {shallowEqual} from "react-redux";
import {Link, useNavigate} from "react-router-dom";

// Images
import IconMenu1 from "assets/img/icon-menu-1.png";
import IconMenu2 from "assets/img/icon-menu-2.png";
import IconMenu3 from "assets/img/icon-menu-3.png";
import IconMenu4 from "assets/img/icon-menu-4.png";

// Components
import Img from "components/Img";
import Column from "../menu/сolumn";

// Classnames
import clsx from "clsx";

// Interface
interface IBurgerMenu {
	isScrolling?: boolean,
}

const BurgerMenu: React.FC<IBurgerMenu> = (props) => {
	const navigate = useNavigate();
	const {isScrolling} = props;

	const user = useAppSelector(
		(store) => store.settings.user,
		shallowEqual
	);
	const diseasesData = useAppSelector(
		(store) => store.libraries.diseases,
		shallowEqual
	);
	const bioActiveData = useAppSelector(
		(store) => store.libraries.bioActiveScreen,
		shallowEqual
	);
	const drugRepurposingData = useAppSelector(
		(store) => store.libraries.drugRepurposing,
		shallowEqual
	);
	const signalingPathwaysData = useAppSelector(
		(store) => store.libraries.signalingPathways,
		shallowEqual
	);

	const [toggle, setToggle] = useState<boolean>(false);
	const [isActive, setIsActive] = useState<boolean>(false);

	useEffect(() => {
		document.body.style.overflow = isActive ? 'hidden' : 'auto';
	}, [isActive]);

	useEffect(() => {
		setIsActive(false);

		return () => {
			setToggle(false);
			setIsActive(false);
			document.body.style.overflow = 'auto';
		};
	}, [navigate]);

	const handlerClick = useCallback(() => {
		setIsActive(!isActive);
	}, [isActive]);

	const handlerClickToggleItem = useCallback(() => {
		setToggle(!toggle);
	}, [toggle]);

	const configMenu = [
		{
			src: IconMenu1,
			calcNumber: 0.3,
			alt: 'Icon image 1',
			data: signalingPathwaysData,
			title: 'Signaling Pathways and Protein<br/> Classes Related Libraries',
		},
		{
			src: IconMenu2,
			calcNumber: 0.7,
			alt: 'Icon image 2',
			data: bioActiveData,
			children: {
				src: IconMenu4,
				calcNumber: 0.7,
				data: diseasesData,
				alt: 'Icon image 4',
				title: 'Disease Related Compound Libraries',
			},
			title: 'Bioactive Screening Compound<br/> Libraries',
		},
		{
			src: IconMenu3,
			calcNumber: 0.9,
			alt: 'Icon image 3',
			data: drugRepurposingData,
			title: 'Drug Repurposing<br/> and Related Libraries',
		},
	];

	const auth = !!user.authorized;

	const cnLi = clsx(
		auth ? `nav-burger__border mt10` : 'mt10',
	);

	const cnWrapper = clsx(
		"burger-menu",
		isScrolling ? 'burger-menu--active' : '',
	);

	return (
		<div className={cnWrapper}>
			<div
				onClick={handlerClick}
				className={`menu-btn ${isActive ? 'active' : ''}`}
			>
				<span className="bar"></span>
				<span className="bar"></span>
				<span className="bar"></span>
			</div>

			<div className={`nav-burger ${isActive ? 'active' : ''}`}>
				<ul className="nav-burger__list">
					<li className="nav-burger__list-item">
						<div
							onClick={handlerClickToggleItem}
							className='nav-burger__border flex flex-ai-center flex-jc-between'
						>
							<p className="nav-burger__list-item-title">
								Compound libraries
							</p>
							<Img
								alt='Arrow active'
								className='nav-burger__arrow'
								name={toggle ? 'arrowActive' : 'arrowDefault'}
							/>
						</div>
						{toggle && (
							<div className='nav-burger__library flex flex-wrap flex-direction-column-mobile'>
								{configMenu.map((item, i) => {
									return <Column
										src={item.src}
										alt={item.alt}
										data={item.data}
										title={item.title}
										imgClass='nav-burger__img'
										titleClass='nav-burger__h4'
										key={`${item.title}_${i}`}
										linkClass='nav-burger__link'
										calcNumber={item.calcNumber}
										children={item?.children || {}}
										wrapperClass='flex flex-ai-start flex-direction-column nav-burger__col'
										ulClass={`nav-burger__ul-menu flex-direction-column ${item?.children && item.calcNumber === 0.7 ? 'nav-burger__col' : ''}`}
									/>
								})}
							</div>
						)}
					</li>
					<li className="nav-burger__border mt10">
						<Link to='/' className="nav-burger__list-item">
							<p className="nav-burger__list-item-title">
								EBC main page
							</p>
						</Link>
					</li>
					<li className="nav-burger__border mt10">
						<Link to='/search' className="nav-burger__list-item">
							<p className="nav-burger__list-item-title">
								Search
							</p>
						</Link>
					</li>
					<li className={cnLi}>
						<Link to='/services' className="nav-burger__list-item">
							<p className="nav-burger__list-item-title">
								Services
							</p>
						</Link>
					</li>
					{auth && <li className="mt10">
            <Link to='/projects' className="nav-burger__list-item">
              <p className="nav-burger__list-item-title">
                Projects
              </p>
            </Link>
          </li>}
				</ul>
			</div>
		</div>
	);
};

export default BurgerMenu;
