export const url = {
  getLibAll: 'libs',
  signup: '/user/signup',
  signin: '/user/signin',
  contactUs: 'contact-us',
  getLibFullDetails: 'libs/',
  userVerify: '/user/verify',
  logout: '/user/refresh-token',
  getHighlightLib: 'highlights/lib/',
  getHighlightCarousel: '/highlights/landing',
  bioInfoRequest: 'bio-info-request',
  refreshToken: '/user/refresh-token',
  resetPassword: 'user/reset-password',
  getCompoundsCount: '/libs/compounds-count',
  resetPasswordRequest: 'user/reset-password/request',

  img: 'libs/media/img/',
  download: 'libs/media/download',
  downloadLib: convertLibsUrl,
  smi2pict: '/smi2pict',
  chemutils: '/chemutils/smiles-image',

  refresh: 'user/access-token/refresh',

  resendMail: 'user/resend-mail/',

  searchLib: '/libs/search',
  search: '/ebc/search-released',
  getSearchCompoundById: 'ebc/catalog/',
  chemutilsConvertStructure: 'chemutils/convert-from-mrv',

  compoundsSimilarity: '/ebc/structure-search/similarity',
  compoundsSubstructure: '/ebc/structure-search/substructure',
  compoundsFullfragment: '/ebc/structure-search/fullfragment',

  linkersSimilarity: 'linkers/structure-search/similarity',
  linkersFullfragment: 'linkers/structure-search/fullfragment',
  linkersSubstructure: 'linkers/structure-search/substructure',

  projects: '/profile/projects',

  getOrders: '/profile/orders',
  addresses: '/profile/addresses',

  profileUpdate: 'profile/users/current',
  profile: 'profile/users/current/avatar',

  getTargetDetailsById: '/targets/',
  getBaDataTargets: '/targets/ba-data',
  getThesaurusById: '/targets/thesaurus',
  searchTesaurus: '/targets/thesaurus/search',

  baDataMainActivity: '/ba-data/main-activity',

  drugImpurity: '/ebc/drug-impurity',

  getCansByEbcId: (id: string) => `/ebc/catalog/${id}/cans`,
  getSolubilitiesByEbcId: (id: string) => `/ebc/catalog/${id}/solubilities`,
};

function convertLibsUrl({catNumber, type}: {catNumber: string, type: string}) {
  return `/libs/${catNumber}/files/${type}`;
}

export default url;