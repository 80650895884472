import {all, call, fork, put, select, take} from "redux-saga/effects";

// Actions
import * as actionsCreator from "./reducer";

// Lib
import {RootState} from "../../store";
import {convertDataPrices} from 'lib/helpers';

// Constants
import {mainActivity} from "pages/molecule-product/reducer";

// Saga helper
import {
	getCansByEbcIdRequest,
	getCompoundByIdRequest,
	getDrugImpurityRequest,
	getMainActivityRequest
} from './saga-helper';

export const auth = (store: RootState) => store.settings;

function* _select<T>(fn: (state: RootState) => T) {
	const res: T = yield select(fn);
	return res;
}

export function* moleculeProductStart(api: any, action: any) {
	yield fork(getMoleculeProductWatcher, api, action);
	yield fork(getSolubilitiesByEbcIdWatcher, api, action);
}

/**
 *  Get molecule product watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getMoleculeProductWatcher(api: any, action: any): Generator<any, void, any> {
	while (true) {
		try {
			const actions = yield take(actionsCreator.getMoleculeById.type);
			const state = yield* _select(auth);
			const authorized = state.user.authorized;

			const requests = [
				call(getCompoundByIdRequest, api, actions.payload),
				call(getDrugImpurityRequest, api, actions.payload),
				call(getCansByEbcIdRequest, api, actions.payload),
			];

			if (authorized) {
				requests.push(call(getMainActivityRequest, api, actions.payload));
			}

			const results = yield all(requests);
			const resGetCompoundById = results[0];
			const resDrugImpurity = results[1];
			const resCansByEbcId = results[2];
			const resGetMainActivity = authorized ? results[3] : {ok: true, data: mainActivity};

			if (
				resGetCompoundById.ok &&
				resDrugImpurity.ok &&
				resCansByEbcId.ok &&
				(authorized ? resGetMainActivity.ok : true)
			) {
				const convertData = convertDataPrices(resGetCompoundById.data.prices || []);
				const newData = {
					data: {
						...resGetCompoundById.data,
						prices: convertData,
						impForDrug: resDrugImpurity.data.impForDrug,
						drugForImp: resDrugImpurity.data.drugForImp,
					},
					cans: resCansByEbcId.data,
					mainActivity: resGetMainActivity.data,
					disabled: !convertData.filter((x: { [index: string]: string | boolean }) => x.available).length,
				};
				yield put(actionsCreator.getMoleculeByIdSuccess(newData));
			} else {
				yield put(actionsCreator.getMoleculeByIdError());
			}
		} catch (e) {
			console.log("CATCH ERROR: getMoleculeProductWatcher: ", e);
			yield put(actionsCreator.getMoleculeByIdError());
		}
	}
}

/**
 *  Get list solubilities by Ebc id watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getSolubilitiesByEbcIdWatcher(api: any, action: any): Generator<any, void, any> {
	while (true) {
		try {
			const actions = yield take(actionsCreator.getSolubilitiesByEbcId.type);

			const res = yield call(api.request, 'getSolubilitiesByEbcId', actions.payload);

			if (!res.ok) {
				yield put(actionsCreator.getSolubilitiesByEbcIdError());
			} else {
				yield put(actionsCreator.getSolubilitiesByEbcIdSuccess(res.data || []));
			}
		} catch (e) {
			console.log("CATCH ERROR: getSolubilitiesByEbcIdWatcher: ", e);
		}
	}
}

