import {call, delay, fork, put, select, take, takeLatest,} from "redux-saga/effects";

// Store
import {RootState} from "../../store";

// Mapper
import {TConvertLibCompounds} from "api/libraries/libraries.mappers";

// Actions
import * as actionsCreator from "./reducer";
import {TContent} from "./reducer";
import {hideLoader} from "reducers/loader";
import {showMessage} from "reducers/messages";
import {getListProjects} from "pages/projects/reducer";

// Lib
import {modalClose} from "reducers/modal";
import {addWithoutDublicate, checkAllCheckbox} from "./utils";
import {filteredDataPrices, isEngLang, optionsPrices, generateXLSTableSave} from "lib/helpers";

function* _select<T>(fn: (state: RootState) => T) {
	const res: T = yield select(fn);
	return res;
}

const storeKeyListIds = 'libCompoundsIds';
export const librariesCompounds = (store: RootState) => store.librariesCompounds;

// Generic response
export type ApiResponse<Data extends unknown> = {
	data?: Data;
	ok?: boolean;
	status?: number;
};
type TActions = {
	type: string,
	payload: string
}
type TMap = {
	[index: string]: string | number | boolean
}

export function* librariesCompoundsSagaStart(api: any, action: any) {
	yield fork(searchLibCompoundsWatcher, api);
	yield fork(getAllPOAInStockWatcher, api, action);
	yield fork(getLibrariesCompoundsIdsWatcher, api, action);
	yield fork(addAllLibrariesToProjectWatcher, api, action);
	yield fork(getLibrariesCompoundsByIdWatcher, api, action);
}

/**
 * Search text libraries compounds worker sagas
 *
 * @param {function} api - api object
 * @returns
 */
export function* searchLibCompoundsWatcher(api: any): Generator<any, void, any> {
	yield takeLatest(
		actionsCreator.searchLibrariesCompounds.type,
		searchLibCompoundsTextWorker,
		api
	);
}

/**
 * Search text libraries compounds workers sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* searchLibCompoundsTextWorker(api: any, action: any): Generator<any, void, any> {
	try {
		const value = action.payload.value;
		const cutNumber = action.payload.cutNumber;

		const state = yield* _select(librariesCompounds);

		const isFilterByStock = state.isFilterByStock;
		const isEng = isEngLang(value);

		if (!value) {
			return;
		}

		if (isEng) {
			const formData = {
				page: 0,
				value: value,
				size: state.data.size,
				isFilter: isFilterByStock,
			};

			const res = yield call(
				api.request,
				'searchLibCompounds',
				formData,
				cutNumber
			);

			if (!res.ok) {
				yield put(actionsCreator.searchLibrariesCompoundsError(res.data));
				yield put(showMessage({
					type: 'info',
					message: 'Error in requset search',
				}));
			} else {
				const currentPrice = JSON.parse(localStorage.getItem("priceApp") || '{}');

				const data = res?.data?.content || [];
				const listIds = JSON.parse(localStorage.getItem(storeKeyListIds) || '[]');

				const isAllCheckbox = checkAllCheckbox(listIds);

				if (isAllCheckbox) {
					localStorage.setItem("checkboxAllLibCompounds", JSON.stringify(true));
				}

				const formData = {
					checkboxAll: isAllCheckbox,
					dataSearch: {
						...res.data,
						content: data?.length ? data?.map((item: TContent) => {
							const isSelected = listIds.find(
								(elem: any) => elem.id === item.id
							).selected;
							return {
								...item,
								quantity: 1,
								selected: isSelected,
								currentPrice: Object.values(currentPrice).length ? currentPrice : [],
							}
						}) : [],
					}
				};

				yield put(actionsCreator.searchLibrariesCompoundsSuccess(formData || {}));
			}
		} else {
			yield put(showMessage({
				type: 'info',
				text: 'Please use Latin letters only'
			}));
		}
	} catch (e) {
		console.log('ERROR searchLibCompoundsTextWorker', e);
	}
}

/**
 *  Get all compounds lib by id watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getLibrariesCompoundsIdsWatcher(api: any, action: any): Generator<any, void, any> {
	while (true) {
		try {
			const actions: TActions = yield take(actionsCreator.getLibrariesCompoundsIds.type);

			const res: ApiResponse<TConvertLibCompounds> = yield call(api.request, "getLibCompoundsIds", actions.payload);

			if (!res.ok) {
				yield put(actionsCreator.getLibrariesCompoundsIdsError());
			} else {
				const currentPrice = JSON.parse(localStorage.getItem("priceApp") || '{}');
				const listIds = JSON.parse(localStorage.getItem(storeKeyListIds) || '[]');

				const convertedData = res?.data?.content.map((item) => {
					const currentObject = listIds?.length ? listIds?.find(
						(elem: TContent) => elem.id === item.id
					) : {};

					const isSelected = currentObject !== undefined ? currentObject : false;

					return {
						...item,
						quantity: 1,
						currentPrice: Object.values(currentPrice)?.length ? currentPrice : [],
						selected: Object.values(isSelected)?.length ? isSelected?.selected : false,
					}
				});
				addWithoutDublicate(convertedData, storeKeyListIds)
				yield put(actionsCreator.getLibrariesCompoundsIdsSuccess(convertedData || []));
			}
		} catch (e) {
			console.log("CATCH ERROR in getLibrariesCompoundsIdsWatcher: ", e);
		}
	}
}

/**
 *  Get size compounds lib by id watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getLibrariesCompoundsByIdWatcher(api: any, action: any): Generator<any, void, any> {
	while (true) {
		try {
			const actions: TActions = yield take(actionsCreator.getLibrariesCompounds.type);

			const state = yield* _select(librariesCompounds);

			const size = state.data.size;
			const number = state.data.number;
			const accessibility = state?.accessibility?.value;

			const formData = {
				size: size,
				page: number,
				id: actions.payload,
				accessibility: accessibility
			};

			const res: ApiResponse<TConvertLibCompounds> = yield call(
				api.request,
				"getLibCompounds",
				formData,
			);

			if (!res.ok) {
				yield put(actionsCreator.getLibrariesCompoundsError());
				yield put(hideLoader());
			} else {
				const currentPrice = JSON.parse(localStorage.getItem("priceApp") || '{}');
				yield delay(300);

				let countPrice = 0;
				const data = res?.data?.content || [];
				const listIds = JSON.parse(localStorage.getItem(storeKeyListIds) || '[]');

				const checkboxAll = JSON.parse(localStorage.getItem("checkboxAllLibCompounds") || 'false');

				const convertObject = (item: TMap) => {
					return {
						value: 'poa',
						mg: item.mg || '',
						label: `POA/${item.mg}mg`,
					}
				};

				const formData = {
					checkboxAll: checkboxAll,
					data: {
						...res.data,
						content: data?.length ? data?.map((item: TContent) => {
							const currentObject: any = listIds?.find(
								(elem: any) => elem.id === item.id
							) || {};

							const optionsPr = item?.prices?.length
								? filteredDataPrices(item?.prices)?.map((item: TMap) =>
									optionsPrices(item)
								) : [];
							let updateOptions: TMap[] = optionsPr;

							updateOptions = optionsPr.map((item: TMap) => {
								if (item.value === undefined) {
									countPrice += 1;

									return {
										...item,
										...convertObject(item),
									}
								}
								countPrice = 0;
								return item;
							});

							if (updateOptions.length === countPrice) {
								updateOptions = updateOptions.map((item: TMap) => convertObject(item));
							}

							return {
								...item,
								quantity: 1,
								isPOA: updateOptions.length === countPrice,
								currentPrice: Object.values(currentPrice).length ? currentPrice : [],
								selected: Object.values(currentObject)?.length ? currentObject?.selected : false
							}
						}) : [],
					}
				};

				yield put(actionsCreator.getLibrariesCompoundsSuccess(formData || {}));
				yield put(hideLoader());
			}
		} catch (e) {
			console.log("CATCH ERROR in getLibrariesCompoundsByIdWatcher: ", e);
		}
	}
}

/**
 *  Add all libraries to project watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* addAllLibrariesToProjectWatcher(api: any, action: any): Generator<any, void, any> {
	while (true) {
		try {
			const actions: any = yield take(actionsCreator.addAllLibrariesToProject.type);

			const url = actions?.payload?.url || '';
			const navigate = actions?.payload?.navigate;

			const productId = actions.payload.productId;
			const catNumber = actions.payload.catNumber;
			const prepackage = actions.payload.prepackage;

			const formData = {
				catNumber: catNumber,
				prepackage: prepackage,
			};

			const res: ApiResponse<any> = yield call(
				api.request,
				'addAllToProject',
				formData,
				productId
			);

			if (!res.ok) {
				yield put(actionsCreator.addAllLibrariesToProjectError());
			} else {
				yield put(showMessage({
					type: 'info',
					title: 'Compound Added to project',
					text: 'Review or continue browsing. Contact<br/> us for any questions. Thank you!',
				}));
				yield put(getListProjects(''));
				yield put(actionsCreator.addAllLibrariesToProjectSuccess());
				yield put(modalClose());

				if(!!navigate) {
					navigate(url);
				}
			}
		} catch (e) {
			console.log("CATCH ERROR in addAllLibrariesToProjectWatcher: ", e);
		}
	}
}

/**
 *  Get All POA InStock watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getAllPOAInStockWatcher(api: any, action: any): Generator<any, void, any> {
	while (true) {
		try {
			const actions: any = yield take(actionsCreator.getAllPOAInStock.type);

			const state = yield* _select(librariesCompounds);

			const totalElements = state.data.totalElements;
			const accessibility = state?.accessibility?.value;

			const formData = {
				size: totalElements,
				page: 0,
				id: actions.payload,
				accessibility: accessibility
			};

			const res: ApiResponse<TConvertLibCompounds> = yield call(
				api.request,
				"getLibCompounds",
				formData,
			);

			if(!res.ok) {
				yield put(actionsCreator.getAllPOAInStockError());
			} else {
				const headers = [
					{data: 'id'},
					{data: 'name'},
					{data: 'iupacName'},
					{data: 'cas'},
					{data: 'description'},
					{data: 'cxSmiles'},
				];

				generateXLSTableSave({data: res.data?.content, headers: headers});
				yield put(actionsCreator.getAllPOAInStockSuccess());
			}
		} catch (e) {
			console.log("CATCH ERROR in getAllPOAInStockWatcher: ", e);
		}
	}
}
