import {
  ApisauceInstance
} from 'apisauce';

// Url
import { url } from '../api.url';

type SingUpFormDataType = {
  email?: string,
  title?: string,
  password?: string,
  lastName?: string,
  position?: string,
  firstName?: string,
  organization?: string,
  advertConsent?: boolean
};

type SingIpFormDataType = {
  email?: string,
  password?: string,
};

const authApi = (api: ApisauceInstance) => {
  const logout = (id: string) => api.delete(`${url.logout}?token=${id}`, {}, {headers: {
      'Authorization': '',
    }});
  const signIn = (formData: SingIpFormDataType) => api.post(url.signin, formData);
  const singUp = (formData: SingUpFormDataType) => api.post(url.signup, formData);
  const userVerify = (formData: string) => api.get(`${url.userVerify}?code=${formData}`);

  return {
    logout,
    signIn,
    singUp,
    userVerify,
  };
};

export default authApi;
