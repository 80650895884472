import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialState,
  reducers: {
    getListOrders: (state) => {
      state.loading = true;
    },
    getListOrdersError: (state) => {
      state.loading = false;
    },
    getListOrdersSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const {
  getListOrders,
  getListOrdersError,
  getListOrdersSuccess,
} = ordersSlice.actions;

export default ordersSlice.reducer;
