import {createSlice} from '@reduxjs/toolkit';

// Lib
import Cookies from "js-cookie";

// Constants
import {amplitudeInstance, events} from "constans/cookie";

// Interface
interface IState {
	user: {
		role?: string,
		user?: string,
		email?: string,
		title?: string,
		token?: string,
		country?: string,
		refresh?: string,
		lastName?: string,
		firstName?: string,
		authorized?: boolean,
		userId?: string | number,
		position?: string | number,
		organization?: string | null,
	},
	listImgs: [],
	loadingLogout: boolean,
	loadingListImgs: boolean,
	currentPrice: {
		label: string | number,
		value: string | number,
	} | [],
	isCloseSmallDropdown?: boolean,
}

const keyPriceApp = "priceApp";
const currentPrice = JSON.parse(localStorage.getItem(keyPriceApp) || '[]');

const initialState: IState = {
	user: {
		role: '',
		user: '',
		email: '',
		title: '',
		token: '',
		country: '',
		refresh: '',
		userId: '',
		position: '',
		lastName: '',
		firstName: '',
		organization: '',
		authorized: false,
	},
	listImgs: [],
	loadingLogout: false,
	loadingListImgs: false,
	currentPrice: currentPrice,
	isCloseSmallDropdown: false,
};

export const settingsSlice = createSlice({
	name: 'settings',
	initialState: initialState,
	reducers: {
		getListImg: (state, action) => {
			state.loadingListImgs = true;
		},
		getListImgError: (state) => {
			state.loadingListImgs = false;
		},
		getListImgSuccess: (state, action) => {
			state.listImgs = action.payload;
			state.loadingListImgs = false;
		},

		toggleModal: (state, action) => {
			state.isCloseSmallDropdown = action.payload;
		},

		checkAuth: (state) => {
		},
		checkAuthSuccess: (state, action) => {
			state.user = {
				...state.user,
				...action.payload?.data,
				authorized: true,
			}
		},

		changeAuthorized: (state, action) => {
			state.user.authorized = action.payload;
		},

		updateSettingsUser: (state, action) => {
			return {
				...state,
				user: {
					...state.user,
					...action.payload,
				}
			}
		},

		logout: (state) => {
			state.loadingLogout = true;
		},
		logoutError: (state) => {
			state.loadingLogout = false;
		},
		logoutSuccess: (state) => {
			localStorage.removeItem('auth');

			state.user = initialState.user;

			const formData = {
				data: '',
				type: "SIGN_OUT",
			};

			localStorage.setItem('synchronizationTab', JSON.stringify(formData));

			const isCookie = Cookies.get('cookieName');
			if (isCookie) {
				amplitudeInstance.logEvent(events.logout);
			}
		},
		updateLogout: (state) => {
			localStorage.removeItem('auth');

			state.user = initialState.user;
		},

		handlerChangePriceApp: (state, action) => {
			const selected = action.payload;
			state.currentPrice = selected;

			localStorage.setItem(keyPriceApp, JSON.stringify(selected));
		},
	},
});

export const {
	logout,
	checkAuth,
	getListImg,
	toggleModal,
	logoutError,
	updateLogout,
	logoutSuccess,
	getListImgError,
	checkAuthSuccess,
	changeAuthorized,
	getListImgSuccess,
	updateSettingsUser,
	handlerChangePriceApp,
} = settingsSlice.actions;

export default settingsSlice.reducer;
