import { call } from "redux-saga/effects";

export function* getCansByEbcIdRequest(api: any, data: any): Generator<any, void, any> {
	try {
		return yield call(api.request, 'getCansByEbcId', data);
	} catch (e) {
		console.log('ERROR getCansByEbcIdRequest: !!!', e);
	}
}

export function* getCompoundByIdRequest(api: any, data: any): Generator<any, void, any> {
	try {
		return yield call(api.request, 'getCompoundById', data);
	} catch (e) {
		console.log('ERROR getCompoundByIdRequest: !!!', e);
	}
}

export function* getMainActivityRequest(api: any, data: any): Generator<any, void, any> {
	try {
		return yield call(api.request, 'getBaDataMainActivity', data);
	} catch (e) {
		console.log('ERROR getMainActivityRequest: !!!', e);
	}
}

export function* getDrugImpurityRequest(api: any, data: any): Generator<any, void, any> {
	try {
		return yield call(api.request, 'drugImpurity', data);
	} catch (e) {
		console.log('ERROR getDrugImpurityRequest: !!!', e);
	}
}

