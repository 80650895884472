import {call, fork, put, take,} from "redux-saga/effects";

// Actions
import * as actionsCreator from "./reducer";

// Type
type PayloadType = {
	email?: string,
	fullName?: string,
	description?: string,
	companyName?: string,
	advertConsent?: boolean
};

type ActionSendContactUsType = {
	type?: string,
	payload?: PayloadType,
};

// Generic response
export type ApiResponse<Data extends unknown> = {
	data?: Data;
	ok?: boolean;
	status?: number;
};

export function* contactUsSagaStart(api: any, action: any) {
	yield fork(sendContactUsWatcher, api, action);
};

/**
 *  Send contact us watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* sendContactUsWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionSendContactUsType = yield take(actionsCreator.sendContacts.type);

			const formData = {
				email: actions.payload?.email,
				name: actions.payload?.fullName,
				message: actions.payload?.description,
				companyName: actions.payload?.companyName,
				advertConsent: actions.payload?.advertConsent,
			};

			const res: ApiResponse<any> = yield call(api.request, "contactUs", formData);

			if (!res.ok) {
				yield put(actionsCreator.sendContactsError(res));
			} else {
				yield put(actionsCreator.sendContactsSuccess(res));
			}
		} catch (e) {
			console.log("CATCH ERROR: sendContactUsWatcher: ", e);
		}
	}
}
