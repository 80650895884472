// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const modalSlice = createSlice({
	name: 'screen',
	initialState: initialState,
	reducers: {
		screenSet: (state, action) => {
			return {
				...state,
				[action.payload?.key]: {
					...state[action.payload?.key],
					...(action.payload?.state || {}),
				},
			};
		},
		screenUnset: (state, action) => {
			return {
				...state,
				[action.payload?.key]: initialState[action.payload?.key] || false,
			};
		},
	},
});

export const {
	screenSet,
	screenUnset
} = modalSlice.actions;

export default modalSlice.reducer;
