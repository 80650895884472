import {TContent} from "./reducer";

const keyLocalStore = "listLibrariesCompounds";

export const addToLocalStorage = (data: any[] = [], localStoreName: string = '') => {
	const localStore = JSON.parse(localStorage.getItem(localStoreName ? localStoreName : keyLocalStore) || '[]');

	const newArrayList = Array.from(new Set([
		...localStore,
		...data
	]));

	localStorage.setItem(localStoreName ? localStoreName : keyLocalStore, JSON.stringify(newArrayList));
};

export const toggleToLocalStorage = (data: any = {}, localStoreName: string = '') => {
	const localStore = JSON.parse(localStorage.getItem(localStoreName ? localStoreName : keyLocalStore) || '[]');

	const id = data.id;
	const newArrayList = localStore.map((item: any) => {
		if (item.id === id) {
			return {
				...item,
				selected: !item.selected,
			}
		} else {
			return item;
		}
	});

	localStorage.setItem(localStoreName ? localStoreName : keyLocalStore, JSON.stringify(newArrayList));
	return newArrayList;
};

export const checkAllCheckbox = (data: TContent[]) => {
	const onStockMoleculesCount = data.filter((item: TContent) => item.onStock);
	const countSelected = onStockMoleculesCount.filter((item: TContent) => item.selected).length;
	return countSelected === onStockMoleculesCount.length;
};

export const addWithoutDublicate = (data: any[] = [], localStoreName: string = '') => {
	const localStore = JSON.parse(localStorage.getItem(localStoreName ? localStoreName : keyLocalStore) || '[]');

	// Custom comparison function based on 'id' property
	const isObjectEqual = (obj1: any, obj2: any) => obj1.id === obj2.id;

	// Combine localStore and data arrays and filter out duplicates based on 'id'
	const newArrayList = Array.from(new Set([...localStore, ...data].filter((item, index, array) =>
		array.findIndex((i) => isObjectEqual(i, item)) === index
	)));

	localStorage.setItem(localStoreName ? localStoreName : keyLocalStore, JSON.stringify(newArrayList));
	return newArrayList
};

export const removeToLocalStorage = (data: any[] = [], localStoreName: string = '') => {
	const localStore = JSON.parse(localStorage.getItem(localStoreName ? localStoreName : keyLocalStore) || '[]');

	const newArrayList = localStore.filter(
		(id: string) => !data.includes(id)
	);

	localStorage.setItem(localStoreName ? localStoreName : keyLocalStore, JSON.stringify(newArrayList));
};

export const checkIsAllLocalStore = (data: any = [], localStoreName: string = '') => {
	const localStore = JSON.parse(localStorage.getItem(localStoreName ? localStoreName : keyLocalStore) || '[]');

	return data.every(
		(item: any) => localStore.includes(item)
	);
}