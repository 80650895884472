import {createSlice} from '@reduxjs/toolkit';

// Type
import {IInitialState, PropsCurrentAddresses} from "./type";

let addressData = {
	zip: '',
	city: '',
	email: '',
	phone: '',
	country: '',
	lastName: '',
	firstName: '',
	streetAddress: '',
	shipAddress: false,
	zipShipAddress: '',
	cityShipAddress: '',
	phoneShipAddress: '',
	streetShipAddress: '',
	countryShipAddress: '',
}

const keyAddressLocalStore = 'address';

const addressLocalStore = localStorage.getItem(keyAddressLocalStore) || '';

if (addressLocalStore) {
	addressData = JSON.parse(addressLocalStore)
}

const formAddress = {
	lastName: '',
	firstName: '',
	email: addressData?.email || '',

	zip: addressData?.zip || '',
	city: addressData?.city || '',
	phone: addressData?.phone || '',
	country: addressData?.country || '',
	shipAddress: addressData?.shipAddress || false,
	streetAddress: addressData?.streetAddress || '',
	zipShipAddress: addressData?.zipShipAddress || '',
	cityShipAddress: addressData?.cityShipAddress || '',
	phoneShipAddress: addressData?.phoneShipAddress || '',
	streetShipAddress: addressData?.streetShipAddress || '',
	countryShipAddress: addressData?.countryShipAddress || '',
}

const currentShippingAddress = {
	city: '',
	phone: '',
	country: '',
	postalCode: '',
	addressLine1: '',

	id: '',
	zipShipAddress: '',
	cityShipAddress: '',
	phoneShipAddress: '',
	streetShipAddress: '',
	countryShipAddress: {
		label: '',
		value: '',
	},
};

const initialState: IInitialState = {
	data: {},
	loading: false,
	listAddresses: [],
	typeForm: 'create',
	currentIdShipping: '',
	formAddress: formAddress,
	loadingPlaceOrder: false,
	thereAreAddresses: false,
	isShowFormShipping: false,
	loadingSendAddress: false,
	isPlaceOrderSuccess: false,
	loadingListAddresses: false,
	loadingUpdateAddresses: false,
	loadingSendAddressShipping: false,
	loadingDeleteAddressesById: false,
	currentShippingAddress: currentShippingAddress,
}

export const searchSlice = createSlice({
	name: 'checkout',
	initialState,
	reducers: {
		handlerChangeCurrentIdShippingAddress: (state, action) => {
			state.currentIdShipping = action.payload;
		},
		handlerChangeCurrentShippingAddress: (state, action) => {
			state.currentShippingAddress = action.payload;
		},
		handlerShowForm: (state, action) => {
			state.isShowFormShipping = action.payload;
		},
		handlerChangeTypeForm: (state, action) => {
			state.typeForm = action.payload;
		},

		handlerUpdateCheckout: (state, action) => {
			state.data = action.payload;
		},

		getOrderById: (state, action) => {
			state.loading = true;
		},
		getOrderByIdError: (state) => {
			state.loading = false;
		},
		getOrderByIdSuccess: (state, action) => {
			state.loading = false;
			state.data = action.payload;
		},

		sendAddressInitForm: (state, action) => {
			state.loadingSendAddress = true;
		},
		sendAddressInitFormError: (state) => {
			state.loadingSendAddress = false;
		},
		sendAddressInitFormSuccess: (state) => {
			state.loadingSendAddress = false;
		},

		getAddress: (state) => {
			state.loadingListAddresses = true;
		},
		getAddressError: (state) => {
			state.loadingListAddresses = false;
		},
		getAddressSuccess: (state, action) => {
			const currentAddresses = action.payload?.find(
				(x: PropsCurrentAddresses) => x.main
			);

			state.loadingListAddresses = false;
			state.listAddresses = action.payload;
			state.currentShippingAddress = currentAddresses;
			state.thereAreAddresses = !!action?.payload?.length;
			state.currentIdShipping = currentAddresses?.id ? currentAddresses?.id + '' : '';
		},

		handlerChangeUpdateFormAddress: (state, action) => {
			state.formAddress = action.payload;
		},

		sendAddressShipping: (state, action) => {
			state.loadingSendAddressShipping = true;
		},
		sendAddressShippingError: (state) => {
			state.loadingSendAddressShipping = false;
		},
		sendAddressShippingSuccess: (state) => {
			state.loadingSendAddressShipping = false;
		},

		updateAddresses: (state, action) => {
			state.loadingUpdateAddresses = true;
		},
		updateAddressesError: (state) => {
			state.loadingUpdateAddresses = false;
		},
		updateAddressesSuccess: (state, action) => {
			state.loadingUpdateAddresses = false;
			state.listAddresses = action.payload;
		},

		deleteAddressesById: (state, action) => {
			state.loadingDeleteAddressesById = true;
		},
		deleteAddressesByIdError: (state) => {
			state.loadingDeleteAddressesById = false;
		},
		deleteAddressesByIdSuccess: (state, action) => {
			state.loadingDeleteAddressesById = false;
			state.listAddresses = action.payload;
		},

		submitPlaceOrder: (state, action) => {
			state.loadingPlaceOrder = true;
		},
		submitPlaceOrderError: (state) => {
			state.loadingPlaceOrder = false;
		},
		submitPlaceOrderSuccess: (state) => {
			state.loadingPlaceOrder = false;
		},
	},
});

export const {
	getAddress,
	getOrderById,
	handlerShowForm,
	updateAddresses,
	getAddressError,
	submitPlaceOrder,
	getAddressSuccess,
	getOrderByIdError,
	sendAddressInitForm,
	getOrderByIdSuccess,
	deleteAddressesById,
	sendAddressShipping,
	updateAddressesError,
	handlerChangeTypeForm,
	submitPlaceOrderError,
	updateAddressesSuccess,
	submitPlaceOrderSuccess,
	sendAddressInitFormError,
	sendAddressShippingError,
	deleteAddressesByIdError,
	sendAddressInitFormSuccess,
	deleteAddressesByIdSuccess,
	sendAddressShippingSuccess,
	handlerChangeUpdateFormAddress,
	handlerChangeCurrentShippingAddress,
	handlerChangeCurrentIdShippingAddress,
} = searchSlice.actions;

export default searchSlice.reducer;
