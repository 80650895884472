// Saga create
import createSagaMiddleware from "redux-saga";

// Toolkit
import { configureStore } from "@reduxjs/toolkit";

// Start Saga
import startSaga from "../saga";

// Reducers
import rootReducer from '../reducers/index';

const zone: string = process.env.REACT_APP_NODE_ENV || '';

const saga = createSagaMiddleware();
const store = configureStore({
  reducer: rootReducer,
  middleware: [saga],
  devTools: zone !== 'production'
});

saga.run(startSaga, store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
