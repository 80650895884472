import {call, fork, put, take,} from "redux-saga/effects";

// Actions
import * as actionsCreator from "./reducer";

// Type
import {ApiResponse} from "../libraries-product/type";

export function* mainSagaStart(api: any, action: any) {
	yield fork(getHighlightLibCarouselChangeWatcher, api, action);
}

/**
 *  Get high light libraries watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getHighlightLibCarouselChangeWatcher(api: any, action: any) {
	while (true) {
		try {
			yield take(actionsCreator.getHighlightCarousel.type);

			const res: ApiResponse<any> = yield call(api.request, "getHighlightCarousel");

			if (!res.ok) {
				yield put(actionsCreator.getHighlightCarouselError());
			} else {
				yield put(actionsCreator.getHighlightCarouselSuccess(res.data || []));
			}
		} catch (e) {
			console.log("CATCH ERROR: getHighlightLibCarouselChangeWatcher: ", e);
		}
	}
}