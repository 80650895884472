import React, {useEffect, useState,} from 'react';

// Helpers
import Cookies from 'js-cookie';
import {hotjar} from 'react-hotjar';
import CookieConsent from "react-cookie-consent";

// Constants
import {amplitudeInstance, events, hjid, hjsv, REACT_APP_AMPLITUDE_KEY} from 'constans/cookie';

// Components
import Divider from "components/Divider";
import Button from "components/ui-kit/button";
import FieldCheckbox from "components/ui-kit/field-checkbox";

// Navigation
import {Link} from "react-router-dom";

// Image
import CookieImage from 'assets/img/cookie.png';

// Styles
const buttonStyle = {
  minWidth: '93px',
  minHeight: '26px',
  border: 'unset',
  cursor: 'pointer',
  padding: '0.8rem 2rem',
  background: '#FFBE7F',
  borderRadius: '100px',
  Color: '#46766F',
  FontSize: '1.4px',
  TextAlign: 'center',
  fontStyle: 'normal',
  lineHeight: 2.4,
  fontFamily: 'Poppins',
  letterSpacing: '-0.02em',
};
const mainStyle = {
  left: '50%',
  bottom: '50px',
  width: '1054px',
  borderRadius: '12px',
  background: '#FEFEFE',
  transform: 'translate(-50%, -50px)',
  boxShadow: '1px 1px 1px rgba(6, 6, 6, 0.1)',
};
const fullScreenStyle = {
  left: 0,
  bottom: '0px',
  width: '100%',
  height: '100%',
  borderRadius: 0,
  transform: 'unset',
};

interface CookeiPreferences {
  content?: boolean,
  necessary?: boolean,
  advertising?: boolean,
  performance?: boolean,
};

amplitudeInstance.init(REACT_APP_AMPLITUDE_KEY, undefined, {
  includeUtm: true,
  includeGclid: true,
  includeReferrer: true,
});

const CookiesView = () => {
  const zone: string = window.location.hostname || '';

  const [isConsentAccepted, setIsConsentAccepted] = useState(false);
  const [toggleSettings, setToggleSettings] = useState<boolean>(false);
  const [cookiePreferences, setCookiePreferences] = useState<CookeiPreferences>({
    content: true,
    necessary: true,
    advertising: true,
    performance: false,
  });

  useEffect(() => {
    if (isConsentAccepted && (
      zone === 'ebc.enamine.net' ||
      zone === 'ebc-aws.enamine.net')
    ) {
      hotjar.initialize(hjid, hjsv);
      amplitudeInstance.logEvent(events.cookie_notice_popup);
    }
  }, [isConsentAccepted]);

  const handleAccept = () => {
    Cookies.set('cookieName', 'accepted', {expires: 365});
    setIsConsentAccepted(true);
    amplitudeInstance.logEvent(events.cookie_notice_accept);
  };

  const handlerChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const checked = e.target.checked;

    setCookiePreferences({
      ...cookiePreferences,
      [name]: checked,
    });
  };

  const handlerSave = () => {
    setToggleSettings(false);
  };

  const fullScreen = toggleSettings ? fullScreenStyle : {};

  return (
    <CookieConsent
      expires={365}
      buttonText='I Agree'
      onAccept={handleAccept}
      buttonStyle={buttonStyle}
      style={{...mainStyle, ...fullScreen}}
    >
      <div className={`cookie-content ${toggleSettings ? 'cookie-content__height' : ''}`}>
        {!toggleSettings && <div className='flex flex-ai-start'>
          <img
            alt='Cookie'
            src={CookieImage}
            className='cookie-content__img'
          />

          <div className='ml-15'>
            <h4 className='cookie-content__title'>This site uses cookies.</h4>
            <p className='cookie-content__text'>
              Some of these cookies are essential, while others help us to improve your experience by providing insights
              into how the site is being used. For more information see our <Link
              to='/privacy-policy'>Privacy Policy</Link>
            </p>
          </div>
        </div>}

        {toggleSettings &&
          <div className='cookie-content__settings flex flex-jc-center flex-ai-center flex-direction-column'>
            <h4 className='cookie-content__settings-title mb10'>This site uses cookies.</h4>
            <p className='cookie-content__settings-sub-title mb30'>
              Some of these cookies are essential, while others help us to improve your experience by<br/>
              providing insights into how the site is being used. For more information see our <Link
              to='/privacy-policy'>Privacy Policy</Link>
            </p>
            <div className='flex flex-direction-column'>
              <FieldCheckbox
                name='performance'
                label='Performance'
                styleLabel={{
                  fontSize: '22px',
                  lineHeight: '22px',
                }}
                styleSubLabel={{
                  fontSize: '16px',
                  lineHeight: '22px',
                }}
                onChange={handlerChangeCheckbox}
                value={cookiePreferences?.performance}
                subLabel='We use these cookies to monitor and improve website performance.'
              />
              <FieldCheckbox
                name='content'
                label='Content'
                styleLabel={{
                  fontSize: '22px',
                  lineHeight: '22px',
                }}
                styleSubLabel={{
                  fontSize: '16px',
                  lineHeight: '22px',
                }}
                onChange={handlerChangeCheckbox}
                value={cookiePreferences?.content}
                subLabel='We use these cookies to tailor the content and experience you receive on our website.'
              />
              <FieldCheckbox
                name='advertising'
                label='Advertising'
                styleLabel={{
                  fontSize: '22px',
                  lineHeight: '22px',
                }}
                styleSubLabel={{
                  fontSize: '16px',
                  lineHeight: '22px',
                }}
                onChange={handlerChangeCheckbox}
                value={cookiePreferences?.advertising}
                subLabel='We use these cookies to help us improve the relevancy of advertising campaigns you receive.'
              />
              <FieldCheckbox
                disabled
                name='necessary'
                label='Necessary Cookies'
                styleLabel={{
                  fontSize: '22px',
                  lineHeight: '22px',
                }}
                styleSubLabel={{
                  fontSize: '16px',
                  lineHeight: '22px',
                }}
                onChange={handlerChangeCheckbox}
                value={cookiePreferences?.necessary}
                subLabel='Necessary cookies enable core functionality. The website cannot function properly without<br /> these cookies, and can only be disabled by changing your browser preferences.'
              />

              <Divider size={50}/>

              <div className='flex flex-ai-center flex-jc-start'>
                <Button variable='info' label='Save & close' onPress={handlerSave}/>
                <span className='cookie-content__text-or'>OR</span>
                <Button variable='outline' label='Accept Recomended Settings' onPress={handlerSave}/>
              </div>
            </div>
          </div>}
      </div>
    </CookieConsent>
  );
}

export default CookiesView;
