import {call, fork, put, take,} from "redux-saga/effects";

// Actions
import * as actionsCreator from "./reducer";
import {modalClose, openModal} from "reducers/modal";

// Lib
import {NavigateFunction} from "react-router-dom";

// Type
export type ApiResponse<Data extends unknown> = {
	data?: Data;
	ok?: boolean;
	error?: string;
	status?: number;
};

type PayloadType = {
	email?: string,
	navigate?: NavigateFunction
};

type ActionsType = {
	type?: string,
	payload?: PayloadType,
};

export function* forgotPasswordSagaStart(api: any, action: any) {
	yield fork(forgotPasswordWatcher, api, action);
}

/**
 *  Send request email watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* forgotPasswordWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionsType = yield take(actionsCreator.sendForgotPassword.type);

			const navigate = actions.payload?.navigate;

			const formData = {
				email: actions.payload?.email,
			};

			const res: ApiResponse<any> = yield call(api.request, "resetPasswordRequest", formData);

			if (!res.ok) {
				yield put(actionsCreator.sendForgotPasswordError({data: res?.data.error, status: res?.data.status}));
			} else {
				yield put(openModal({
					type: 'success',
					subTitle: res.data,
					navigate: navigate,
					actionClose: modalClose,
					title: 'Check your email',
				}));

				yield put(actionsCreator.sendForgotPasswordSuccess());
			}
		} catch (e) {
			console.log("CATCH ERROR: forgotPasswordWatcher: ", e);
		}
	}
}
