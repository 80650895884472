import React from "react";

// Components
import Img from "components/Img";

// Interface
interface IBanner {
  handlerClose?: any;
  modalProps?: UnitType;
}

// Type
type UnitType = {
  url?: string,
}

const Banner: React.FC<IBanner> = (props) => {
  const url = props?.modalProps?.url || '';
  const handlerClose = props?.handlerClose;

  const handlerClick = () => {
    handlerClose();

    localStorage.setItem("banner", JSON.stringify(false));
  }

  return <div className="flex bunner-modal flex-jc-center flex-ai-center">
    <div
      onClick={handlerClick}
      className="bunner-modal__close flex flex-ai-center flex-jc-center"
    >
      <Img name="greenCross" />
    </div>
    <Img
      innerSrc={url}
      alt="Image molecule"
      className="libraries-description__img"
    />
  </div>;
};

export default Banner;
