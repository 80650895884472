import {useEffect, useState} from 'react';

import {amplitudeInstance, events} from '../../constans/cookie';

const sessionIdKey = 'amplitude-bio-session-id';
const timeStep = 60 * 1000 * 30; // 30 minutes

const useLocalStorage = (key: any, initialValue: any) => {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => {
		try {
			// Get from local storage by key
			const item = window.localStorage.getItem(key);
			// Parse stored json or if none return initialValue
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			// If error also return initialValue
			console.log(error);
			return initialValue;
		}
	});

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value: any) => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore =
				value instanceof Function ? value(storedValue) : value;
			// Save state
			setStoredValue(valueToStore);
			// Save to local storage
			window.localStorage.setItem(key, JSON.stringify(valueToStore));
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error);
		}
	};

	return [storedValue, setValue];
};

const shouldFireEvent = (amplitude: any, storedValue: any, timeStep: any, timeNow: any) => {
	if (!storedValue) {
		return true;
	}

	const timeDiff = timeNow - storedValue.time;

	return timeDiff > timeStep;
};

const fireEventAndStoreValue = (amplitude: any, setValue: any, timeNow: any) => {
	const date = new Date(timeNow);
	let month = date.getMonth() + 1;
	month = month < 10 ? parseInt(('0' + month)) : month;
	let day = date.getDate();
	day = day < 10 ? parseInt(('0' + day)) : day;
	const year = date.getFullYear();
	const resultDate = `${year}${month}${day}`;

	amplitude.logEvent(events.session_start, {
		cohortday: resultDate,
	});

	setValue({
		sessionId: amplitude._sessionId,
		time: timeNow,
	});
};

const AmplitudeInitialTrigger = () => {
	const amplitude = amplitudeInstance;
	const [storedValue, setValue] = useLocalStorage(sessionIdKey, null);
	const [init, setInit] = useState<boolean>(false);

	const logSession = () => {
		const timeNow = Date.now();

		const shouldFire = shouldFireEvent(
			amplitude,
			storedValue,
			timeStep,
			timeNow
		);

		if (shouldFire) {
			fireEventAndStoreValue(amplitude, setValue, timeNow);
			setInit(true);
		}
	}

	useEffect(() => {
		const interval = setInterval(() => {
			logSession();
		}, timeStep);

		if (!init) {
			logSession();
		}

		return () => {
			clearInterval(interval);
		};
	}, []);

	return null;
};

export default AmplitudeInitialTrigger;
