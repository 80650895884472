import React from 'react';

// Components
import Crop from './crop';
import Bunner from './banner';
import Modal from 'react-modal';
import Success from './success';
import Confirm from "./confirm";
import Redirect from './redirect';
import ProjectAdd from "./project-add";
import ProjectsList from './projects-list';
import LimitExceeded from "./limit-exceeded";
import ProjectUpdate from "./project-update";

// Classnames
import clsx from 'clsx';

// Lib
import {useAppDispatch, useAppSelector} from "lib/hooks/useDispatch";

// Actions
import * as actionCreators from 'reducers/modal';

// Type
type ModalComponents = {
  [key: string]: React.ComponentType<any>
};

// Make sure to bind modals to your appElement
Modal.setAppElement('#root');

export const ModalComponent = () => {
  const dispatch = useAppDispatch();

  const modal = useAppSelector((store) => store.modal);

  const openModal = modal.openModal || false;
  const width = modal?.modalProps?.width || 50;
  const height = modal?.modalProps?.height || 50;
  const className = modal?.modalProps?.className || '';

  function handlerCloseModal() {
    dispatch(actionCreators.modalClose());
  }

  const COMPONENT: ModalComponents = {
    'crop': Crop,
    'bunner': Bunner,
    'success': Success,
    'confirm': Confirm,
    'redirect': Redirect,
    'projectAdd': ProjectAdd,
    'projectsList': ProjectsList,
    'projectUpdate': ProjectUpdate,
    'limitExceeded': LimitExceeded,
  };

  const cn = clsx(
    'relative',
    className ? className : '',
  );

  const ModalComponent = COMPONENT[modal?.modalProps?.type as keyof ModalComponents];

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      outline: 'none',
      marginRight: '-50%',
      width: '100%',
      height: '100%',
      maxWidth: `${width}rem`,
      maxHeight: `${height}rem`,
      transform: 'translate(-50%, -50%)',
      backdropFilter: 'blur(5px) !important',
      background: 'rgba(#191919, 0.50) !important',
    },
  };

  const handlerClose = (e: any) => {
    handlerCloseModal()
    localStorage.setItem("banner", JSON.stringify(false));
  };

  return (
    <Modal
      className={cn}
      isOpen={openModal}
      style={customStyles}
      onRequestClose={handlerClose}
    >
      {!!ModalComponent && <ModalComponent modalProps={modal.modalProps} handlerClose={handlerCloseModal}/>}
    </Modal>
  );
};

export default ModalComponent;
