import {call, fork, put, take,} from "redux-saga/effects";

// Actions
import * as actionsCreator from "./reducer";

// Type
import {ApiResponse, UnitHightLight} from './type';

export function* librariesProductSagaStart(api: any, action: any) {
	yield fork(downloadFileWatcher, api, action);
	yield fork(getHighlightLibChangeWatcher, api, action);
	yield fork(getLibrariesFullDetailsWatcher, api, action);
}

type ActionType = {
	type?: string,
	payload?: string,
};

type ActionTypeDownload = {
	type?: string,
	payload?: {
		[index: string]: string;
	},
};

/**
 *  Get high light libraries watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getHighlightLibChangeWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionType = yield take(actionsCreator.getHighlightLib.type);

			const res: ApiResponse<UnitHightLight[]> = yield call(
				api.request,
				"getHighlightLib",
				actions.payload
			);

			if (!res.ok) {
				yield put(actionsCreator.getHighlightLibError());
			} else {
				const sortedData = res?.data?.sort(
					(a, b) => (a?.rank ?? 0) - (b?.rank ?? 0)
				) || [];

				yield put(actionsCreator.getHighlightLibSuccess(sortedData || []));
			}
		} catch (e) {
			console.log("CATCH ERROR in getHighlightLibChangeWatcher: ", e);
		}
	}
}

/**
 *  Get full details libraries watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getLibrariesFullDetailsWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionType = yield take(actionsCreator.getLibrariesFullDetails.type);

			const res: ApiResponse<UnitHightLight[]> = yield call(api.request, "getLibFullDetails", actions.payload);

			if (!res.ok) {
				yield put(actionsCreator.getLibrariesFullDetailsError(res.data));
			} else {
				yield put(actionsCreator.getLibrariesFullDetailsSuccess(res.data || []));
			}
		} catch (e) {
			console.log('CATCH ERROR: getLibrariesFullDetailsWatcher', e);
		}
	}
}

export function* downloadFileWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionTypeDownload = yield take(actionsCreator.downloadFile.type);

			const id = actions?.payload?.uniqId;
			const type = actions?.payload?.type;

			const formData = {
				catNumber: id,
				type: type?.toUpperCase(),
			}

			const res: ApiResponse<any> = yield call(api.request, "downloadLib", formData);

			if (!res.ok) {
				yield put(actionsCreator.downloadFileError(type));
			} else {
				let filenameMatch = '';
				let filename = `untitled.${type}`;

				const contentDisposition = res?.headers['content-disposition'] || '';
				if (contentDisposition) {
					filenameMatch = contentDisposition.match(/filename[^;=\n]*=(["'])?([^"\n]*)\1?/);
					if (filenameMatch && filenameMatch.length > 2) {
						filename = filenameMatch[2];
					}
				}

				const outputFilename = `${filename}`;
				const href = window.URL.createObjectURL(res.data);
				const link = document.createElement('a');

				link.href = href;
				link.setAttribute('download', outputFilename); //or any other extension

				document.body.appendChild(link);

				link.click();

				document.body.removeChild(link);

				yield put(actionsCreator.downloadFileSuccess(type));
			}
		} catch (e) {
			console.log('CATCH ERROR: downloadFileWatcher', e);
		}
	}
}
