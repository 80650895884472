import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Type
import { UnitLibraries } from './type';

// Lib
import {filteredData} from "../../lib/libraries";
import {useMemo} from "react";

type Data = UnitLibraries;
interface EnvInterface {
  data: Data[];
  loading: boolean;
  loadingCount: boolean;
  diseases: Data[],
  drugRepurposing: Data[],
  bioActiveScreen: Data[],
  signalingPathways: Data[],
  compoundsCount: {
    compoundsAll?: string
    compoundsType1?: string
    compoundsType2?: string
    compoundsType3?: string
    compoundsType4?: string
  };
}

const initialState: EnvInterface = {
  data: [],
  diseases: [],
  loading: false,
  compoundsCount: {},
  loadingCount: false,
  drugRepurposing: [],
  bioActiveScreen: [],
  signalingPathways: [],
};

export const librariesSlice = createSlice({
  name: "libraries",
  initialState,
  reducers: {
    getAlllibraries: (state) => {
      state.loading = true;
    },
    getAlllibrariesError: (state) => {
      state.loading = false;
    },
    getAlllibrariesSuccess: (state, action: PayloadAction<Data[]>) => {
      const data = action.payload;

      const bioActiveScreen = filteredData(data || [], 1, true) || [];
      const drugRepurposing = filteredData(data || [], 2, true) || [];
      const signalingPathways = filteredData(data || [], 3, true) || [];
      const diseases = filteredData(data || [], 4, true) || [];
      
      state.loading = false;
      state.data = data;

      state.diseases = diseases;
      state.drugRepurposing = drugRepurposing;
      state.bioActiveScreen = bioActiveScreen;
      state.signalingPathways = signalingPathways;
    },

    getCompoundsCount: (state) => {
      state.loadingCount = true;
    },
    getCompoundsCountError: (state,) => {
      state.loadingCount = false;
    },
    getCompoundsCountSuccess: (state, action) => {
      state.loadingCount = false;
      state.compoundsCount = action.payload;
    },
  },
});

export const {
  getAlllibraries,
  getAlllibrariesError,
  getAlllibrariesSuccess,

  getCompoundsCount,
  getCompoundsCountError,
  getCompoundsCountSuccess,
} =
  librariesSlice.actions;

export default librariesSlice.reducer;
