import React, {
    useState,
    useEffect,
} from 'react';

type ScrollingTypes = {
    limit?: number,
    element?: string,
};

export function Scrolling(props: ScrollingTypes) {
    const { limit = 100, } = props;
    const [state, setState] = useState<boolean>(false);

    const handlerScrolling = (event: Event): void => {
        const scrollValue = document.documentElement.scrollTop;

        if(scrollValue > limit) {
            setState(true);
        } else {
            setState(false);
        };
    };

    useEffect(() => {
        const win: Window = window;
        win.addEventListener('scroll', handlerScrolling);

        return () => win.removeEventListener('scroll', handlerScrolling);
    }, []);

    return state;
};

export function ScrollWidth() {
    const [state, setState] = useState<number | undefined>(0);

    const handlerScrollWidth = (event: Event): void => {
        const scrollValue = document.querySelector('html')?.scrollLeft;
        setState(scrollValue);
    };

    useEffect(() => {
        const win: Window = window;
        win.addEventListener('scroll', handlerScrollWidth);

        return () => win.removeEventListener('scroll', handlerScrollWidth);
    }, []);

    return state;
};
