import {
  ApisauceInstance
} from 'apisauce';

// Url
import { url } from '../api.url';

const bioInfoRequest = (api: ApisauceInstance) => {
  const bioInfoRequest = (formData: any) => api.post(url.bioInfoRequest, formData);

  return {
    bioInfoRequest,
  };
};

export default bioInfoRequest;
