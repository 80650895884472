import {call} from "redux-saga/effects";

export function* billingRequest(api: any, data: any): Generator<any, void, any> {
	try {
		return yield call(api.request, 'sendAddresses', data);
	} catch (e) {
		console.log('ERROR billingRequest: !!!', e);
	}
}

export function* shippingRequest(api: any, data: any): Generator<any, void, any> {
	try {
		return yield call(api.request, 'sendAddresses', data);
	} catch (e) {
		console.log('ERROR shippingRequest: !!!', e);
	}
}