import React from 'react';

// Classnames
import clsx from 'clsx';

// Lib
import {sanitize} from 'dompurify';

// Type
type SelectType = {
  name?: string,
  hint?: boolean,
  title?: string,
  register?: any,
  label?: string,
  onChange?: any,
  value?: boolean,
  isInfo?: boolean,
  subLabel?: string,
  isError?: boolean,
  readOnly?: boolean,
  disabled?: boolean,
  msgError?: string,
  styleLabel?: object,
  styleSubLabel?: object,
  className?: string,
  placeholder?: string,
  classNameLabel?: string,
  isShowLabelLeft?: string,
};

// Component
export const FieldCheckbox: React.FC<SelectType> = ({
  name,
  hint,
  label,
  title,
  value,
  isInfo,
  onChange,
  msgError,
  subLabel,
  disabled,
  readOnly,
  className,
  styleLabel,
  styleSubLabel,
  classNameLabel,
  isShowLabelLeft,
}) => {
  const cnWrapper = clsx(
    'flex align-items-center field-checkbox relative',
    className ? className : '',
    disabled ? 'field-checkbox__disabled' : '',
    hint && isInfo ? 'field-checkbox__info' : '',
    !label && !subLabel ? 'field-checkbox__without-text' : ''
  );

  const cnLabel = clsx(
    "field-checkbox__text",
    classNameLabel ? classNameLabel : '',
  );

  return (
    <label title={title} className={cnWrapper}>
      {(label || subLabel) && (
        <div className="flex flex-ai-start flex-direction-column">
          {!!label && (
            <p
              className={cnLabel}
              style={styleLabel ? styleLabel : {}}
              dangerouslySetInnerHTML={{ __html: sanitize(label || '') }}
            />
          )}
          {!!subLabel && (
            <p
              className="mt10 field-checkbox__text"
              style={styleSubLabel ? styleSubLabel : {}}
              dangerouslySetInnerHTML={{ __html: sanitize(subLabel || '') }}
            />
          )}
        </div>
      )}
      <input
        name={name}
        type="checkbox"
        checked={!!value}
        disabled={disabled}
        onChange={onChange}
        readOnly={readOnly}
        className="field-checkbox__input"
      />
      <span
        className="field-checkbox__label"
        style={!label ? { top: 0 } : {}}
      />
      {msgError && <p className="field-checkbox__error">{msgError}</p>}
    </label>
  );
};

export default FieldCheckbox;
