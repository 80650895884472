import {ApisauceInstance} from 'apisauce';

// Url
import {url} from '../api.url';

const cans = (api: ApisauceInstance) => {
	const getCansByEbcId = (id: string) => api.get(`${url.getCansByEbcId(id)}`);

	return {
		getCansByEbcId
	};
};

export default cans;
