import {createSlice} from "@reduxjs/toolkit";

// Lib
import {optionsPrices} from "lib/helpers";
import {checkAllCheckbox, toggleToLocalStorage} from "./utils";

// Type
type TPrice = {
	mg?: number,
	priceEur?: number,
	priceUsd?: number,
	available?: boolean,
}
export type TContent = {
	id: string,
	qed: number,
	cas: string,
	name: string,
	wqed: number,
	dbId: string,
	prices: TPrice[],
	onStock: boolean,
	cxSmiles: string,
	iupacName: string,
	currentPrice?: any,
	selected?: boolean,
	isPOA?: boolean,
	description: string,
}
type TData = {
	size?: number,
	number?: number,
	totalPages?: number,
	content?: TContent[],
	totalElements?: number,
}
type TItem = { [index: string]: string | number | boolean };

// Interface
interface IEnv {
	data: TData;
	value: string;
	message: string,
	loading: boolean;
	ids?: TContent[];
	dataSearch: TData;
	checkboxAll?: boolean;
	loadingSearch?: boolean,
	isFilterByStock?: boolean,
	accessibility?: { [index: string]: string },

	loadingPOA?: boolean,
}

let checkboxAll: boolean = false;
const storeKeyListIds = 'libCompoundsIds';
const ids: any = JSON.parse(localStorage.getItem(storeKeyListIds) || '[]');
const getCheckboxAllCompounds: string = localStorage.getItem("checkboxAllLibCompounds") || '';

if (getCheckboxAllCompounds) {
	checkboxAll = JSON.parse(getCheckboxAllCompounds);
}

export const initialState: IEnv = {
	data: {
		size: 20,
		number: 0,
		content: [],
		totalPages: 0,
		totalElements: 0,
	},
	dataSearch: {
		size: 20,
		number: 0,
		content: [],
		totalPages: 0,
		totalElements: 0,
	},
	value: '',
	message: '',
	ids: ids || [],
	loading: false,
	loadingSearch: false,
	isFilterByStock: false,
	checkboxAll: checkboxAll,
	accessibility: {label: 'All', value: 'ALL'},

	loadingPOA: false,
}

export const librariesCompoundsSlice = createSlice({
	name: "librariesCompounds",
	initialState,
	reducers: {
		getAllPOAInStock: (state, action) => {
			state.loadingPOA = true;
		},
		getAllPOAInStockError: (state) => {
			state.loadingPOA = false;
		},
		getAllPOAInStockSuccess: (state) => {
			state.loadingPOA = false;
		},
		handlerClearState: (state) => {
			return {
				...state,
				...initialState,
			}
		},
		handlerChangeAccessibility: (state, action) => {
			state.accessibility = action.payload;
			state.data.number = 0;

			const listIds = JSON.parse(localStorage.getItem(storeKeyListIds) || '[]');
			state.checkboxAll = false;

			const content = state?.data?.content || [];

			const selectedAll = listIds.map((item: any) => ({
				...item,
				selected: false,
			}));

			state.data.content = content?.length
				? content?.map((item: TContent) => {
					return {
						...item,
						selected: false,
					}
				}) : [];

			localStorage.setItem("checkboxAllLibCompounds", JSON.stringify(false));
			localStorage.setItem(storeKeyListIds, JSON.stringify(selectedAll));
		},
		handlerChangeStock: (state) => {
			state.isFilterByStock = !state.isFilterByStock;
		},

		updateData: (state) => {
			state.data = state.dataSearch;
		},

		getLibrariesCompoundsIds: (state, action) => {
		},
		getLibrariesCompoundsIdsError: () => {
		},
		getLibrariesCompoundsIdsSuccess: (state, action) => {
			state.ids = action.payload;
		},

		getLibrariesCompounds: (state, action) => {
			state.loading = true;
		},
		getLibrariesCompoundsError: (state) => {
			state.loading = false;
		},
		getLibrariesCompoundsSuccess: (state, action) => {
			state.loading = false;
			state.data = action.payload.data;
			state.checkboxAll = action.payload.checkboxAll;
		},

		handlerChangePage: (state, action) => {
			state.data.number = action.payload;
		},

		handlerChangeCheckboxSelectAll: (state) => {
			const listIds = JSON.parse(localStorage.getItem(storeKeyListIds) || '[]');
			state.checkboxAll = !state.checkboxAll;

			const content = state?.data?.content || [];

			const selectedAll = listIds.map((item: any) => ({
				...item,
				selected: item.onStock ? state.checkboxAll : false,
			}));

			state.data.content = content?.length
				? content?.map((item: TContent) => {
 					return {
						...item,
						selected: item.isPOA ? false : item.onStock ? state.checkboxAll : false,
					}
				}) : [];

			localStorage.setItem("checkboxAllLibCompounds", JSON.stringify(state.checkboxAll));
			localStorage.setItem(storeKeyListIds, JSON.stringify(selectedAll));
		},
		handlerChangeCheckboxSelect: (state, action) => {
			const checkbox = action.payload.id;
			const content = state?.data?.content;

			const currentObject = content?.find((item: TContent) => item.id === checkbox);
			const result = toggleToLocalStorage(currentObject, storeKeyListIds);
			const isAllCheckbox = checkAllCheckbox(result);

			localStorage.setItem("checkboxAllLibCompounds", JSON.stringify(isAllCheckbox));

			state.checkboxAll = isAllCheckbox;
			state.data.content = content?.length
				? content?.map((item: TContent) => {
					if (item.id === checkbox) {
						return {
							...item,
							selected: !currentObject?.selected,
						}
					} else {
						return item
					}
				}) : [];
		},

		searchLibrariesCompounds: (state, action) => {
			state.loadingSearch = true;
			state.value = action.payload.value;
		},
		searchLibrariesCompoundsError: (state, action) => {
			state.loading = false;
			state.message = action.payload;
		},
		searchLibrariesCompoundsSuccess: (state, action) => {
			state.loading = false;
			state.dataSearch = action.payload.dataSearch;
			state.checkboxAll = action.payload.checkboxAll;
		},

		handlerChangeSize: (state, action) => {
			state.data.size = action.payload;
			state.data.number = 0;
		},

		handlerChangeQuantity: (state, action) => {
			const listId = JSON.parse(localStorage.getItem(storeKeyListIds) || '[]');

			const id = action.payload.id;
			const quantity = action.payload.quantity;

			const updateData = (data: TContent[]) => {
				return data.map((item: TContent) => {
					if (item.id === id) {
						return {
							...item,
							quantity: quantity
						}
					}

					return item;
				});
			};

			const updateQuanLocalStore = updateData(listId);
			state.data.content = updateData(state?.data?.content?.length ? state?.data?.content : []);

			localStorage.setItem(storeKeyListIds, JSON.stringify(updateQuanLocalStore));
		},
		handlerChangePrice: (state, action) => {
			const listId = JSON.parse(localStorage.getItem(storeKeyListIds) || '[]');

			const id = action.payload.id;
			const currentPrice = action.payload.currentPrice;

			const updatePricesLocalStore = listId.map((item: TContent) => {
				if (item.id === id) {
					return {
						...item,
						currentPrice: currentPrice
					};
				}

				return item;
			});

			state.data.content = state?.data?.content?.length
				? state?.data?.content.map((item: TContent) => {
					if (item.id === id) {
						const findObject = item.prices.map(
							(item: TItem) => optionsPrices(item)
						).find(
							(item: TItem) => item.mg === currentPrice.mg
						);

						return {
							...item,
							currentPrice: currentPrice.value === 'poa' ? currentPrice : findObject
						};
					}

					return item;
				}) : [];

			localStorage.setItem(storeKeyListIds, JSON.stringify(updatePricesLocalStore));
		},

		handlerUpdateContent: (state, action) => {
			state.data.content = action.payload;
		},

		handlerClearAllCheckboxSelected: (state) => {
			const listId = JSON.parse(localStorage.getItem(storeKeyListIds) || '[]');
			state.checkboxAll = false;
			state.data.content = state?.data?.content?.map((item: TContent) => ({
				...item,
				selected: false,
			}));

			const selectedAll = listId.map(
				(item: TContent) => ({...item, selected: false})
			);
			localStorage.setItem(storeKeyListIds, JSON.stringify(selectedAll));
			localStorage.setItem("checkboxAllLibCompounds", JSON.stringify(false));
		},

		addAllLibrariesToProject: (state, action) => {
		},
		addAllLibrariesToProjectError: () => {
		},
		addAllLibrariesToProjectSuccess: () => {
		},
	},
});

export const {
	getAllPOAInStock,
	getAllPOAInStockError,
	getAllPOAInStockSuccess,

	updateData,
	handlerClearState,
	handlerChangePage,
	handlerChangeSize,
	handlerChangePrice,
	handlerChangeStock,
	handlerUpdateContent,
	getLibrariesCompounds,
	handlerChangeQuantity,
	getLibrariesCompoundsIds,
	addAllLibrariesToProject,
	searchLibrariesCompounds,
	getLibrariesCompoundsError,
	handlerChangeAccessibility,
	handlerChangeCheckboxSelect,
	getLibrariesCompoundsSuccess,
	addAllLibrariesToProjectError,
	getLibrariesCompoundsIdsError,
	searchLibrariesCompoundsError,
	handlerChangeCheckboxSelectAll,
	getLibrariesCompoundsIdsSuccess,
	addAllLibrariesToProjectSuccess,
	handlerClearAllCheckboxSelected,
	searchLibrariesCompoundsSuccess,
} = librariesCompoundsSlice.actions;

export default librariesCompoundsSlice.reducer;
