import { createSlice } from "@reduxjs/toolkit";

// Type
import {
  DetailsType, UnitHightLight,
} from './type';

interface IInitialState {
  highlightData?: UnitHightLight[];
  message?: string,
  status?: number | null,
  loading?: boolean;
  downloadLoading?: {
    xls?: boolean;
    sdf?: boolean;
  },
  loadingLibDetails?: boolean;
  libDetailsData?: DetailsType
}

const initialState: IInitialState = {
  message: '',
  status: null,
  loading: false,
  highlightData: [],
  downloadLoading: {
    xls: false,
    sdf: false,
  },
  loadingLibDetails: false,
  libDetailsData: {
    lib: {},
    libPrices: [],
    libTargets: [],
  },
};

export const librariesProductSlice = createSlice({
  name: "librariesProduct",
  initialState,
  reducers: {
    getHighlightLib: (state, action) => {
      state.loading = true;
    },
    getHighlightLibError: (state) => {
      state.loading = false;
    },
    getHighlightLibSuccess: (state, action) => {
      state.loading = false;
      state.highlightData = action.payload;
    },

    getLibrariesFullDetails: (state, action) => {
      state.loadingLibDetails = true;
    },
    getLibrariesFullDetailsError: (state, action) => {
      state.loadingLibDetails = false;

      state.message = action.payload;
    },
    getLibrariesFullDetailsSuccess: (state, action) => {
      state.loadingLibDetails = false;
      state.libDetailsData = action.payload;
    },

    clearMessage: (state) => {
      state.message = '';
    },

    downloadFile: (state, action) => {
      if (state.downloadLoading) {
        state.downloadLoading[action.payload.type as keyof typeof state.downloadLoading] = true;
      }
    },
    downloadFileError: (state, action) => {
      if (state.downloadLoading) {
        state.downloadLoading[action.payload as keyof typeof state.downloadLoading] = false;
      }
    },
    downloadFileSuccess: (state, action) => {
      if (state.downloadLoading) {
        state.downloadLoading[action.payload as keyof typeof state.downloadLoading] = false;
      }
    },
  },
});

export const {
  downloadFile,
  clearMessage,
  getHighlightLib,
  downloadFileError,
  downloadFileSuccess,
  getHighlightLibError,
  getHighlightLibSuccess,
  getLibrariesFullDetails,
  getLibrariesFullDetailsError,
  getLibrariesFullDetailsSuccess,
} = librariesProductSlice.actions;

export default librariesProductSlice.reducer;
