import {call, fork, put, take,} from "redux-saga/effects";

// Actions
import * as actionsCreator from "./reducer";

// Type
import {ActionType, ApiResponse} from './type'

export function* requestSagaStart(api: any, action: any) {
	yield fork(sendFormDataWatcher, api, action);
}

/**
 *  Send form data watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* sendFormDataWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionType = yield take(actionsCreator.sendForm.type);

			const compoundFormat = actions?.payload?.data?.compoundFormat || '';
			const dryPowder = `Dry powder, ${actions?.payload?.data?.format}` || '';
			const packagingGroup = actions?.payload?.data?.packagingGroup || '';
			const solution = actions?.payload?.data?.solution;
			const dmsoWater = actions?.payload?.data?.dmsoWater;

			const formData = {
				email: actions?.payload?.data?.email,
				title: actions?.payload?.data?.title,
				target: actions?.payload?.data?.target,
				setSize: actions?.payload?.data?.setSize,
				inquiry: actions?.payload?.data?.inquiry,
				lastName: actions?.payload?.data?.lastName,
				firstName: actions?.payload?.data?.firstName,
				companyName: actions?.payload?.data?.companyName,
				selectivity: actions?.payload?.data?.selectivity,
				formulation: compoundFormat !== 'solution' ? dryPowder : `Solution, ${packagingGroup === 'Other' ? solution : packagingGroup}, ${dmsoWater}`,
				purity: actions?.payload?.data?.groupRadio2 === 'Other3' ? actions?.payload?.data?.compoundsPurity : actions?.payload?.data?.groupRadio2,
				activity: actions?.payload?.data?.groupRadio3 === 'Other4' ? actions?.payload?.data?.activityRange : actions?.payload?.data?.groupRadio3,
			};

			const res: ApiResponse<any> = yield call(api.request, "bioInfoRequest", formData);

			if (!res.ok) {
				yield put(actionsCreator.sendFormError(res));
			} else {
				yield put(actionsCreator.sendFormSuccess(res));
			}
		} catch (e) {
			console.log("CATCH ERROR: sendFormDataWatcher: ", e);
		}
	}
}
