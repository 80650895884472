import React, {useEffect} from "react";

// Redux connect
import {shallowEqual} from "react-redux";
import {useAppDispatch, useAppSelector,} from "lib/hooks/useDispatch";

// Images
import IconMenu1 from "assets/img/icon-menu-1.png";
import IconMenu2 from "assets/img/icon-menu-2.png";
import IconMenu3 from "assets/img/icon-menu-3.png";
import IconMenu4 from "assets/img/icon-menu-4.png";

// Actions
import {getAlllibraries, getCompoundsCount} from "pages/libraries/reducer";

// Components
import Column from "./сolumn";
import {EmptyList} from './empty-list';

// Type
import {IMenu} from "../type";

export const Menu: React.FC<IMenu> = () => {
	const dispatch = useAppDispatch();

	const diseasesData = useAppSelector((store) => store.libraries.diseases, shallowEqual);
	const bioActiveData = useAppSelector((store) => store.libraries.bioActiveScreen, shallowEqual);
	const drugRepurposingData = useAppSelector((store) => store.libraries.drugRepurposing, shallowEqual);
	const signalingPathwaysData = useAppSelector((store) => store.libraries.signalingPathways, shallowEqual);

	useEffect(() => {
		dispatch(getAlllibraries());
		dispatch(getCompoundsCount());
	}, []);

	const isEmpty = !signalingPathwaysData.length &&
		!drugRepurposingData.length &&
		!bioActiveData.length;

	const configMenu = [
		{
			src: IconMenu1,
			calcNumber: 0.3,
			alt: 'Icon image 1',
			data: signalingPathwaysData,
			title: 'Signaling Pathways and Protein Classes Related Libraries',
		},
		{
			src: IconMenu2,
			calcNumber: 0.7,
			alt: 'Icon image 2',
			data: bioActiveData,
			children: {
				src: IconMenu4,
				calcNumber: 0.7,
				data: diseasesData,
				alt: 'Icon image 4',
				title: 'Disease Related Compound Libraries',
			},
			title: 'Bioactive Screening Compound Libraries',
		},
		{
			src: IconMenu3,
			calcNumber: 0.9,
			alt: 'Icon image 3',
			data: drugRepurposingData,
			title: 'Drug Repurposing and Related Libraries',
		},
	];

	if (isEmpty) {
		return <EmptyList/>;
	}

	return (
		<div className='wrapper-menu'>
			<nav className='menu flex flex-jc-between flex-ai-start wrapper'>
				{configMenu.map((item, i) => {
					return <Column
						src={item.src}
						alt={item.alt}
						data={item.data}
						title={item.title}
						imgClass='menu__img'
						titleClass='menu__title'
						key={`${item.title}_${i}`}
						linkClass='menu__list-item'
						calcNumber={item.calcNumber}
						children={item?.children || {}}
						imgArrorClass='menu__list-item-arrow'
						ulClass='menu__col flex-direction-column'
						wrapperClass='menu__col flex flex-ai-start flex-direction-column'
					/>
				})}
			</nav>
		</div>
	);
};
