import React from "react";

// Components
import Button from 'components/ui-kit/button';
import TextField from 'components/ui-kit/field-text';

// Lib
import * as yup from "yup";
import Cookies from "js-cookie";
import {useForm} from "react-hook-form";
import {patternRxpCheckEng} from "lib/helpers";
import {yupResolver} from '@hookform/resolvers/yup';
import {amplitudeInstance, events} from "constans/cookie";
import {useAppDispatch, useAppSelector} from "lib/hooks/useDispatch";

// Actions
import {createProject} from 'pages/projects/reducer';
import {shallowEqual} from "react-redux";

import {NavigateFunction} from "react-router-dom";

type TUnit = {
	data?: any,
	text?: string,
	url?: string,
	navigate?: NavigateFunction,
	handlerCloseModal?: () => {},
	isAddItem?: string | undefined,
	isLibrary?: boolean | undefined,
};

interface TProjectsAdd {
	modalProps?: TUnit
}

// Schema
const schema = yup.object({
	name: yup.string().matches(patternRxpCheckEng, "Use Latin symbols only.").max(100, 'The text must be less than 100 symbols').required('The text must be less than 100 symbols'),
	description: yup.string().matches(patternRxpCheckEng, "Use Latin symbols only.").max(200, 'The text must be less than 200 symbols').required('The text must be less than 200 symbols'),
}).required();
type FormData = yup.InferType<typeof schema>;

const ProjectsAdd: React.FC<TProjectsAdd> = (props) => {
	const dispatch = useAppDispatch();

	const url = props?.modalProps?.url || '';
	const navigate = props?.modalProps?.navigate;

	const text = props?.modalProps?.text;
	const result = props?.modalProps?.data || [];
	const isLibrary = props?.modalProps?.isLibrary;
	const isAddItem = props?.modalProps?.isAddItem;

	const loading = useAppSelector((store) => store.projects.loadingCreateProject, shallowEqual);

	const {register, handleSubmit, formState: {errors, isDirty, isValid}} = useForm<FormData>({
		resolver: yupResolver(schema)
	});

	const onSubmit = (data: FormData) => {
		const isText = !!text?.length ? {text: text} : {};
		dispatch(createProject({
			...data,
			...isText,
			isAddItem,
			result,
			isLibrary,
			text,
			url,
			navigate
		}));
		const isCookie = Cookies.get('cookieName');

		if (isCookie) {
			amplitudeInstance.logEvent(events.create_project, {sent_form: true});
		}
	};

	return <div className='projects-modal'>
		<div className="projects-modal__create flex flex-direction-column">
			<h4 className="projects-modal__title mb30">
				Add new project
			</h4>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="projects-modal__form flex flex-ai-center flex-jc-between flex-direction-column"
			>
				<div className="full-width">
					<TextField
						required
						name='name'
						label='Project name'
						isError={!!errors?.name}
						placeholder='Project name'
						className="full-width mb10"
						register={register('name')}
						msgError={errors?.name ? errors?.name.message : ''}
					/>
					<TextField
						required
						name='description'
						className="full-width"
						label='Project description'
						isError={!!errors?.description}
						placeholder='Project description'
						register={register('description')}
						msgError={errors?.description ? errors?.description.message : ''}
					/>
				</div>
				<div className='flex flex-jc-center'>
					<Button
						variable='info'
						label='Create'
						loading={loading}
						type='submit'
						disabled={!isValid && !isDirty}
					/>
				</div>
			</form>
		</div>
	</div>;
};

export default ProjectsAdd;
