// @ts-nocheck
import React from "react";

// Lib
import {sanitize} from 'dompurify';
import {shallowEqual} from "react-redux";
import {useAppSelector} from "lib/hooks/useDispatch";

// Components
import {toast, ToastContainer} from 'react-toastify';

// Styles
import 'react-toastify/dist/ReactToastify.css';

// Custom pages
// Info notification
const MsgInfo = (props) => {
	const {text, title} = props;

	return (
		<div className="messages messages-info">
			{title && <h2 className="messages__title-info" dangerouslySetInnerHTML={{__html: sanitize(title || '')}}/>}
			{text && <p className="messages__text-info" dangerouslySetInnerHTML={{__html: sanitize(text || '')}}/>}
		</div>
	)
};

// Icons
const iconInfo = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
	<path
		d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14C11 14.55 10.55 15 10 15ZM11 7H9V5H11V7Z"
		fill="#005FB7"/>
</svg>

const Messages: React.FC = () => {
	const type = useAppSelector((store) => store?.messages.type);
	const text = useAppSelector((store) => store?.messages.text);
	const title = useAppSelector((store) => store?.messages.title);
	const uniqId = useAppSelector((store) => store?.messages.uniqId);
	const className = useAppSelector((store) => store?.messages.className);
	const configMessages = useAppSelector((store) => store?.messages.configMessages, shallowEqual);

	if (!type) return null;

	switch (type) {
		case 'success':
			toast.success(text, {
				...configMessages,
				className: className ? className : '',
				position: configMessages?.position || 'top-right',
			});
			break;
		case 'error':
			toast.error(text, {
				...configMessages,
				className: className ? className : '',
				position: configMessages?.position || 'top-right',
			});
			break;
		case 'info':
			toast.info(
				<MsgInfo
					type={type}
					text={text}
					title={title}
				/>,
				{
					...configMessages,
					icon: iconInfo,
					hideProgressBar: false,
					className: className ? className : '',
					position: configMessages?.position || 'top-right',
				});
			break;
		case 'custom':
			toast(text, {
				...configMessages,
				className: className ? className : '',
				position: configMessages?.position || 'top-right',
			});
			break;

		default:
			console.log('Not found notification!');
			break;
	}

	return <ToastContainer style={{ width: 'auto' }}/>;
};

export default Messages;
