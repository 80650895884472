import {createSlice} from '@reduxjs/toolkit';

// type
export type typeConfigMessages = {
  rtl?: boolean | undefined,
  theme?: string | undefined,
  position?: string | undefined,
  autoClose?: number | undefined,
  draggable?: boolean | undefined,
  newestOnTop?: boolean | undefined,
  pauseOnHover?: boolean | undefined,
  closeOnClick?: boolean | undefined,
  hideProgressBar?: boolean | undefined,
  pauseOnFocusLoss?: boolean | undefined,
}

// Interface
interface IInitialState {
  uniqId?: string,
  type?: string | undefined,
  text?: string | undefined,
  title?: string | undefined,
  className?: string | undefined,
  configMessages?: typeConfigMessages,
}

const initialUniqId = new Date() + '';
const initialState: IInitialState = {
  type: '',
  text: '',
  title: '',
  className: '',
  uniqId: initialUniqId,
  configMessages: {
    rtl: false,
    draggable: true,
    autoClose: 3000,
    theme: 'colored',
    newestOnTop: false,
    closeOnClick: true,
    pauseOnHover: true,
    position: 'top-right',
    hideProgressBar: false,
    pauseOnFocusLoss: true,
  },
};

export const messageSlice = createSlice({
  name: 'messages',
  initialState: initialState,
  reducers: {
    showMessage: (state, action) => {
      state.type = action.payload.type;
      state.text = action.payload.text;
      state.title = action.payload.title;
      state.uniqId = action.payload.uniqId;
      state.className = action.payload.className;

      state.configMessages = {
        ...state.configMessages,
        ...action.payload?.configMessages,
      }
    },
    hideMessage: (state, action) => {
    },
  },
});

export const {showMessage, hideMessage} = messageSlice.actions;

export default messageSlice.reducer;
