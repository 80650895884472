import {createSlice} from "@reduxjs/toolkit";

// Interface
interface IInitialState {
	loading: boolean,
	message: string,
	status: string | number,
}

const initialState: IInitialState = {
	status: '',
	message: '',
	loading: false,
};

export const contactUsSlice = createSlice({
	name: "contactUs",
	initialState,
	reducers: {
		sendContacts: (state, action) => {
			state.loading = true;
		},
		sendContactsError: (state, action) => {
			state.loading = false;
			state.status = action.payload.status;
			state.message = action.payload.message;
		},
		sendContactsSuccess: (state, action) => {
			state.loading = false;
			state.status = action.payload.status;
		},
	},
});

export const {
	sendContacts,
	sendContactsError,
	sendContactsSuccess
} = contactUsSlice.actions;

export default contactUsSlice.reducer;
