import {
  ApisauceInstance
} from 'apisauce';

// Url
import { url } from '../api.url';

const resendMail = (api: ApisauceInstance) => {
  const resendMail = (id?: string) => api.get(`${url.resendMail}${id}`);

  return {
    resendMail,
  };
};

export default resendMail;
