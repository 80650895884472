import React, {useState} from 'react';

// Components
import Avatar from 'react-avatar-edit';
import Button from 'components/ui-kit/button';

// Actions
import {updateAvatarUser} from 'pages/account/reducer';

// Classname
import clsx from 'clsx';

// Lib
import {convertBase64ToBlob} from 'lib/helpers';
import {useAppDispatch} from "lib/hooks/useDispatch";

export const Crop: React.FC<any> = (props) => {
	const dispatch = useAppDispatch();

	const {modalProps, handlerClose} = props;
	const imageData = modalProps.data;

	const [preview, setPreview] = useState('');

	const handlerCrop = () => {
		const blob = convertBase64ToBlob(preview);
		dispatch(updateAvatarUser(blob));
	}

	const onClose = () => {
		setPreview('');
	}

	const onCrop = (preview: string) => {
		setPreview(preview);
	}

	const onBeforeFileLoad = (e: React.ChangeEvent<HTMLInputElement>) => {
		const maxSize = 5 * (1024 * 1024);
		const size = e?.target?.files
			? e?.target?.files[0]?.size
			: 0;

		if (size > maxSize) {
			alert("Image is too big!");
			e.target.value = "";
		}
	};

	const cnPreview = clsx(
		'crop__preview',
		!preview ? 'crop__preview--active' : '',
	);

	const styleAvatar = {
		width: '100%',
		height: '100%',
		color: '#9B9B9B',
		cursor: 'pointer',
		fontSize: '1.4rem',
		lineHeight: '1.8rem',
		display: 'inline-block',
		fontFamily: 'Poppins',
	};

	return (
		<div className="crop">
			<div className="flex flex-ai-start flex-ai-center-mobile flex-direction-column-mobile mb15">
				<div className="flex flex-ai-center flex-direction-column">
					<h4 className="crop__title mb20">Default usage</h4>
					<div className="crop__border-avatar">
						<Avatar
							width={390}
							height={295}
							src={imageData}
							onCrop={onCrop}
							imageWidth={390}
							onClose={onClose}
							imageHeight={295}
							labelStyle={styleAvatar}
							onBeforeFileLoad={onBeforeFileLoad}
						/>
					</div>
				</div>
				<div className="flex flex-ai-center flex-direction-column ml10">
					<h4 className="crop__title mb20">Preview</h4>
					<div className={cnPreview}>
						{preview && <img
							src={preview}
							alt="Preview"
							className="ml10"
						/>}
          </div>
				</div>
			</div>


			<div className="flex flex-jc-end">
				<Button
					color="error"
					label="Close"
					variable="outline"
					className="mr10"
					onPress={handlerClose}
				/>
				<Button
					variable="info"
					label="Save"
					onPress={handlerCrop}
				/>
			</div>
		</div>
	);
};

export default Crop;
