// @ts-nocheck
// Api request
import apiEnamine from "../api";

// Config
import config from "../lib/config";

// Api
import {LibrariesFN} from "./libraries/libraries.api";

// Make basic configuration
const apiProvider = apiEnamine;

type EnvApiConfig = {
	[env: string]: string;
};

const apiConfig = {
	apiUrl: config.api.apiUrl,
	timeout: 72000000,
};

const initApi = (store: any) => {
	// Request transformation
	const requestTransform = (req: any, when: any) => {
		if (when === "before") {
			const msgId = Math.random().toString(36).substring(2);
			const stateStore = store.getState();

			const authorization = stateStore?.settings?.user?.token ? {Authorization: `Bearer ${stateStore?.settings?.user?.token}`} : {};

			req.headers = {
				...authorization,
				...req.headers,
				"Request-id": msgId,
				"Accept-Language": (stateStore?.app?.lang || "EN").toLowerCase(),
			};

			// Dispatch action when request
			try {
				store.dispatch({
					type: "API_REQUEST",
					id: msgId,
					url: req.url || "",
					method: req.method || "",
				});
			} catch (e) {
				console.log("ATTENTION - Can't dispatch store");
			}
		}
	};

	// Response transformation
	const responseTransform = (res: any, when: any) => {
		if (when === "before") {
			try {
				store.dispatch({
					type: "API_RESPONSE",
					id:
						res.config.headers["Request-id"] ||
						res.config.headers["request-id"] ||
						"",
				});
			} catch (e) {
				console.log("ATTENTION - Can't dispatch store");
			}
		}
	};

	// Convert final result to app format
	const convertResult = (resp: any, dataConvertor: any) => {
		let isHeaders = {};
		const url = resp.config.url;

		const urlPattern1 = /\/libs\/.+\/files\/.+/;
		const urlPattern2 = /\/api\/highlights\/\d+\/image/;

		if (
			url.includes('libs/media/download/') ||
			urlPattern1.test(url) ||
			urlPattern2.test(url)
		) {
			isHeaders = {
				headers: resp.headers,
			}
		}

		return {
			...isHeaders,
			status: resp.status,
			ok: resp.ok,
			data: resp.ok && dataConvertor ? dataConvertor(resp.data) : resp.data,
		};
	};

	// Init provider API
	const api = apiProvider.create(
		apiConfig,
		store,
		requestTransform,
		responseTransform
	);

	// Dispatch error action
	const dispatchError = (resp: any) => {
		let error = {text: "API Error default text", message: ""};
		if (api?._getError && typeof api?._getError === 'function') {
			error = api?._getError(resp);
			console.log(error);
		}
		store.dispatch({
			type: "MESSAGE_SHOW",
			modalType: "error",
			msg: {
				text: error.message,
				type: "error",
				data: {
					...error,
					traceId: resp?.headers?.['trace-id'],
					requestId: resp?.config?.headers?.['Request-id'],
				},
			},
		});
	};

	const apiRequest = (
		methodName: keyof ReturnType<LibrariesFN>,
		reqData: any,
		reqParams: any,
		disableAutoError: any
	) => {
		return new Promise((resolve) => {
			if (!Object.prototype.hasOwnProperty.call(api, methodName)) {
				console.error(`API has no method: ${methodName}`);
				resolve(false);
				return false;
			}

			let apiMethod;
			let outData = reqData;

			if (typeof api[methodName] === 'function') {
				apiMethod = api[methodName];
			}

			if (
				typeof api[methodName] === 'object' && api[methodName] !== null &&
				Object.prototype.hasOwnProperty.call(api[methodName], 'send')
			) {
				apiMethod = api[methodName].send;

				if (typeof api[methodName].out === 'function') {
					outData = api[methodName].out(reqData);
				}
			}

			if (!apiMethod) {
				resolve(false);
				return false;
			}

			// Send to api provider
			apiMethod
				.call(null, outData || {}, reqParams || {})
				.then((response: any) => {
					if (!response.ok && !disableAutoError) {
						dispatchError(response);
					}

					if (response?.status !== 403 || response?.status !== 401) {
						resolve(
							convertResult(
								response,
								typeof api[methodName].in === 'function' ? api[methodName].in : false
							)
						);
					}
				});
		});
	};

	return {
		request: apiRequest,
	};
};

export default initApi;
