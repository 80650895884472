export const categories = [
  {
    id: 1,
    isHeader: true,
    link: '/libraries',
    title: 'EBC libraries',
  },
  {
    id: 2,
    isHeader: true,
    title: 'Services',
    link: '/services',
  },
  {
    id: 3,
    isHeader: true,
    title: 'Search',
    // link: '/search?tab=name',
    link: '/search?tab=bio-search',
  },
  {
    id: 4,
    isHeader: false,
    title: 'Terms of Service',
    link: '/terms-of-service',
  },
  {
    id: 5,
    isHeader: false,
    title: 'Privacy Policy',
    link: '/privacy-policy',
  },
  {
    id: 6,
    isHeader: false,
    isRedirect: true,
    title: 'Publications',
    link: 'https://enamine.net/publications',
  },

  {
    id: 7,
    isHeader: false,
    title: 'Contact us',
    link: '/contact-us',
  },
  {
    id: 8,
    isHeader: false,
    title: 'About us',
    link: '/about-us',
  },
];

export const dropdownMenu = [
  {label: 'My account', url: '/account', isShow: true},
  {label: 'My projects', url: '/projects', isShow: true},
  {label: 'My requests', url: '/request', isShow: false},
  {label: 'Settings', url: '/settings', isShow: false},
  {label: 'Logout', url: '', isLogout: true, isShow: true}
];
