// Services
import modal from "./modal";
import screen from "./screen";
import loader from "./loader";
import settings from "./settings";
import messages from "./messages";
import timer from "pages/timer/reducer";

// Pages
import auth from "pages/auth/reducer";
import main from "pages/main/reducer";
import orders from "pages/orders/reducer";
import search from "pages/search/reducer";
import account from "pages/account/reducer";
import checkout from "pages/checkout/reducer";
import projects from "pages/projects/reducer";
import thesaurus from "pages/thesaurus/reducer";
import libraries from "pages/libraries/reducer";
import contactUs from 'pages/contact-us/reducer';
import requestForm from "pages/request-form/reducer";
import resetPassword from "pages/reset-password/reducer";
import forgotPassword from "pages/forgot-password/reducer";
import moleculeProduct from "pages/molecule-product/reducer";
import librariesProduct from "pages/libraries-product/reducer";
import librariesCompounds from "pages/libraries-compounds/reducer";

const rootReducer = {
	main: main,
	auth: auth,
	timer: timer,
	modal: modal,
	loader: loader,
	screen: screen,
	search: search,
	orders: orders,
	account: account,
	checkout: checkout,
	messages: messages,
	projects: projects,
	settings: settings,
	thesaurus: thesaurus,
	contactUs: contactUs,
	libraries: libraries,
	requestForm: requestForm,
	resetPassword: resetPassword,
	forgotPassword: forgotPassword,
	moleculeProduct: moleculeProduct,
	librariesProduct: librariesProduct,
	librariesCompounds: librariesCompounds,
};

export default rootReducer;
