import React, {useState} from 'react';

// Classnames
import clsx from 'clsx';

// Images
import Search from 'assets/img/search.png';
import IconInfo from 'assets/img/info-icon.png';

// Svg
const SvgSearch = <svg className='field-text__icon-right' xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                       viewBox="0 0 14 14" fill="none">
	<path opacity="0.5" fillRule="evenodd" clipRule="evenodd"
	      d="M3.75085 9.30585C2.1217 7.21353 2.26885 4.18642 4.19231 2.26296C6.2751 0.180168 9.65198 0.180168 11.7348 2.26296C13.8176 4.34576 13.8176 7.72264 11.7348 9.80544C9.81132 11.7289 6.78422 11.876 4.69189 10.2469C4.68287 10.2571 4.67347 10.2671 4.66371 10.2768L1.83528 13.1053C1.57493 13.3656 1.15283 13.3656 0.892476 13.1053C0.632126 12.8449 0.632127 12.4228 0.892476 12.1625L3.7209 9.33403C3.73066 9.32427 3.74065 9.31488 3.75085 9.30585ZM5.13512 3.20577C3.57302 4.76787 3.57302 7.30053 5.13512 8.86263C6.69721 10.4247 9.22987 10.4247 10.792 8.86263C12.3541 7.30053 12.3541 4.76787 10.792 3.20577C9.22987 1.64368 6.69721 1.64368 5.13512 3.20577Z"
	      fill="#FEFEFE"/>
</svg>

// Type
type SelectType = {
	step?: string,
	style?: any,
	value?: any,
	id?: string,
	name?: string,
	rows?: number,
	register?: any,
	title?: string,
	hint?: boolean,
	isCtrl?: boolean,
	label?: string,
	onKeyDown?: any,
	onKeyPress?: any,
	onChange?: any,
	pattern?: string,
	subLabel?: string,
	hintText?: string,
	msgError?: string,
	iconLeft?: string,
	required?: boolean,
	disabled?: boolean,
	isError?: boolean,
	className?: string,
	isIconInfo?: boolean,
	placeholder?: string,
	isIconLeft?: boolean,
	isTextarea?: boolean,
	iconSvgLeft?: boolean,
	autoComplete?: string,
	children?: JSX.Element,
	ref?: HTMLInputElement,
	innerTypeInput?: string,
	classNameInput?: string,
	isShowPassword?: boolean,
};

export const FieldText: React.FC<SelectType> = (props) => {
	const {
		step,
		rows,
		hint,
		label,
		title,
		style,
		value,
		isCtrl,
		pattern,
		isError,
		register,
		onChange,
		disabled,
		hintText,
		required,
		msgError,
		onKeyDown,
		className,
		isIconLeft,
		isTextarea,
		onKeyPress,
		isIconInfo,
		placeholder,
		autoComplete,
		classNameInput,
		isShowPassword,
		innerTypeInput,
	} = props
	const [passwordShown, setPasswordShown] = useState<boolean>(false);

	const handlerShowPassword = () => {
		setPasswordShown(!passwordShown);
	};

	const handlerOnKeyPress = (e: any) => {
		if (e.ctrlKey && e.which === 13 && onKeyPress) {
			onKeyPress(e);
		}

		if (!isCtrl && onKeyPress && e.which === 13) {
			onKeyPress(e);
		}
	};

	const cnWrapper = clsx(
		'field-text flex flex-direction-column',
		disabled ? 'field-text__disabled' : '',
		className ? className : '',
	);

	const cnInput = clsx(
		'field-text__input',
		isIconLeft ? 'field-text__input--icon-left' : '',
		isError ? 'field-text__input--active' : '',
		classNameInput ? classNameInput : ''
	);

	const cnRequired = clsx(
		required ? 'field-text__required' : '',
	);

	const titleRequired = required ? 'Field is required' : '';

	const type = innerTypeInput
		? innerTypeInput
		: isShowPassword
			? passwordShown
				? 'text'
				: 'password'
			: 'text';

	return (
		<div className={cnWrapper}>
			{label && <div className='flex flex-ai-center flex-jc-between field-text__label'>
        <div className='flex flex-ai-center'>
					{label && <span
            title={titleRequired}
            className={cnRequired}
            dangerouslySetInnerHTML={{ __html: label }}
          />}
	        {isIconInfo && <img
            src={IconInfo}
            alt='Icon info'
            title={title || ''}
            className='field-text__info-icon'
          />}
        </div>
				{isShowPassword && <div
          className='field-text__show'
          onClick={handlerShowPassword}
        >
					{!passwordShown ? 'show' : 'hide'}
        </div>}
      </div>}

			{props.subLabel && <span className='field-text__sub-label'>
				{props.subLabel}
			</span>}

			{props.children && props.children}

			{props.isIconLeft && <img
        alt='Icon search'
				src={props?.iconLeft || Search}
				className='field-text__icon-right'
			/>}

			{props.iconSvgLeft && <>{SvgSearch}</>}

			<div className="relative">
				{isTextarea ?
					<textarea
						rows={rows}
						style={style}
						className={cnInput}
						disabled={disabled}
						required={required}
						placeholder={placeholder}
						autoComplete={autoComplete}
						onKeyPress={handlerOnKeyPress}
						onChange={onChange ? onChange : {}}

						{...register}
					/>
					:
					<input
						step={step}
						type={type}
						style={style}
						value={value}
						pattern={pattern}
						className={cnInput}
						disabled={disabled}
						required={required}
						onKeyDown={onKeyDown}
						placeholder={placeholder}
						autoComplete={autoComplete}
						onKeyPress={handlerOnKeyPress}
						onChange={onChange ? onChange : {}}

						{...register}
					/>}
			</div>

			{!!hint && <span className='field-text__hint'>{hintText}</span>}
			{!!isError && <div className='field-text__error'>{msgError}</div>}
		</div>
	);
};

export default FieldText;