import {createSlice} from '@reduxjs/toolkit';

// Type
import {IInitialState} from "./type";

export const initialStateDataKeyBoard = {
	libraries: {
		size: 5,
		number: 0,
		content: [],
		totalPages: 1,
		totalElements: 0,
	},
	compounds: {
		size: 5,
		number: 0,
		content: [],
		totalPages: 1,
		totalElements: 0,
	},
};

const initialState: IInitialState = {
	searchDataKeyBoard: {
		type: '',
		...initialStateDataKeyBoard,
	},
	type: '',
	value: '',
	message: '',
	loading: false,
	isSearch: false,
	recentSearchList: [],
	recentBioSearchList: [],
	historyStructureSearch: [],
	price: {
		value: 0,
		label: '',
	},
	structureSearch: {
		structureType: 'compounds',
		structureData: {
			linkers: {
				size: 10,
				number: 0,
				content: [],
				totalPages: 1,
				totalElements: 0,
			},
			compounds: {
				size: 10,
				number: 0,
				content: [],
				totalPages: 1,
				totalElements: 0,
			},
		},
		structureLoading: {
			loadingProtac: false,
			loadingLinker: false,
			loadingCompounds: false,
		},
	},
}

export const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		handlerChangeIsSearch: (state, action) => {
			state.isSearch = action.payload;
		},

		handlerChangeTypeStructure: (state, action) => {
			state.structureSearch.structureType = action.payload;
		},

		compoundsSimilarity: (state, action) => {
			state.structureSearch.loadingCompounds = true;
		},
		compoundsSimilarityError: (state) => {
			state.structureSearch.loadingCompounds = false;
		},
		compoundsSimilaritySuccess: (state, action) => {
			state.structureSearch.loadingCompounds = false;

			state.structureSearch.structureData.compounds = action.payload;
		},

		compoundsSubstructure: (state, action) => {
			state.structureSearch.loadingCompounds = true;
		},
		compoundsSubstructureError: (state) => {
			state.structureSearch.loadingCompounds = false;
		},
		compoundsSubstructureSuccess: (state, action) => {
			state.structureSearch.loadingCompounds = false;

			state.structureSearch.structureData.compounds = action.payload;
		},

		compoundsFullfragment: (state, action) => {
			state.structureSearch.loadingCompounds = true;
		},
		compoundsFullfragmentError: (state) => {
			state.structureSearch.loadingCompounds = false;
		},
		compoundsFullfragmentSuccess: (state, action) => {
			state.structureSearch.loadingCompounds = false;

			state.structureSearch.structureData.compounds = action.payload;
		},

		linkersSubstructure: (state, action) => {
			state.structureSearch.loadingLinkers = true;
		},
		linkersSubstructureError: (state) => {
			state.structureSearch.loadingLinkers = false;
		},
		linkersSubstructureSuccess: (state, action) => {
			state.structureSearch.loadingLinkers = false;

			state.structureSearch.structureData.linkers = action.payload;
		},

		linkersSimilarity: (state, action) => {
			state.structureSearch.loadingLinkers = true;
		},
		linkersSimilarityError: (state) => {
			state.structureSearch.loadingLinkers = false;
		},
		linkersSimilaritySuccess: (state, action) => {
			state.structureSearch.loadingLinkers = false;

			state.structureSearch.structureData.linkers = action.payload;
		},

		linkersFullfragment: (state, action) => {
			state.structureSearch.loadingLinkers = true;
		},
		linkersFullfragmentError: (state) => {
			state.structureSearch.loadingLinkers = false;
		},
		linkersFullfragmentSuccess: (state, action) => {
			state.structureSearch.loadingLinkers = false;

			state.structureSearch.structureData.linkers = action.payload;
		},

		getStructureSearch: (state, action) => {
			const isClearState = action.payload.isClearState;
			if (isClearState) {
				state.structureSearch.structureData.linkers = initialState.structureSearch.structureData.linkers;
				state.structureSearch.structureData.compounds = initialState.structureSearch.structureData.compounds;
			}
		},
		getStructureSearchError: (state) => {
		},
		getStructureSearchSuccess: (state) => {
		},

		handlerChangePriceCartCompound: (state, action) => {
			state.price = action.payload;
		},

		searchText: (state, action) => {
			state.loading = true;
			state.value = action.payload.value;

			state.type = '';
			state.message = '';
		},
		searchTextError: (state, action) => {
			state.loading = false;
			state.type = action.payload.type;
			state.message = action.payload.message;
		},
		searchTextSuccess: (state, action) => {
			state.loading = false;
			state.searchDataKeyBoard = action.payload;

			state.type = '';
			state.message = '';
		},

		handlerChangeTypeData: (state, action) => {
			if (state?.searchDataKeyBoard) {
				state.searchDataKeyBoard.type = action.payload;
			}
		},

		handlerChangeStucturePageEbc: (state, action) => {
			if (state.structureSearch.structureData) {
				state.structureSearch.structureData.compounds.number = action.payload;
			}
		},
		handlerChangeStucturePageLinkers: (state, action) => {
			if (state.structureSearch.structureData) {
				state.structureSearch.structureData.linkers.number = action.payload;
			}
		},

		handlerChangePageLib: (state, action) => {
			if (state.searchDataKeyBoard && state.searchDataKeyBoard.libraries) {
				state.searchDataKeyBoard.libraries.number = action.payload;
			}
		},
		handlerChangePageCompound: (state, action) => {
			if (state.searchDataKeyBoard && state.searchDataKeyBoard.compounds) {
				state.searchDataKeyBoard.compounds.number = action.payload;
			}
		},

		historyList: (state, action) => {
			const name = action?.payload?.storeName;
			// @ts-ignore
			state[name] = action?.payload?.data;
		},
		recentSearchListCheck: (state) => {
			const list = localStorage.getItem('recentSearchList') || '[]';
			state.recentSearchList = JSON.parse(list) || [];
		},
		recentBioSearchListCheck: (state) => {
			const list = localStorage.getItem('recentBioSearchList') || '[]';
			state.recentBioSearchList = JSON.parse(list) || [];
		},
		structureSearchCheck: (state) => {
			const list = localStorage.getItem('structureList') || '[]';
			state.historyStructureSearch = JSON.parse(list) || [];
		},
		updateHistoryStructureSearch: (state, action) => {
			state.historyStructureSearch = action.payload;
		},
		deleteItemStructureHistory: (state, action) => {
		},
	},
});

export const {
	handlerChangeIsSearch,

	searchText,
	searchTextError,
	searchTextSuccess,

	historyList,
	recentBioSearchListCheck,

	linkersSimilarity,
	getStructureSearch,
	compoundsSimilarity,
	linkersSubstructure,
	linkersFullfragment,
	structureSearchCheck,
	compoundsFullfragment,
	recentSearchListCheck,
	compoundsSubstructure,
	linkersSimilarityError,
	getStructureSearchError,
	linkersSubstructureError,
	compoundsSimilarityError,
	linkersFullfragmentError,
	linkersSimilaritySuccess,
	getStructureSearchSuccess,
	compoundsFullfragmentError,
	compoundsSubstructureError,
	linkersSubstructureSuccess,
	compoundsSimilaritySuccess,
	deleteItemStructureHistory,
	linkersFullfragmentSuccess,
	compoundsFullfragmentSuccess,
	compoundsSubstructureSuccess,

	handlerChangePageLib,
	handlerChangeTypeData,
	handlerChangePageCompound,
	handlerChangeTypeStructure,
	handlerChangeStucturePageEbc,
	updateHistoryStructureSearch,
	handlerChangePriceCartCompound,
	handlerChangeStucturePageLinkers,
} = searchSlice.actions;

export default searchSlice.reducer;
