import {ApisauceInstance} from 'apisauce';

// Url
import {url} from '../api.url';

type CreateUpdateProjectFormDataType = {
  name?: string,
  description?: string,
};
type AddItemToProject = {
  purity?: number,
  quantity?: number,
  productId?: string,
  prepackage?: number,
  productType?: string,
};
type MoveToProjectId = {
  moveToProjectId?: number,
}
type TAddAllToProject = {
  catNumber: string,
  prepackage: number,
}

const projects = (api: ApisauceInstance) => {
  const getAllProjects = () => api.get(url.projects);
  const deleteProject = (id: number) => api.delete(`${url.projects}/${id}`);
  const orderCopy = (orderId: number) => api.post(`${url.projects}/order-copy`, {orderId} || {});
  const createProject = (formData: CreateUpdateProjectFormDataType) => api.post(url.projects, formData || {});
  const deleteItemProject = (itemId: number, projectId: number) => api.delete(`${url.projects}/${projectId}/items/${itemId}`);
  const updateItemProject = async (data: any) => api.put(`${url.projects}/${data.projectId}/items/${data.itemId}`, data.data || {});
  const moveItemToProject = (itemId: number, formData: MoveToProjectId) => api.put(`${url.projects}/items/${itemId}`, formData || {});
  const addItemToProject = (formData: AddItemToProject, prodjectId: string) => api.post(`${url.projects}/${prodjectId}/items`, formData || {});
  const updateProject = (formData: CreateUpdateProjectFormDataType, projectId: number) => api.put(`${url.projects}/${projectId}`, formData || {});
  const addAllToProject = (formData: TAddAllToProject, projectId: number) => api.post(`profile/projects/${projectId}/items/library`, formData || {});

  return {
    orderCopy,
    createProject,
    updateProject,
    deleteProject,
    getAllProjects,
    addAllToProject,
    addItemToProject,
    moveItemToProject,
    deleteItemProject,
    updateItemProject,
  };
};

export default projects;
