 import {all, call, fork, put, select, take} from "redux-saga/effects";

// Lib
import {hexEncode} from 'lib/helpers';

// Store
import {RootState} from "../store";

// Actions
import * as actionsCreator from "reducers/settings";
import {handlerUpdateContent} from "pages/libraries-compounds/reducer";
 import {handlerChangeCurrentProject, clearListProjects} from "pages/projects/reducer";

// Type
export type ApiResponse<Data extends unknown> = {
	data?: Data;
	ok?: boolean;
	status?: number;
};
type TItem = { [index: string]: string | number | boolean };
type ActionTypeListImg = { type: string, payload: string[] };

function* _select<T>(fn: (state: RootState) => T) {
	const res: T = yield select(fn);
	return res;
}

export const librariesCompounds = (store: RootState) => store.librariesCompounds;

export function* settingsSagaStart(api: any, action: any) {
	yield fork(logoutWatcher, api, action);
	yield fork(checkAuthWacher, api, action);
	yield fork(getListImgWatcher, api, action);
	yield fork(changePriceAppWacther, api, action);
}

/**
 * logout Watcher Sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* logoutWatcher(api: any, action: any) {
	while (true) {
		try {
			yield take(actionsCreator.logout.type);

			const auth = JSON.parse(localStorage.getItem('auth') || '{}');

			const res: ApiResponse<any> = yield call(api.request, 'logout', auth.token);

			if (!res?.ok) {
				yield put(actionsCreator.logoutError());
			} else {
				yield put(actionsCreator.logoutSuccess());
				yield put(handlerChangeCurrentProject(''));
				yield put(clearListProjects());
			}
		} catch (e) {
			console.log('CATCH ERROR: logoutWatcher', e);
		}
	}
}

/**
 * Check Auth Sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* checkAuthWacher(api: any, action: any) {
	while (true) {
		try {
			yield take(actionsCreator.checkAuth.type);

			const getAuth = localStorage.getItem('auth');

			let auth;
			if (getAuth) {
				auth = JSON.parse(getAuth);
			}

			if (auth?.token) {
				yield put(actionsCreator.checkAuthSuccess({
					data: auth
				}));
			}
		} catch (e) {
			console.log('CATCH ERROR in checkAuth', e);
		}
	}
}

/**
 * Get list img saga watcher
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getListImgWatcher(api: any, action: any): Generator<any, void, any> {
	while (true) {
		try {
			const actions: ActionTypeListImg = yield take(actionsCreator.getListImg.type);
			const data = actions.payload;

			if (data.length) {
				const response = yield all(
					data?.map((item: string) => call(
						api.request,
						'chemutilsSmilesImage',
						hexEncode(item, true))
					)
				);

				if (response.length !== response.filter((item: any) => item.ok).length) {
					yield put(actionsCreator.getListImgError());
				} else {
					const convertData = response.map((item: any, i: number) => {
						return {
							oldItem: data[i],
							newItem: URL.createObjectURL(item.data),
						}
					});
					yield put(actionsCreator.getListImgSuccess(convertData || []));
				}
			} else {
				yield put(actionsCreator.getListImgError());
			}
		} catch (e) {
			console.log('CATCH ERROR in getListImgWatcher', e);
		}
	}
}

export function* changePriceAppWacther(api: any, action: any): Generator<any, void, any> {
	while (true) {
		try {
			const actions = yield take(actionsCreator.handlerChangePriceApp.type);

			const state = yield* _select(librariesCompounds);
			const content = state?.data?.content;

			const data = content?.map((item) => ({
				...item,
				currentPrice: actions.payload,
			}));

			yield put(handlerUpdateContent(data));
		} catch (e) {
			console.log('CATCH ERROR in changePriceAppWacther', e);
		}
	}
}
