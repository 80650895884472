import {call, fork, put, take,} from "redux-saga/effects";

// Actions
import * as actionsCreator from "./reducer";
import {modalClose, openModal} from "../../reducers/modal";

// Lib
import {NavigateFunction} from "react-router-dom";

// Type
export type ApiResponse<Data extends unknown> = {
	data?: Data;
	ok?: boolean;
	status?: number;
};

type PayloadTypeNewPassword = {
  token?: string,
	password?: string,
	navigate?: NavigateFunction,
};

type ActionsTypeNewPassword = {
	type?: string,
	payload?: PayloadTypeNewPassword,
};

export function* resetPasswordSagaStart(api: any, action: any) {
	yield fork(resetPasswordWatcher, api, action);
}

/**
 *  Send new password watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* resetPasswordWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionsTypeNewPassword = yield take(actionsCreator.sendNewPassword.type);

			const navigate = actions.payload?.navigate;

			const formData = {
				token: actions.payload?.token,
				password: actions.payload?.password,
			};

			const res: ApiResponse<any> = yield call(api.request, "resetPassword", formData);

			if (!res.ok) {
				yield put(actionsCreator.sendNewPasswordError(res?.data?.error));
			} else {
				yield put(openModal({
					type: 'success',
					subTitle: res.data,
					navigate: navigate,
					title: 'Password reset',
					actionClose: modalClose,
				}));

				yield put(actionsCreator.sendNewPasswordSuccess());
			}
		} catch (e) {
			console.log("CATCH ERROR: forgotPasswordNewWatcher: ", e);
		}
	}
}
