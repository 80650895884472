import {call, fork, put, takeLatest} from "redux-saga/effects";

// Actions
import {hideLoader, showLoader} from "reducers/loader";
import * as actionsCreatorSearch from "../reducer";
import * as actionsCreatorThesaurus from "pages/thesaurus/reducer";

// Lib
import {isEngLang} from 'lib/helpers';

// Store
import {RootState} from "../../../store";

// Store
export const thesaurus = (store: RootState) => store.thesaurus;

// Start Sagas
export function* bioSearchSagaStart(api: any, action: any) {
	yield fork(bioSearchTextWatcher, api);
}

/**
 * Search bio watcher
 *
 * @param {function} api - api object
 * @returns
 */
export function* bioSearchTextWatcher(api: any) {
	yield takeLatest(
		actionsCreatorThesaurus.searchTextBio,
		searchBioTextWorker,
		api
	);
}

/**
 * Search bio worker
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* searchBioTextWorker(api: any, action: any): Generator<any, void, any> {
	try {
		const value = action.payload.value;
		const isEng = isEngLang(value);

		if (isEng) {
			yield put(showLoader());

			yield put(actionsCreatorSearch.historyList({
				data: value || '',
				storeName: 'recentBioSearchList',
				localStoreKey: 'recentBioSearchList',
			}));

			const res = yield call(api.request, 'searchTesaurus', value);

			if (!res.ok) {
				yield put(actionsCreatorThesaurus.searchTextBioError({
					type: 'error',
					message: 'Error in requset search',
				}));
				yield put(hideLoader());
			} else {
				const inputDeboundce: HTMLInputElement | null = document.querySelector('.input-deboundce__list');
				if (inputDeboundce) {
					inputDeboundce.style.display = 'block';
				}

				yield put(actionsCreatorThesaurus.searchTextBioSuccess(res.data.children));
				yield put(hideLoader());
			}
		} else {
			yield put(actionsCreatorThesaurus.searchTextBioError({
				type: 'info',
				message: 'Please use Latin letters only',
			}));
			yield put(hideLoader());
		}
	} catch (e) {
		console.log('ERROR searchBioTextWorker: !!!', e)
	}
}

