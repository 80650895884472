import {selectStyle} from "./react-select-style";

export const configAddress = {
	billing: 'BILLING',
	shipping: 'SHIPPING',
};

export const styleFieldSelect = {
	...selectStyle,
	container: (provided: any) => ({
		...provided,
		'&:hover, &:focus': {},
		width: '100%',
		height: '4.4rem',
		color: '#060606',
		maxWidth: '100%',
		fontWeight: '500',
		fontSize: '1.4rem',
		fontStyle: 'normal',
		lineHeight: '2.4rem',
		letterSpacing: '0.8px',
		fontFamily: 'Poppins',

		"@media only screen and (max-width: 1024px)": {
			...provided["@media only screen and (max-width: 1024px)"],
			maxWidth: "100%",
		},
	}),
	placeholder: (provided: any) => ({
		...provided,
		fontWeight: '500',
		fontSize: '1.4rem',
		fontStyle: 'normal',
		lineHeight: '2.6rem',
		letterSpacing: '0.8px',
		color: '#9B9B9B',
		fontFamily: 'Poppins',
	}),
	menu: (provided: any) => ({
		...provided,
		'&:hover, &:focus': {},
	}),
	control: (provided: any) => ({
		...provided,
		'&:hover, &:focus': {
			borderColor: 'unset',
		},
		height: '4.4rem',
		width: '100%',
		border: 'unset',
		color: '#060606',
		boxShadow: 'none',
		maxWidth: '100%',
		cursor: 'pointer',
		fontWeight: '500',
		fontSize: '1.4rem',
		fontStyle: 'normal',
		borderStyle: 'unset',
		lineHeight: '2.4rem',
		borderRadius: 'unset',
		letterSpacing: '0.8px',
		fontFamily: 'Poppins',
		backgroundColor: 'transparent',
		borderBottom: '1px solid rgba(6, 6, 6, 0.25)',

		"@media only screen and (max-width: 1024px)": {
			...provided["@media only screen and (max-width: 1024px)"],
			maxWidth: "100%",
		},
	}),
};