import React from "react";

// Type
import {UnitLibraries} from "pages/libraries/type";

// Lib
import {compareSort} from "../helpers";

// Pdf
import {Link, StyleSheet, Text} from "@react-pdf/renderer";
import parse, {domToReact, HTMLReactParserOptions} from "html-react-parser";

export const filteredData = (data: any, type: number, isAlphabetica?: boolean) => {
  let result: [] = [];

  if (!isAlphabetica) {
    result = data
      .filter((x: UnitLibraries) => x.type === type)
      .sort((x: UnitLibraries, y: UnitLibraries) =>
        ('' + x?.catNumber || '').localeCompare(y?.catNumber || '')
      ) || [];
  }

  if (isAlphabetica) {
    result = data
      .filter((x: UnitLibraries) => x.type === type)
      .sort(compareSort('name')) || [];
  }

  return result;
};

export const pxToPt = (px?: number) => {
  if(!px) return;
  const ptPerPx = 72 / 96;
  return px * ptPerPx;
}

export const transformHtmlToPdfComponents = (htmlString: string) => {
  const options: HTMLReactParserOptions = {
    replace: (domNode: any) => {
      if (domNode.type === 'tag' && domNode.children) {
        const { name, attribs, children } = domNode;
        const textContent = domToReact(children, options);
        switch (name) {
          case 'p':
            return <><Text style={styles.paragraph}>{textContent}</Text><Text>{'\n'}</Text></>;
          case 'strong':
          case 'b':
            return <Text style={styles.bold}>{textContent}</Text>;
          case 'em':
          case 'i':
            return <Text style={styles.italic}>{textContent}</Text>;
          case 'sub':
            return <Text style={styles.sub}>{textContent}</Text>;
          case 'sup':
            return <Text style={styles.sup}>{textContent}</Text>;
          case 'br':
            return <Text>{'\n'}</Text>;
          case 'ul':
            return (
              <Text>
                {React.Children.map(textContent, (child, index) => (
                  <React.Fragment key={index}>
                    {child}
                    {index !== React.Children.count(textContent) - 1 && <Text>{'\n'}</Text>}
                  </React.Fragment>
                ))}
              </Text>
            );
          case 'li':
            return (
              <Text style={styles.listItem}>
                <Text style={styles.bulletPoint}>• </Text>
                <Text style={styles.listItemContent}>{textContent}</Text>
              </Text>
            );
          case 'a':
            return (
              <Link src={attribs.href} style={styles.link}>
                {textContent}
              </Link>
            );
          default:
            return <Text>{textContent}</Text>;
        }
      }
    }
  };

  return parse(htmlString, options);
};

// Стили для компонентов
const styles = StyleSheet.create({
  paragraph: {
    fontWeight: 300,
    color: '#191919',
    fontFamily: 'Open Sans',
    fontSize: pxToPt(12),
  },
  bold: {
    fontWeight: 700,
    fontFamily: 'Open Sans',
  },
  italic: {
    fontStyle: 'italic',
    fontFamily: 'Open Sans',
  },
  sub: {
    fontSize: pxToPt(12),
    verticalAlign: 'sub',
  },
  sup: {
    fontSize: pxToPt(12),
    verticalAlign: 'super',
  },
  listItem: {
    marginBottom: 5,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  bulletPoint: {
    width: 10,
    fontWeight: 300,
    fontFamily: 'Open Sans',
    fontSize: pxToPt(12),
  },
  listItemContent: {
    flex: 1,
    fontWeight: 300,
    fontFamily: 'Open Sans',
    fontSize: pxToPt(12),
  },
  link: {
    flex: 1,
    fontWeight: 300,
    color: '#191919',
    fontFamily: 'Open Sans',
    fontSize: pxToPt(12),
    textDecoration: 'underline',
  },
});
