import React, {useEffect, useState} from "react";

// Link
import {Link, useLocation, useNavigate} from "react-router-dom";

// Constants
import {categories} from 'constans/categories';
import {initialStateFormat} from 'constans/request-form';

// Type
import {CategoriesType} from '../type';

// Hooks
import {Scrolling} from "lib/hooks/scrolling";

// ClassNames
import clsx from "clsx";

// Lib
import Cookies from "js-cookie";
import {shallowEqual} from "react-redux";
import {calcCountItems} from "lib/helpers";
import {useAppDispatch, useAppSelector} from "lib/hooks/useDispatch";

// Components
import {Menu} from "../menu";
import Img from 'components/Img';
import BurgerMenu from '../burger';
import Avatar from "components/Avatar";
import AccountMenu from "../account-menu";
import Button from "components/ui-kit/button";

// Actions
import {openModal} from "reducers/modal";
import {getAddress} from "pages/checkout/reducer";
import {getAvatarUser} from "pages/account/reducer";
import {amplitudeInstance, events} from "constans/cookie";
import * as actionsCreatorProjects from "pages/projects/reducer";
import {checkAuth, toggleModal, updateLogout, checkAuthSuccess} from "reducers/settings";

export const Header: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();

	let timeoutMenuId: ReturnType<typeof setTimeout>;
	let timeoutAccountId: ReturnType<typeof setTimeout>;

	const {user, isCloseSmallDropdown: isClose} = useAppSelector(
		(store) => store.settings,
		shallowEqual
	);
	const avatar = useAppSelector(
		(store) => store.account.avatar,
		shallowEqual
	);
	const {currentProject, dataProjects: projects } = useAppSelector(
		(store) => store.projects,
		shallowEqual
	);

	const countItems = calcCountItems(projects, (+currentProject || 0));

	const [isMenuDropDownOpen, setMenuDropDownOpen] = useState<boolean>(false);
	const [isMenuDropDownOpenAccount, setMenuDropDownOpenAccount] = useState<boolean>(false);

	const isScrolling = Scrolling({limit: 20});

	useEffect(() => {
		if (user.authorized) {
			dispatch(getAddress());
			dispatch(getAvatarUser());
		}
	}, [user]);

	useEffect(() => {
		dispatch(checkAuth());

		window.addEventListener(
			'storage',
			handlerSynchronizationTabListener
		);

		return () => {
			clearTimeout(timeoutMenuId);
			clearTimeout(timeoutAccountId);

			dispatch(toggleModal(false));

			setMenuDropDownOpen(false);
			setMenuDropDownOpenAccount(false);
			window.removeEventListener(
				'storage',
				handlerSynchronizationTabListener
			);
		};
	}, []);

	useEffect(() => {
		return () => {
			clearTimeout(timeoutMenuId);
			clearTimeout(timeoutAccountId);

			dispatch(toggleModal(false));
			setMenuDropDownOpen(false);
			setMenuDropDownOpenAccount(false);
		};
	}, [location]);

	const handlerSynchronizationTabListener = (event: any) => {
		const parsedData = event.key === 'synchronizationTab'
			? JSON.parse(event.newValue)
			: { type: '' };

		const data = parsedData?.data;
		const type = parsedData?.type || '';

		if (type === "SIGN_OUT") {
			dispatch(updateLogout());
		}

		if(type === "UPDATE_SIGN_IN") {
			dispatch(checkAuthSuccess({ data }));
		}

		if (type === "UPDATE_CURRENT_PROJECT") {
			dispatch(actionsCreatorProjects.handlerChangeCurrentProject(data));
		}
	}

	const handlerClick = () => {
		if (window.location.pathname !== 'request-form') {
			localStorage.setItem('format', JSON.stringify(initialStateFormat));
		}

		navigate('/request-form');
	};

	const handlerClickRedirect = (url: string) => () => {
		dispatch(openModal({
			type: 'redirect',
			url: url,
		}));

		const isCookie = Cookies.get('cookieName');

		if (isCookie) {
			if (url === '/services') {
				amplitudeInstance.logEvent(events.services);
			}

			if (url === '/search') {
				amplitudeInstance.logEvent(events.search);
			}

			if (window.location.pathname === '/') {
				amplitudeInstance.logEvent(events.home_click);
			}
		}
	};

	const handlerToogleMenu = () => {
		dispatch(toggleModal(!isClose));
	};

	const onMouseEnter = () => {
		timeoutAccountId = setTimeout(() => {
			setMenuDropDownOpenAccount(true);
		}, 800);
	};

	const onMouseLeave = () => {
		setMenuDropDownOpenAccount(false);
		clearTimeout(timeoutAccountId);
	};

	const onMouseEnterItem = (isFirst: boolean) => () => {
		timeoutMenuId = setTimeout(() => {
			setMenuDropDownOpen(isFirst)
		}, 800);
	};

	const onMouseLeaveItem = () => {
		setMenuDropDownOpen(false);
		clearTimeout(timeoutMenuId);
	};

	const cnAuthWrapp = clsx(
		"main-wrapper-avatar pl30",
		isMenuDropDownOpenAccount ? 'show-account' : ''
	);

	const cnMainWrapp = clsx(
		'wrapper-header',
		isScrolling ? 'wrapper-header__bg' : ''
	);

	const cnHeaderWrapp = clsx(
		'header header__avatar',
		isClose ? 'header__avatar--active-login' : '',
		isMenuDropDownOpen ? 'header--is-active' : '',
	);

	const cnLogo = clsx(
		'logo',
		isScrolling ? 'logo--active' : ''
	);

	return (
		<div className={cnMainWrapp}>
			<header className={cnHeaderWrapp}>
				<div className='header__top flex flex-ai-center flex-jc-between'>
					<div className='flex flex-ai-center'>
						<div className='flex flex-ai-center'>
							<Link
								to='/'
								onMouseOver={() => setMenuDropDownOpen(false)}
							>
								<Img
									isSvg
									alt='Logo'
									name='logoMain'
									className={cnLogo}
								/>
							</Link>
							<nav>
								<ul className='header__ul flex flex-ai-center'>
									{categories
										.filter(x => x.isHeader)
										.map((x: CategoriesType, i: number) => {
												return (
													<li
														key={x.id}
														className='header__li'
														onMouseLeave={onMouseLeaveItem}
														onMouseEnter={onMouseEnterItem(i === 0)}
													>
														{x.isRedirect ?
															<span
																className='header__a'
																onClick={handlerClickRedirect(x?.link || '')}
															>
                           {x.title || ''}
                         </span>
															:
															<Link
																to={x.link || ''}
																className='header__a'
															>
																{x.title || ''}
															</Link>}

														{i === 0 && <Menu/>}
													</li>
												)
											}
										)}
								</ul>
							</nav>
						</div>

						<BurgerMenu isScrolling={isScrolling}/>
					</div>

					<div className='flex flex-ai-center'>
						<Button
							variable='info'
							type='button'
							label='Send a request'
							onPress={handlerClick}
						/>

						{user.authorized ?
							<div className="flex flex-ai-center">
								<Link to="/projects" className="header__pallet relative">
									<Img isSvg name="palletSvg" title="Go to My projects" className="header__pallet-svg"/>
									<div className="header__pallet-count flex flex-ai-center flex-jc-center">
										<p>{countItems}</p>
									</div>
								</Link>
								<div
									onMouseEnter={onMouseEnter}
									onMouseLeave={onMouseLeave}
									className={cnAuthWrapp}
								>
									<Avatar
										isShowName
										avatar={avatar}
										title={user?.title || ''}
										name={
											user?.firstName &&
											(`${user?.firstName[0]}.` || '')
										}
										lastName={user?.lastName || ''}
									/>
									<AccountMenu/>
								</div>
							</div>
							:
							<div className="pl30 main-wrapper-avatar">
								<Avatar
									isClose={isClose}
									title={user?.title || ''}
									onClick={handlerToogleMenu}
									name={user?.firstName || ''}
								/>
								<AccountMenu/>
							</div>
						}
					</div>
				</div>
			</header>
		</div>
	);
};
