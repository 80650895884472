import { createSlice } from '@reduxjs/toolkit';

type modalType = {
  type?: string,
  width?: number,
  height?: number,
  className?: string,
  isAddItem?: string,
};

type InitialStateType = {
  openModal?: boolean,
  modalProps?: modalType,
};

const initialState: InitialStateType = {
  modalProps: {
    type: '',
    width: 50,
    height: 50,
    className: '',
    isAddItem: '',
  },
  openModal: false,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState: initialState,
  reducers: {
    openModal: (state, action) => {
      state.openModal = true;
      state.modalProps = action?.payload;
    },
    modalClose: (state) => {
      state.openModal = false;
    },
  },
});

export const {
  openModal,
  modalClose,
} = modalSlice.actions;

export default modalSlice.reducer;
