import React from 'react';

// Lib
import {useAppSelector} from "lib/hooks/useDispatch";

// Classname
import clsx from 'clsx';

// Components
import Preloader from 'components/ui-kit/loader';

export const Container = () => {
	const {showLoader, isTransparent} = useAppSelector((store) => store.loader);

	const cn = clsx('smart-loader', showLoader ? 'smart-loader--active' : '', isTransparent ? 'smart-loader__transparent' : '');

	return (
		<div className={cn}>
			<Preloader type="global"/>
		</div>
	);
};

export default Container;
