import React from 'react';

// Clsx
import clsx from 'clsx';

// Lib
import {Link} from "react-router-dom";

// Config
import config from "lib/config";

// Components
import Img from "components/Img";
import Button from 'components/ui-kit/button';

const LimitExceeded: React.FC<any> = (props) => {
	const {modalProps, handlerClose} = props;
	const {
		innerClass,
	} = modalProps;

	const handlerClick = () => {
		handlerClose();
	};

	const handlerRedirect = () => {
		const link = document.createElement('a');

		link.href = `${window.location.origin}/contact-us`;
		link.click();
		link.remove();
	};

	const cn = clsx(
		'limit-exceeded flex flex-direction-column flex-ai-center flex-jc-center',
		innerClass ? innerClass : '',
	);

	return (
		<div className={cn}>
			<Img isSvg name="limit" width={56} height={56} />
			<h4 className="limit-exceeded__title mb10 mt30">
        Limit exceeded
      </h4>

			<p className="limit-exceeded__text text-align-cn mb10">
				Please note you can perform a search of compounds only for 60 targets<br/> simultaneously. To get information for a broader variety of targets <br/> <Link
				to="/contact-us">contact us</Link>.
			</p>
			<div className="limit-exceeded__wrapper-btn flex flex-jc-center">
				<Button
					variable="info"
					className="mr10"
					label="Contact Us"
					onPress={handlerRedirect}
				/>
				<Button
					color="green"
					variable="primary"
					label="Ok, understood"
					onPress={handlerClick}
				/>
			</div>
		</div>
	);
};

export default LimitExceeded;
