import React from "react";

import {useAppDispatch} from "lib/hooks/useDispatch";

// Redirect
import {Link} from "react-router-dom";

// Constants
import {categories} from 'constans/categories';

// Actions
import * as actionsCreator from 'reducers/modal';

// Lib
import Cookies from "js-cookie";
import {amplitudeInstance, events} from "constans/cookie";

// Components
import Img from "components/Img";

// Type
import {CategoriesType} from '../type';

export const Footer: React.FC = () => {
	const dispatch = useAppDispatch();

	const handlerClick = (url: string) => () => {
		dispatch(actionsCreator.openModal({
			type: 'redirect',
			url: url,
		}));

		const isCookie = Cookies.get('cookieName');

		if (isCookie) {
			if (url === 'contact-us') {
				amplitudeInstance.logEvent(events.contact_us);
			}

			if (url === 'privacy-policy') {
				amplitudeInstance.logEvent(events.privacy_policy);
			}

			if (url === 'terms-of-service') {
				amplitudeInstance.logEvent(events.terms_of_service);
			}

			if (url === 'https://enamine.net/publications') {
				amplitudeInstance.logEvent(events.publications);
			}

			if (window.location.pathname === '/') {
				amplitudeInstance.logEvent(events.home_click);
			}
		}
	};

	const handlerOnPress = () => {
		const link = document.createElement('a');

		link.href = 'https://enamine.net';
		link.target = '_blank';
		link.click();
	};

	return (
		<div className="footer">
			<div className='footer__wrapper-logo-mobile flex flex-ai-center flex-jc-center'>
				<Link to='/'>
					<Img
						alt='Logo'
						className='logo'
						name='logoFooter'
					/>
				</Link>
				<div onClick={handlerOnPress} className='header__border header__border--active'>
					<Img
						className='logo-store'
						alt='Enamine store logo'
						name='logoEnamineStoreFooter'
					/>
				</div>
			</div>
			<div className='footer__top'>
				<div className='wrapper flex flex-ai-center'>
					<div className='flex flex-ai-start'>
						<Link to='/' className='footer__wrapper-logo'>
							<Img
								alt='Logo'
								className='logo'
								name='logoFooter'
							/>
						</Link>
					</div>
					<nav>
						<ul className='header__ul flex flex-ai-center'>
							{categories.filter(x => !x.isHeader).map((x: CategoriesType) => {
								return x?.isRedirect ?
									<li className='header__li' key={x.id}>
                  <span className='header__a' onClick={handlerClick(x?.link || '')}>
                    {x.title || ''}
                  </span>
									</li>
									:
									<li className='header__li' key={x.id}>
										<Link to={x.link || ''} className='header__a'>
											{x.title || ''}
										</Link>
									</li>
							})}
						</ul>
					</nav>
				</div>
			</div>
			<div className="footer__copyright flex-ai-center flex-jc-center">
				<div className='flex-direction-column flex-jc-center'>
					<div onClick={handlerOnPress} className='flex flex-jc-center mobile-logo-dn'>
						<Img
							className='logo-store'
							alt='Enamine store logo'
							name='logoEnamineStoreFooter'
						/>
					</div>
					<p>© {new Date().getFullYear()} Copyright Enamine</p>
				</div>
			</div>
		</div>
	);
};
