import React from "react";

// Components
import Img from 'components/Img';
import Loader from 'components/ui-kit/loader';

// Link
import {Link} from "react-router-dom";

// Interface
interface IButton {
	icon?: any,
	id?: string,
	onPress?: any,
	label?: string,
	color?: string,
	accept?: string,
	isSvg?: boolean,
	className?: string,
	disabled?: boolean,
	variable?: string,
	loading?: boolean,
	title?: string,
	typePadding?: string,
	href?: string,
	arrowLeft?: string,
	ariaLabel?: string,
	ref?: React.RefObject<HTMLInputElement>,
	type?: "button" | "submit" | "reset" | "file",
	children?: React.ReactNode | (() => React.ReactNode),
}

const Button: React.FC<IButton> = (props) => {
	const {
		id,
		ref,
		icon,
		type,
		href,
		label,
		isSvg,
		color,
		title,
		accept,
		onPress,
		variable,
		arrowLeft,
		ariaLabel,
		children,
		className,
		typePadding,
		loading = false,
		disabled = false,
	} = props;
	let classes = className || "";

	classes += " btn-" + variable;
	classes += ` ${color ? `btn-${color}` : ''}`;
	classes += ` ${typePadding ? `btn-${typePadding || ''}-p` : ''} ${href ? 'btn-href' : ''} ${
		icon === 'editIcon' ? 'btn-edit-pen' : ''
	}`;

	if (loading) classes += " btn-loading";
	if (disabled) classes += " btn-disabled";

	const getIcon = () =>
		icon ? <Img
			alt={icon}
			name={icon}
			isSvg={isSvg}
		/> : null;

	const getContent = () =>
		<>
			{loading && <Loader type="circle"/>}
			{label || (typeof children === 'function' ? children() : children)}
		</>

	const onClick = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!disabled) {
			return onPress ? onPress(type === 'file' ? e : null) : () => {
			};
		} else {
			return () => {
			};
		}
	};

	if (href) {
		return (
			<Link
				to={href}
				title={title}
				className={`${classes}`}
			>
				{getIcon()} {getContent()}
			</Link>
		);
	} else {
		if (type === 'file') {
			return (
				<label htmlFor={id} className={`${classes} ${arrowLeft}`}>
					<input
						id={id}
						ref={ref}
						type="file"
						accept={accept}
						onChange={onClick}
						disabled={disabled}
						style={{display: 'none'}}
					/>
					{icon && !loading ? (
						<div
							title={title || ''}
							className="btn-ico-holder d-flex align-items-center justify-content-center"
						>
							{getIcon()} {getContent()}{' '}
						</div>
					) : (
						getContent()
					)}
				</label>
			);
		} else {
			return (
				<button
					id={id}
					title={title}
					type={type}
					aria-label={ariaLabel}
					className={`${classes} ${arrowLeft}`}
					onClick={disabled ? () => {
					} : onPress ? () => onPress() : () => {
					}}
				>
					{icon && !loading ? (
						<div className="btn-ico-holder flex flex-jc-center flex-ai-center">
							{getIcon()} {getContent()}{" "}
						</div>
					) : (
						getContent()
					)}
				</button>
			);
		}
	}
};

export default Button;
