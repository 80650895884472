import React, {useState} from "react";

// Components
import Button from 'components/ui-kit/button';
import FieldRadio from 'components/ui-kit/field-radio';

// Lib
import * as yup from "yup";
import Cookies from "js-cookie";
import {useForm} from "react-hook-form";
import {shallowEqual} from "react-redux";
import {yupResolver} from '@hookform/resolvers/yup';
import {convertProjectsToOptions} from "lib/helpers";
import {amplitudeInstance, events} from "constans/cookie";
import {useAppDispatch, useAppSelector} from "lib/hooks/useDispatch";

// Actions
import {modalClose, openModal} from "reducers/modal";
import * as actionsCreator from "pages/projects/reducer";
import {handlerChangeCurrentProject} from "pages/projects/reducer";
import * as actionCreators from "pages/libraries-compounds/reducer";

// Type
import {NavigateFunction} from "react-router-dom";
type UnitType = {
  url?: string,
  navigate?: NavigateFunction
  data?: any;
  text?: string,
  isAddItem?: string,
  handlerCloseModal?: () => {},
  isLibrary?: boolean | undefined,
};

// Interface
interface IProjectsList {
  modalProps?: UnitType;
}

// Schema
const schema = yup.object({
  projectList: yup.string().nullable().required('Selecting the variant radio button is required'),
}).required();
type FormData = yup.InferType<typeof schema>;

const ProjectsList: React.FC<IProjectsList> = (props) => {
  const dispatch = useAppDispatch();

  const keyCurrentProject = 'currentProject';
  const currentProjectStore = localStorage.getItem(keyCurrentProject) || '';

  const url = props?.modalProps?.url;
  const text = props?.modalProps?.text || '';
  const result = props?.modalProps?.data || [];
  const isAddItem = props?.modalProps?.isAddItem || '';
  const isLibrary = props?.modalProps?.isLibrary || false;

  const dataProjects = useAppSelector((store) => store.projects.dataProjects, shallowEqual);
  const loading = useAppSelector((store) => store.projects.loadingAddItemToProject, shallowEqual);

  const filteredOption = dataProjects?.filter(
    x => x?.id === +currentProjectStore
  );

  const currentOption = convertProjectsToOptions(filteredOption) || [];

  const [currentProject, setCurrentProject] = useState(currentOption[0]);

  const defaultValues = {
    projectList: currentProject?.value+'',
  };

  const {register, handleSubmit, formState: { errors }} = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues
  });

  const onSubmit = () => {
    const uniqId = crypto.randomUUID();
    dispatch(handlerChangeCurrentProject(currentProject+''));

    if(isLibrary) {
      dispatch(actionCreators.addAllLibrariesToProject({
        ...result,
        uniqId: uniqId,
        productId: currentProject,
      }));
    }

    if(!isLibrary) {
      const isText = !!text.length ? {text: text} : {};
      dispatch(actionsCreator.addItemToProject({
        ...isText,
        data: result,
        uniqId: uniqId,
        id: currentProject,
      }));
    }

    const isCookie = Cookies.get('cookieName');
    if (isCookie) {
      amplitudeInstance.logEvent(events.add_molecule_to_project, {id: ''});
    }
  };

  const handlerChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = +e.target?.value;
    setCurrentProject(id);
  };

  const handlerCancel = () => {
    dispatch(modalClose());
  };

  const handlerAddNewProject = () => {
    const isText = !!text.length ? {text: text} : {};
    dispatch(openModal({
      ...isText,
      width: 57,
      height: 47,
      data: result,
      type: 'projectAdd',
      isAddItem: isAddItem,
    }));
  };

  const newData = convertProjectsToOptions(dataProjects);

  return <div className='projects-modal flex flex-direction-column'>
    <div className="mb30 flex flex-ai-center flex-jc-between">
      <h4 className="projects-modal__title">Add to the project</h4>
      <Button variable="outline" onPress={handlerAddNewProject} label="Add new project +"/>
    </div>

    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="projects-modal__list mb30">
        <FieldRadio
          isColumn
          className="mb10"
          options={newData}
          name="projectList"
          classNameWrapper="mb10"
          onChange={handlerChangeRadio}
          isError={!!errors?.projectList}
          register={register("projectList", {required: false})}
          msgError={errors?.projectList ? errors?.projectList?.message : ''}
        />
      </div>
      <div className='flex flex-ai-center flex-jc-center'>
        <Button
          color='error'
          variable='outline'
          label='Cancel'
          className="mr30"
          onPress={handlerCancel}
        />
        <Button
          label='Save'
          variable='outline'
          loading={loading}
          onPress={onSubmit}
          disabled={!currentProject}
        />
      </div>
    </form>
  </div>;
};

export default ProjectsList;
