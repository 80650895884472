import {
  ApisauceInstance
} from 'apisauce';

// Url
import { url } from '../api.url';

type ResetPasswordType = {
  token?: string,
  password?: string,
};

type ResetPasswordRequestType = {
  email?: string,
};

const resetPassword = (api: ApisauceInstance) => {
  const resetPassword = (formData: ResetPasswordType) => api.post(url.resetPassword, formData);
  const resetPasswordRequest = (formData: ResetPasswordRequestType) => api.post(url.resetPasswordRequest, formData);

  return {
    resetPassword,
    resetPasswordRequest
  };
};

export default resetPassword;
