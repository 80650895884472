import {fork, put, take} from "redux-saga/effects";

// Actions
import * as actionsCreator from '../reducer';

// Type
import {ActionType, ActionTypeDelete} from "../type";

// Sagas
import {bioSearchSagaStart} from "./saga-bio-search";
import {searchTextSagaStart} from "./saga-search-text";
import {structureSearchSagaStart} from "./saga-structure-search";

// Start Sagas
export function* searchSagaStart(api: any, action: any) {
	yield fork(historyListWatcher, api, action);
	yield fork(bioSearchSagaStart, api, action);
	yield fork(searchTextSagaStart, api, action);
	yield fork(structureSearchSagaStart, api, action);
	yield fork(structureSearchDeleteItemWatcher, api, action);
}

/**
 * Structure list delete item Search Watcher
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* structureSearchDeleteItemWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionTypeDelete = yield take(
				actionsCreator.deleteItemStructureHistory.type
			);

			const listStructureSearch = JSON.parse(
				localStorage.getItem('structureList') || '[]'
			);
			let result = listStructureSearch.filter(
				(item: string) => item !== actions?.payload
			);

			localStorage.setItem('structureList', JSON.stringify(result));
			yield put(actionsCreator.updateHistoryStructureSearch(result));
		} catch (e) {
			console.log('CATCH ERROR in structureSearchDeleteItemWatcher: ', e);
		}
	}
}

/**
 * History list Watcher
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* historyListWatcher(api: any, action: any): Generator<any, void, any> {
	while (true) {
		try {
			const actions: ActionType = yield take(
				actionsCreator.historyList.type
			);

			let result = [];
			const maxLngtItem = 9;
			const payload = actions?.payload?.data || '';
			const localStoreKey = actions?.payload?.localStoreKey || '';

			if (payload) {
				const listStructureSearch = JSON.parse(localStorage.getItem(localStoreKey) || '[]');

				if (
					listStructureSearch &&
					listStructureSearch.length
				) {
					const found = listStructureSearch.some((item: any) => item === payload);

					if (found) {
						listStructureSearch.filter((item: any) => item !== payload);
						result = listStructureSearch;
					} else {
						if (listStructureSearch.length >= maxLngtItem) {
							listStructureSearch.pop();
							result = [payload, ...listStructureSearch];
						} else {
							result = [payload, ...listStructureSearch];
						}
					}

					localStorage.setItem(localStoreKey, JSON.stringify(result));
					yield put(actionsCreator.historyList({
						data: result,
						storeName: actions?.payload?.storeName,
						localStoreKey: actions?.payload?.localStoreKey,
					}));
				} else {
					yield put(actionsCreator.historyList({
						data: [payload],
						storeName: actions?.payload?.storeName,
						localStoreKey: actions?.payload?.localStoreKey,
					}));
					localStorage.setItem(localStoreKey, JSON.stringify([payload]));
				}
			}
		} catch (e) {
			console.log('CATCH ERROR historyListWatcher: ', e);
		}
	}
}
