import { createSlice } from "@reduxjs/toolkit";

// Type
interface EnvInterface {
  message?: string;
  loading?: boolean;
  status?: number | null,
};

const initialState: EnvInterface = {
  status: null,
  message: '',
  loading: false,
};

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    sendNewPassword: (state, action) => {
      state.loading = true;
    },
    sendNewPasswordError: (state, action) => {
      state.loading = false;
      state.message = action.payload.data;
      state.status = action.payload.status;
    },
    sendNewPasswordSuccess: (state) => {
      state.loading = false;
    },
  },
});

export const {
  sendNewPassword,
  sendNewPasswordError,
  sendNewPasswordSuccess,
} = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
