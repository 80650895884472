import React, { memo } from "react";

// Components
import Img from "components/Img";

export const EmptyList: React.FC = memo(() => {
  return (
    <div className='wrapper-menu'>
      <nav className='menu flex wrapper flex-direction-column'>
        <div className="flex flex-jc-between flex-ai-start">
          <div className='menu__col flex flex-ai-start flex-direction-column'>
            <Img
              name='iconMenu1'
              alt='Icon image 1'
              className='menu__img'
            />
            <h4 className='menu__title'>Signaling Pathways and Protein Classes Related Libraries</h4>
          </div>
          <div className='menu__col flex flex-ai-start flex-direction-column'>
            <Img
              name='iconMenu2'
              alt='Icon image 2'
              className='menu__img'
            />
            <h4 className='menu__title'>Bioactive Screening Compound Libraries</h4>
          </div>
          <div className='menu__col flex flex-ai-start flex-direction-column'>
            <Img
              name='iconMenu3'
              alt='Icon image 3'
              className='menu__img'
            />
            <h4 className='menu__title'>Drug Repurposing and Related Libraries</h4>
          </div>
        </div>
        <div className='menu__list-item menu__list-item--disabled-hover'>Something went wrong. We're working on it and we'll get it fixed as soon as we can.</div>
      </nav>
    </div>
  );
});
