import React from "react";

// Components
import Button from 'components/ui-kit/button';
import TextField from 'components/ui-kit/field-text';

// Lib
import * as yup from "yup";
import Cookies from "js-cookie";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {amplitudeInstance, events} from "constans/cookie";
import {convertedToArray, patternRxpCheckEng} from "lib/helpers";
import {useAppDispatch, useAppSelector} from "lib/hooks/useDispatch";

// Actions
import {modalClose} from "reducers/modal";
import {updateProject} from 'pages/projects/reducer';

// Type
import {PropsProject} from 'pages/projects/type';

// Interface
interface IProjectsAdd {
	modalProps?: UnitType,
	handlerClose?: () => {},
}

// Type
type UnitType = {
	id?: number,
}

// Schema
const schema = yup.object({
	name: yup.string().matches(patternRxpCheckEng, "Use Latin symbols only.").max(100, 'The text must be less than 100 symbols').required('The text must be less than 100 symbols'),
	description: yup.string().matches(patternRxpCheckEng, "Use Latin symbols only.").max(200, 'The text must be less than 200 symbols').required('The text must be less than 200 symbols'),
}).required();
type FormData = yup.InferType<typeof schema>;

const ProjectsAdd: React.FC<IProjectsAdd> = ({modalProps}) => {
	const dispatch = useAppDispatch();

	const data = useAppSelector((store) => store.projects.dataProjects);
	const loading = useAppSelector((store) => store.projects.loadingUpdateProject);

	const id = modalProps?.id ? +modalProps?.id : 0;
	const convertProjects = convertedToArray(data);

	const currentProject = convertProjects?.length
		? convertProjects?.find((x: PropsProject) => x.id === id)
		: {
			name: '',
			description: '',
		};

	const defaultValues = {
		name: currentProject?.name || '',
		description: currentProject?.description || '',
	}

	const {register, handleSubmit, formState: {errors, isDirty, isValid}} = useForm<FormData>({
		resolver: yupResolver(schema),
		defaultValues
	});

	const handlerCancel = () => {
		dispatch(modalClose());
	};

	const onSubmit = (data: FormData) => {
		dispatch(updateProject({
			...data,
			id: modalProps?.id,
		}));
		const isCookie = Cookies.get('cookieName');
		if (isCookie) {
			amplitudeInstance.logEvent(events.create_project, {sent_form: true});
		}
	};

	return <div className='projects-modal'>
		<div className="projects-modal__create flex flex-direction-column">
			<h4 className="projects-modal__title mb30">
				Update project
			</h4>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="projects-modal__form flex flex-ai-center flex-jc-between flex-direction-column"
			>
				<div className="full-width">
					<TextField
						required
						name='name'
						isError={!!errors?.name}
						placeholder='Name project'
						className="full-width mb10"
						label='Update a project name'
						register={register('name')}
						msgError={errors?.name ? errors?.name.message : ''}
					/>
					<TextField
						required
						name='description'
						className="full-width"
						isError={!!errors?.description}
						placeholder='Description project'
						label='Update a project description'
						register={register('description')}
						msgError={errors?.description ? errors?.description.message : ''}
					/>
				</div>
				<div className='flex flex-jc-center'>
					<Button
						variable='outline'
						label='Cancel'
						className='mr10'
						type='submit'
						onPress={handlerCancel}
					/>
					<Button
						variable='outline'
						label='Update'
						loading={loading}
						type='submit'
						disabled={!isValid && !isDirty}
					/>
				</div>
			</form>
		</div>
	</div>;
};

export default ProjectsAdd;
