import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	time: 'stop',
};

export const timerSlice = createSlice({
	name: 'timer',
	initialState: initialState,
	reducers: {
		getTimeSuccess: (state, action) => {
			const data = action.payload.data;
			const key = action.payload.key || 'time';

			return {
				...state,
				[key]: data,
			};
		},
	},
});

export const { getTimeSuccess } = timerSlice.actions;

export default timerSlice.reducer;
