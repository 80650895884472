import {createSlice} from "@reduxjs/toolkit";

// Interface
interface IInitialState {
	avatar: string,
	loadingAvatar?: boolean;
	loadingProfile?: boolean;
	loadingUpdateAvatar: boolean;
	loadingDeleteAvatar?: boolean;
}

const initialState: IInitialState = {
	avatar: '',
	loadingAvatar: false,
	loadingProfile: false,
	loadingDeleteAvatar: false,
	loadingUpdateAvatar: false,
};

export const accountSlice = createSlice({
	name: "account",
	initialState,
	reducers: {
		updateProfile: (state, action) => {
			state.loadingProfile = true;
		},
		updateProfileError: (state) => {
			state.loadingProfile = false;
		},
		updateProfileSuccess: (state) => {
			state.loadingProfile = false;
		},

		getAvatarUser: (state) => {
			state.loadingAvatar = true;
		},
		getAvatarUserError: (state) => {
			state.loadingAvatar = false;
		},
		getAvatarUserSuccess: (state, action) => {
			state.loadingAvatar = false;
			state.avatar = action.payload;
		},

		updateAvatarUser: (state, action) => {
			state.loadingUpdateAvatar = true;
		},
		updateAvatarUserError: (state) => {
			state.loadingUpdateAvatar = false;
		},
		updateAvatarUserSuccess: (state, action) => {
			state.loadingUpdateAvatar = false;
			state.avatar = action.payload;
		},

		deleteAvatarUser: (state) => {
			state.loadingDeleteAvatar = true;
		},
		deleteAvatarUserError: (state) => {
			state.loadingDeleteAvatar = false;
		},
		deleteAvatarUserSuccess: (state) => {
			state.loadingDeleteAvatar = false;
			state.avatar = '';
		},
	},
});

export const {
	updateProfile,
	getAvatarUser,
	updateAvatarUser,
	deleteAvatarUser,
	updateProfileError,
	getAvatarUserError,
	updateProfileSuccess,
	deleteAvatarUserError,
	getAvatarUserSuccess,
	updateAvatarUserError,
	deleteAvatarUserSuccess,
	updateAvatarUserSuccess,
} = accountSlice.actions;

export default accountSlice.reducer
