import React from "react";
import ReactDOM from "react-dom/client";

// Redux
import {Provider} from "react-redux";

// Store
import Store from "./store";

import RootComponent from "./root";
import reportWebVitals from "./reportWebVitals";

// React-pdf connect fonts
import {Font} from "@react-pdf/renderer";

// Fonts in base64
import {
	OpenSansBold,
	OpenSansBoldItalic,
	OpenSansLight,
	OpenSansLightItalic,
	OpenSansMedium,
	OpenSansMediumItalic,
	OpenSansRegular,
} from 'constans/fonts-base64';

// Register fonts react-pdf/renderer
Font.register({
	family: 'Open Sans',
	fonts: [
		{
			src: `data:font/ttf;base64,${OpenSansLight}`,
			fontWeight: 300,
		},
		{
			src: `data:font/ttf;base64,${OpenSansLightItalic}`,
			fontWeight: 300,
			fontStyle: 'italic',
		},
		{
			src: `data:font/ttf;base64,${OpenSansRegular}`,
			fontWeight: 400,
		},
		{
			src: `data:font/ttf;base64,${OpenSansMedium}`,
			fontWeight: 500,
		},
		{
			src: `data:font/ttf;base64,${OpenSansMediumItalic}`,
			fontWeight: 500,
			fontStyle: 'italic',
		},
		{
			src: `data:font/ttf;base64,${OpenSansBold}`,
			fontWeight: 700,
		},
		{
			src: `data:font/ttf;base64,${OpenSansBoldItalic}`,
			fontWeight: 700,
			fontStyle: 'italic',
		}
	],
});

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

Store.dispatch({type: "STARTUP"});

root.render(
	<React.Fragment>
		<Provider store={Store}>
			<RootComponent/>
		</Provider>
	</React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
