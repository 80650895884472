import {call, fork, put, select, take, takeLatest} from "redux-saga/effects";

// Actions
import {modalClose} from "reducers/modal";
import * as actionsCreator from "./reducer";
import {showMessage} from "reducers/messages";
import {handlerChangeCurrentProject} from "pages/projects/reducer";
import * as actionCreators from "pages/libraries-compounds/reducer";

// Lib
import {RootState} from "store";
import {convertedToArray} from "lib/helpers";

// Type
import {PropsItem, PropsProject} from './type';
import {NavigateFunction} from "react-router-dom";

// Constants
import {configAddress} from "constans/checkout";

type PropsAddresses = {
	[index: string]: string | number | boolean;
}
export type ApiResponse<Data extends unknown> = {
	data?: Data;
	ok?: boolean;
	status?: number;
};
type PayloadType = {
	result?: [],
	text?: string,
	name?: string,
	isAddItem?: string,
	description?: string,
	navigate?: NavigateFunction,
	url?: string,
	isLibrary?: boolean | undefined,
};
type PayloadTypeUpdateProject = {
	id?: number,
	name?: string,
	description?: string
};
type PayloadDeleteItemProject = {
	itemId?: number,
	projectId?: number,
}
type ActionsTypeCreateProject = {
	type?: string,
	payload?: PayloadType,
};
type DataTypeAddItem = {
	quantity?: number,
	productId?: string,
	prepackage?: number,
}
type PayloadTypeAddItem = {
	id?: number,
	text?: string,
	title?: string,
	uniqId?: string,
	position?: string,
	url?: string,
	navigate?: NavigateFunction
	data?: DataTypeAddItem[],
};
type ActionsTypeAddItem = {
	type?: string,
	payload?: PayloadTypeAddItem,
};
type ActionsTypeUpdateProject = {
	type?: string,
	payload?: PayloadTypeUpdateProject,
};
type ActionsTypeDeleteItemProject = {
	type?: string,
	payload?: PayloadDeleteItemProject,
}
type ActionsTypeMoveItemProject = {
	type?: string,
	payload?: PayloadMoveToProjectId,
}
type PayloadMoveToProjectId = {
	itemId?: number,
	moveToProjectId?: number,
}
type PayloadUpdateItemProject = {
	id?: number,
	quantity?: number,
	projectId?: number,
	selected?: boolean,
};
type ActionsTypeSelectedItemProject = {
	type?: string,
	payload?: PayloadUpdateItemProject,
};
type ActionsPostToCheckout = {
	type: string,
	payload: PayloadTypePostCheckout,
};
type PayloadTypePostCheckout = {
	projectId?: string,
	navigation?: NavigateFunction,
};

// Selector store
function* _select<T>(fn: (state: RootState) => T) {
	const res: T = yield select(fn);
	return res;
}

// Store
export const auth = (store: RootState) => store.settings;
export const projects = (store: RootState) => store.projects;
export const checkout = (store: RootState) => store.checkout;

/**
 *  Progects sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* projectsSagaStart(api: any, action: any) {
	yield fork(orderCopyWatcher, api, action);
	yield fork(updateCountProjectWatcher, api);
	// @ts-ignore
	yield fork(getProjectsWatcher, api, action);
	yield fork(postProjectWatcher, api, action);
	yield fork(createProjectWatcher, api, action);
	yield fork(updateProjectWatcher, api, action);
	yield fork(deleteProjectWatcher, api, action);
	yield fork(addItemToProjectWatcher, api, action);
	yield fork(deleteItemProjectWatcher, api, action);
	yield fork(moveItemToProjectWatcher, api, action);
	yield fork(updateSelectItemInProjectWatcher, api, action);
}

/**
 *  Start watcher sagas update count project
 *
 * @param {function} api - api object
 * @returns
 */
export function* updateCountProjectWatcher(api: any)  {
	yield takeLatest(
		actionsCreator.handlerCount.type,
		updateCountProjectWorker,
		api
	);
}

/**
 *  Start watcher sagas get progects project
 *
 * @param {function} api - api object
 * @returns
 */
export function* getProjectsWatcher(api: any): Generator<any, void, any> {
	yield takeLatest(
		actionsCreator.getListProjects.type,
		getProjectsWorker,
		api
	);
}

/**
 *  Get list projects watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getProjectsWorker(api: any, action: any): Generator<any, void, any> {
	try {
		const state = yield* _select(auth);
		const authorized = state.user.authorized;

		if (authorized) {
			const res: ApiResponse<any> = yield call(api.request, "getAllProjects");

			if (!res.ok) {
				yield put(actionsCreator.getListProjectsError());
			} else {
				res.data.forEach((project: PropsProject) => {
					project?.items?.length && project?.items.forEach((element: PropsItem) => {
						const elemQuan = element?.quantity ? element.quantity : 0;
						const elemTotalPrice = element?.totalPrice ? element?.totalPrice : 0;
						element.basePrice = elemTotalPrice / elemQuan;
					})
				});
				yield put(actionsCreator.getListProjectsSuccess(res.data));
				localStorage.setItem('projects', JSON.stringify(res.data));
			}
		} else {
			yield put(actionsCreator.getListProjectsError());
		}
	} catch (e) {
		console.log("CATCH ERROR in getProjectsWatcher: ", e);
	}
}

/**
 *  Create project watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* createProjectWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionsTypeCreateProject = yield take(actionsCreator.createProject.type);

			const url = actions?.payload?.url || '';
			const navigate = actions?.payload?.navigate;

			const result = actions?.payload?.result;
			const name = actions?.payload?.name || '';
			const text = actions?.payload?.text || '';
			const isLibrary = actions?.payload?.isLibrary || false;
			const description = actions?.payload?.description || '';

			const formData = {
				name: name,
				description: description,
			};

			const res: ApiResponse<any> = yield call(api.request, "createProject", formData);

			if (!res.ok) {
				yield put(actionsCreator.createProjectError());
			} else {
				yield put(handlerChangeCurrentProject(res.data));

				if (actions.payload?.isAddItem) {
					if (isLibrary) {
						const newData = {
							...result,
							productId: res.data,
						};
						yield put(actionCreators.addAllLibrariesToProject(newData));
					}

					if (!isLibrary) {
						const isText = !!text.length ? {text: text} : {};
						yield put(actionsCreator.addItemToProject({
							...isText,
							id: res.data,
							data: result,
							text: text,

							url: url,
							navigate: navigate,
						}));
					}
				}

				if (!actions.payload?.isAddItem) {
					yield put(actionsCreator.getListProjects(''));
				}
				yield put(actionsCreator.createProjectSuccess());
				yield put(modalClose());

				yield put(showMessage({
					type: 'info',
					text: 'Project is created',
				}));

				if(!!navigate) {
					navigate(url);
				}
			}
		} catch (e) {
			console.log("CATCH ERROR in createProjectWatcher: ", e);
		}
	}
}

/**
 *  Add item to the project watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* addItemToProjectWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionsTypeAddItem = yield take(
				actionsCreator.addItemToProject.type
			);

			const url = actions?.payload?.url || '';
			const navigate = actions?.payload?.navigate;

			const id = actions?.payload?.id;
			const uniqId = actions?.payload?.uniqId;
			const data = actions?.payload?.data || [];
			const text = actions?.payload?.text;
			const title = actions?.payload?.title;

			const formData = data?.map((item: any) => {
				return {
					purity: 90,
					productType: "EBC",
					quantity: item.quantity,
					productId: item.id || item.productId,
					prepackage: item?.prepackage || item?.currentPrice?.mg || item?.currentPrice?.value,
				}
			});

			const res: ApiResponse<null> = yield call(api.request, "addItemToProject", formData, id);

			if (!res.ok) {
				yield put(actionsCreator.addItemToProjectError());
			} else {
				const messageConfig = {
					text: text,
					title: title,
					type: 'info',
					uniqId: uniqId,
				}
				yield put(actionsCreator.getListProjects(''));
				yield put(actionsCreator.addItemToProjectSuccess());
				yield put(showMessage(messageConfig));
				yield put(modalClose());

				if(!!navigate) {
					navigate(url)
				}
			}
		} catch (e) {
			console.log("CATCH ERROR in addItemToProjectWatcher: ", e);
		}
	}
}

/**
 *  Update project watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* updateProjectWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionsTypeUpdateProject = yield take(actionsCreator.updateProject.type);

			const state = yield* _select(projects);
			const id = actions?.payload?.id;
			const name = actions?.payload?.name || '';
			const description = actions?.payload?.description || ''

			const formData = {
				name: name,
				description: description,
			};

			const res: ApiResponse<any> = yield call(api.request, 'updateProject', formData, id);

			if (!res.ok) {
				yield put(actionsCreator.updateProjectError());
			} else {
				const data = state?.dataProjects || [];

				const result = data.map((x: PropsProject) => {
					if (x.id === id) {
						return {
							...x,
							name: name,
							description: description
						}
					}
					return x;
				});

				yield put(actionsCreator.updateProjectSuccess(result));
				yield put(modalClose());

				yield put(showMessage({
					type: 'info',
					text: 'Project is updated',
				}))
			}
		} catch (e) {
			console.log("CATCH ERROR in updateProjectWatcher: ", e);
		}
	}
}

/**
 *  Delete project watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* deleteProjectWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionsTypeUpdateProject = yield take(actionsCreator.deleteProject.type);

			const state = yield* _select(projects);
			const id = actions?.payload;
			const keyCurrentId = 'currentProject';
			const currentId = localStorage.getItem(keyCurrentId);

			const res: ApiResponse<any> = yield call(api.request, 'deleteProject', id);

			if (!res.ok) {
				yield put(actionsCreator.deleteProjectError());
			} else {
				const data = state?.dataProjects || [];

				const convertedProjects = convertedToArray(data);

				const result = convertedProjects.filter(
					(project: PropsProject) => project.id !== id
				);

				if (id === (currentId ? +currentId : 0)) {
					yield put(actionsCreator.handlerChangeCurrentProject(''));
				}

				yield put(actionsCreator.deleteProjectSuccess(result));
				localStorage.setItem('projects', JSON.stringify(result));
			}
		} catch (e) {
			console.log("CATCH ERROR in deleteProjectWatcher: ", e);
		}
	}
}

/**
 *  Delete item project watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* deleteItemProjectWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionsTypeDeleteItemProject = yield take(actionsCreator.deleteItemProject.type);

			const state = yield* _select(projects);

			const itemId = actions.payload?.itemId;
			const projectId = actions.payload?.projectId;

			const res: ApiResponse<any> = yield call(api.request, 'deleteItemProject', itemId, projectId);

			if (!res.ok) {
				yield put(actionsCreator.deleteItemProjectError());
			} else {
				const data = state?.dataProjects || [];
				const convertedProjects = convertedToArray(data);
				const result = convertedProjects.map((project: PropsProject) => {
					if (project.id === projectId) {
						return {
							...project,
							items: project?.items?.length ?
								project.items.filter(
									(item: PropsItem) => item.id !== itemId
								)
								: [],
						}
					}
					return project;
				});

				yield put(actionsCreator.deleteItemProjectSuccess(result));
				localStorage.setItem('projects', JSON.stringify(result));
			}
		} catch (e) {
			console.log("CATCH ERROR in deleteItemProjectWatcher: ", e);
		}
	}
}

/**
 *  Move item to project watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* moveItemToProjectWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionsTypeMoveItemProject = yield take(actionsCreator.moveItemToProject.type);

			const moveToProjectId = actions?.payload?.moveToProjectId;
			const itemId = actions?.payload?.itemId ? +actions?.payload?.itemId : 0;

			const formData = {
				moveToProjectId: moveToProjectId,
			};

			const res: ApiResponse<any> = yield call(api.request, 'moveItemToProject', itemId, formData);

			if (!res.ok) {
				yield put(actionsCreator.moveItemToProjectError());
			} else {
				yield put(actionsCreator.moveItemToProjectSuccess());
			}
		} catch (e) {
			console.log("CATCH ERROR in moveItemToProjectWatcher: ", e)
		}
	}
}

/**
 *  Update count item project watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* updateCountProjectWorker(api: any, action: any) {
	try {
		const state = yield* _select(projects);

		const itemId = action?.payload?.id;
		const type = action?.payload?.type;
		const selected = action?.payload?.selected;
		const quantity = action?.payload?.quantity;
		const projectId = action?.payload?.projectId;

		const convertedProjects = convertedToArray(state.dataProjects);

		const result = (convertedProjects ?? []).reduce((acc: PropsProject[], item: PropsProject) => {
			let obj: object = {};
			if (item.id === projectId) {
				const items = item?.items ? item.items.map((x: PropsItem) => {
					let totalPrice: number = 0;
					if (
						x.totalPrice !== undefined &&
						x.basePrice !== undefined
					) {
						totalPrice = type === 'incriment'
							? x?.totalPrice + x?.basePrice
							: x?.totalPrice - x?.basePrice;
					}

					if (x.id === itemId) {
						return {
							...x,
							quantity: quantity,
							totalPrice: totalPrice,
						}
					}
					return x;
				}) : []
				obj = {
					...item,
					items: items,
				}
			} else {
				obj = item;
			}

			return acc.concat(obj);
		}, []);

		const data = {
			selected: selected,
			quantity: quantity,
		};

		if (!quantity) {
			return false;
		}

		const res: ApiResponse<null> = yield call(api.request, 'updateItemProject', {itemId, projectId, data});

		if (!res.ok) {
			yield put(actionsCreator.handlerCountError(null));
		} else {
			yield put(actionsCreator.handlerCountSuccess(result));
			localStorage.setItem('projects', JSON.stringify(result));
		}
	} catch (e) {
		console.log("CATCH ERROR in updateCountProjectWorker: ", e);
	}
}

/**
 *  Update select item project watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* updateSelectItemInProjectWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionsTypeSelectedItemProject = yield take(actionsCreator.handlerSelectItem.type);

			const state = yield* _select(projects);

			const itemId = actions?.payload?.id;
			const selected = actions?.payload?.selected;
			const quantity = actions?.payload?.quantity;
			const projectId = actions?.payload?.projectId;

			const convertedProjects = convertedToArray(state?.dataProjects);

			const result = (convertedProjects ?? []).reduce((acc: PropsProject[], item: PropsProject) => {
				let obj: object = {};
				if (item.id === projectId) {
					const items = item?.items ? item?.items.map((x: any) => {
						if (x.id === itemId) {
							return {
								...x,
								selected: !selected,
							}
						}
						return x;
					}) : [];
					obj = {
						...item,
						items: items,
					}
				} else {
					obj = item;
				}

				return acc.concat(obj);
			}, []);

			const data = {
				selected: !selected,
				quantity: quantity,
			};

			const res: ApiResponse<null> = yield call(api.request, 'updateItemProject', {itemId, projectId, data});

			if (!res.ok) {
				yield put(actionsCreator.handlerSelectItemError());
			} else {
				yield put(actionsCreator.handlerSelectItemSuccess(result));
				localStorage.setItem('projects', JSON.stringify(result));
			}
		} catch (e) {
			console.log("CATCH ERROR in updateSelectItemInProjectWatcher: ", e)
		}
	}
}

/**
 *  Post porject on checkout watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* postProjectWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionsPostToCheckout = yield take(actionsCreator.postProject.type);

			const projectId = actions?.payload.projectId;
			const navigation = actions?.payload?.navigation;

			const stateCheckout = yield* _select(checkout);
			const listAddresses = stateCheckout.listAddresses;

			const formData = {
				projectId: projectId,
			};

			const res: ApiResponse<number> = yield call(api.request, 'postProject', formData);

			if (!res.ok) {
				yield put(actionsCreator.postProjectError());
			} else {
				yield put(actionsCreator.postProjectSuccess());

				const findBillingAddress = listAddresses?.filter(
					(x: PropsAddresses) => x.type === configAddress.billing
				) || [];

				navigation?.(`/checkout`, {
					state: {
						id: res.data,
						screen: !!findBillingAddress.length ? 'addresses' : 'initial',
					}
				});
			}
		} catch (e) {
			console.log("CATCH ERROR in postProjectWatcher: ", e)
		}
	}
}

/**
 *  Post order copy watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* orderCopyWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: {
				type: string,
				payload: string | number
			} = yield take(actionsCreator.orderCopy.type);

			const res: ApiResponse<any> = yield call(
				api.request,
				'orderCopy',
				+actions.payload
			);

			if (!res.ok) {
				yield put(actionsCreator.orderCopyError());
			} else {
				yield put(actionsCreator.orderCopySuccess());
				yield put(showMessage({
					type: 'info',
					title: 'Compound Added to project',
					text: 'Review or continue browsing. Contact<br/> us for any questions. Thank you!',
				}));
				yield put(actionsCreator.getListProjects(''));
			}
		} catch (e) {
			console.log("CATCH ERROR in orderCopyWatcher: ", e)
		}
	}
}
