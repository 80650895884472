import {createSlice} from '@reduxjs/toolkit';

// Lib
import {sortedArray, convertDataByKey, filteredDBData, getClearedArrayDublicate} from "lib/thesaurus";

// Type
import {TPrice} from 'components/carts/CartBio';
import {checkAllCheckbox} from "../libraries-compounds/utils";

// Actions
import * as actionsCreatorThesaurus from "pages/thesaurus/reducer";

// Type
export type TGenes = {
	url?: string,
	name?: string,
};
export type TChildren = {
	lvl: number
	name: string
	type: string
	navKey: string
	groupId: number
	familyId: number
	className: string
	matchSearch: boolean
	children: TChildren[],
};
export type TSelectors = {
	label?: string,
	value?: string | number,
};
export type TAvailability = {
	label?: string,
	value?: string,
}
export type TBaData = {
	id?: string,
	px?: string[],
	tarPx?: string,
	isPOA?: boolean,
	onStock?: boolean,
	prices?: TPrice[],
	tarRefs?: string,
	tarAction?: string,
	selected?: boolean,
	currentPrice?: any,
};
export type TUpdateData = {
	id?: string,
	key?: string,
	data?: TBaData[],
	value?: string | number | boolean,
};
export type TDataTargetDetails = {
	name?: string,
	genes?: TGenes[],
	synonyms?: string[],
};

// Interface
interface IInitialState {
	tags: [],
	px?: string,
	type: string,
	input: string,
	goTo?: number,
	message: string,
	isNoData?: boolean,
	data: TChildren[],
	loading: boolean,
	resultGTF: string[],
	autoExpand?: boolean,
	showAllData?: boolean,
	checkboxAll?: boolean,
	loadingBaData: boolean,
	isInitLoading?: boolean,
	loadingSearchBio: boolean,
	BAData: TBaData[],
	baseBaData: TBaData[],
	currentPrice: [] | {
		label?: string;
		value?: string | number;
	},
	loadingTargetDetails: boolean,
	dataTargetDetails: TDataTargetDetails,

	paged?: boolean;
	currPage?: number;
	pageSize?: number;

	tarPx?: string,
	tarAction?: TSelectors,
	availability?: TAvailability,

	optionsTarPx: TSelectors[],
	optionsTarAction: TSelectors[],
	optionsAvailability: TSelectors[],
}

type TUpdateSelectData = {
	data: TBaData[],
	selected: boolean,
}

type TIteratingOverArray = {
	key?: string,
	value?: any,
	data?: TBaData[],
}

export const initialStateSelectors = {
	value: '',
	label: 'All',
};

const initialState: IInitialState = {
	tags: [],
	type: '',
	data: [],
	input: '',
	px: '4.5',
	BAData: [],
	message: '',
	resultGTF: [],
	loading: false,
	baseBaData: [],
	isNoData: false,
	currentPrice: [],
	checkboxAll: false,
	isInitLoading: false,
	loadingBaData: false,
	dataTargetDetails: {},
	loadingSearchBio: false,
	loadingTargetDetails: false,

	autoExpand: false,
	showAllData: false,

	goTo: 1,
	currPage: 1,
	paged: true,
	pageSize: 20,

	optionsTarPx: [],
	optionsTarAction: [],
	optionsAvailability: [],

	tarPx: '4.5',
	tarAction: initialStateSelectors,
	availability: initialStateSelectors,
}

const updateData = (props: TUpdateData) => {
	const {
		id,
		key,
		data,
		value,
	} = props;
	return data?.map((item: any) => {
		if (item.id === id) {
			return {
				...item,
				[key as string]: value
			}
		}

		return item;
	});
};

const updateSelectData = ({data, selected}: TUpdateSelectData) => data.length ? data?.map((item: TBaData) => {
	const prices = item?.prices;
	const countAvailable = prices?.filter(
		(item: TPrice) => !item.available
	)?.length;

	return {
		...item,
		selected: (!item.onStock || countAvailable === prices?.length) ? false : selected,
	}
}) : [];

const iteratingOverArrayByKey = (opt: TIteratingOverArray) => {
	const {key, data, value} = opt;
	return data?.length ? data?.map((item: TBaData) => ({
		...item,
		[key as string]: value,
	})) : [];
};

export const thesaurusSlice = createSlice({
	name: 'thesaurus',
	initialState,
	reducers: {
		handlerClearBAData: (state) => {
			state.BAData = [];
			state.baseBaData = [];
		},
		handlerChangeShowAllData: (state) => {
			state.showAllData = !state.showAllData;
		},
		handlerChangeAutoExpand: (state, action) => {
			state.autoExpand = action.payload;
		},

		handlerChangeAllPrices: (state, action) => {
			state.currentPrice = action.payload;

			const BAData = iteratingOverArrayByKey({
				data: state.BAData,
				key: 'currentPrice',
				value: action.payload,
			});

			const baseBaData = iteratingOverArrayByKey({
				key: 'currentPrice',
				value: action.payload,
				data: state.baseBaData,
			});

			state.BAData = BAData;
			state.baseBaData = baseBaData;
		},

		handlerChangeClearSelected: (state) => {
			state.checkboxAll = false;
			state.BAData = iteratingOverArrayByKey({
				value: false,
				key: 'selected',
				data: state.BAData,
			});
			state.baseBaData = iteratingOverArrayByKey({
				value: false,
				key: 'selected',
				data: state.baseBaData,
			});

		},
		handlerChangeSortItems: (state, action) => {
			let result: any = [];
			const key = action.payload.key;
			const typeSort = action.payload.typeSort;

			if (
				key === 'px' &&
				typeSort === 'sortMax'
			) {
				// @ts-ignore
				result = state.BAData.sort((a: TBaData, b: TBaData) => b[key] - a[key]);
			}

			if (
				key === 'px' &&
				typeSort === 'sortMin'
			) {
				// @ts-ignore
				result = state.BAData.sort((a: TBaData, b: TBaData) => a[key] - b[key]);
			}

			if (
				key !== 'px' &&
				typeSort === 'sortMax'
			) {
				// @ts-ignore
				result = state.BAData.sort((a: TBaData, b: TBaData) => a[key].localeCompare(b[key]));
			}

			if (
				key !== 'px' &&
				typeSort === 'sortMin'
			) {
				// @ts-ignore
				result = state.BAData.slice().sort((a: TBaData, b: TBaData) => b[key].localeCompare(a[key]));
			}

			state.BAData = result;
		},
		handlerChangeTarPx: (state, action) => {
			state.tarPx = action.payload;

			// Data
			const baseBaData = state.baseBaData;

			// Filter params
			const tarPx = action.payload;
			const tarAction = state.tarAction?.value;
			const availability = state?.availability?.value;

			const filterFunction = filteredDBData({
				tarPx: tarPx,
				tarAction: tarAction as string,
				availability: availability as string,
			});

			const filteredArray = baseBaData.filter(filterFunction);

			const optionsTarPx: TSelectors[] = convertDataByKey(filteredArray, 'tarAction');

			const filteredOptionsDublicateTarPx = sortedArray({
				data: getClearedArrayDublicate(optionsTarPx, 'value'),
			});

			filteredOptionsDublicateTarPx.unshift(actionsCreatorThesaurus.initialStateSelectors);

			state.currPage = 1;
			state.checkboxAll = false;
			state.BAData = iteratingOverArrayByKey({
				value: false,
				key: 'selected',
				data: filteredArray,
			});
			state.optionsTarAction = filteredOptionsDublicateTarPx;
		},
		handlerChangeTarAction: (state, action) => {
			state.tarAction = action.payload;

			// Data
			const dataOld = state.baseBaData;

			// Filter params
			const tarPx = state?.tarPx;
			const tarAction = action.payload?.value;
			const availability = state?.availability?.value;

			const filterFunction = filteredDBData({
				tarPx: tarPx,
				tarAction: tarAction as string,
				availability: availability as string,
			});

			state.currPage = 1;
			state.checkboxAll = false;
			state.BAData = iteratingOverArrayByKey({
				value: false,
				key: 'selected',
				data: dataOld.filter(filterFunction),
			});
		},
		handlerChangeAvailability: (state, action) => {
			state.availability = action.payload;

			// Data
			const dataOld = state.baseBaData;

			// Filter params
			const tarPx = state?.tarPx;
			const tarAction = state?.tarAction?.value;
			const availability = action.payload.value;

			const filterFunction = filteredDBData({
				tarPx: tarPx,
				tarAction: tarAction as string,
				availability: availability as string,
			});

			state.currPage = 1;
			state.checkboxAll = false;
			state.BAData = iteratingOverArrayByKey({
				value: false,
				key: 'selected',
				data: dataOld.filter(filterFunction),
			});
		},
		handlerChangeCurrPage: (state, action) => {
			state.currPage = action.payload;
		},
		handlerChangeUpdateCurrPage: (state, action) => {
			state.goTo = action.payload;
		},

		handlerChangeCheckboxAll: (state, action) => {
			const selected = action.payload;

			state.checkboxAll = selected;

			state.BAData = updateSelectData({
				data: state.BAData,
				selected: selected,
			});
			state.baseBaData = updateSelectData({
				selected: selected,
				data: state.baseBaData,
			});
		},
		handlerChangeCheckboxItem: (state, action) => {
			const id = action.payload.id;
			const selected = action.payload.selected;

			const data = updateData({
				id: id,
				key: 'selected',
				value: selected,
				data: state?.BAData?.length ? state?.BAData : [],
			}) || [];

			const baseData = updateData({
				id: id,
				key: 'selected',
				value: selected,
				data: state?.baseBaData?.length ? state?.baseBaData : [],
			}) || [];

			state.checkboxAll = checkAllCheckbox(data);
			state.BAData = data;
			state.baseBaData = baseData;
		},
		handlerChangeQuantityItem: (state, action) => {
			const id = action.payload.id;
			const number = action.payload.number;

			state.BAData = updateData({
				id: id,
				value: number,
				key: 'quantity',
				data: state?.BAData?.length ? state?.BAData : [],
			}) || [];
		},
		handlerChangePriceItem: (state, action) => {
			const id = action.payload.id;
			const price = action.payload.price;

			state.BAData = updateData({
				id: id,
				value: price,
				key: 'currentPrice',
				data: state?.BAData?.length ? state?.BAData : [],
			}) || [];
		},

		handlerChangeInputPx: (state, action) => {
			state.px = action.payload;
		},

		handlerChangeNoData: (state, action) => {
			state.isNoData = action.payload;
		},

		getBaDataForTargets: (state) => {
			state.isNoData = false;
			state.checkboxAll = false;
			state.loadingBaData = true;

			state.goTo = 1;
			state.currPage = 1;
			state.tarPx = state.px;
			state.tarAction = initialStateSelectors;
			state.availability = initialStateSelectors;
		},
		getBaDataForTargetsError: (state) => {
			state.loadingBaData = false;
		},
		getBaDataForTargetsSuccess: (state, action) => {
			state.loadingBaData = false;
			state.BAData = action.payload.data;
			state.baseBaData = action.payload.data;

			state.optionsTarPx = action.payload.optionsTarPx;
			state.optionsTarAction = action.payload.optionsTarAction;
			state.optionsAvailability = action.payload.optionsAvailability;
		},

		getTargetDetailsById: (state, action) => {
			state.loadingTargetDetails = true;
		},
		getTargetDetailsByIdError: (state) => {
			state.loadingTargetDetails = false;
		},
		getTargetDetailsByIdSuccess: (state, action) => {
			state.loadingTargetDetails = false;
			state.dataTargetDetails = action.payload;
		},

		getThesaurusById: (state, action) => {
			const isInit = action.payload;

			state.loading = true;
			if (isInit) {
				state.isInitLoading = true;
			}
		},
		getThesaurusByIdError: (state) => {
			state.loading = false;
			state.isInitLoading = false;
		},
		getThesaurusByIdSuccess: (state, action) => {
			state.loading = false;
			state.isInitLoading = false;
			state.data = action.payload;
		},

		searchTextBio: (state, action) => {
			state.loadingSearchBio = true;
		},
		searchTextBioError: (state, action) => {
			state.loadingSearchBio = false;
			state.type = action.payload.type;
			state.message = action.payload.message;
		},
		searchTextBioSuccess: (state, action) => {
			state.data = action.payload;
			state.loadingSearchBio = false;
		},
		handlerChangeMessage: (state, action) => {
			state.type = action.payload.type;
			state.message = action.payload.message;
		},

		handlerChangeInput: (state, action) => {
			state.input = action.payload;
		},

		handlerChangeCheckedGtf: (state, action) => {
			state.resultGTF = action.payload;
		},

		handlerUpdateTags: (state, action) => {
			state.tags = action.payload;
		},
	},
});

export const {
	handlerClearBAData,
	handlerChangeNoData,

	handlerChangeShowAllData,
	handlerChangeAutoExpand,

	handlerChangeMessage,

	getThesaurusById,
	getThesaurusByIdError,
	getThesaurusByIdSuccess,

	searchTextBio,
	searchTextBioError,
	searchTextBioSuccess,

	getTargetDetailsById,
	getTargetDetailsByIdError,
	getTargetDetailsByIdSuccess,

	getBaDataForTargets,
	getBaDataForTargetsError,
	getBaDataForTargetsSuccess,


	handlerChangeTarPx,
	handlerChangeCurrPage,
	handlerChangeSortItems,
	handlerChangeTarAction,
	handlerChangeAvailability,
	handlerChangeClearSelected,
	handlerChangeUpdateCurrPage,

	handlerUpdateTags,
	handlerChangeInput,
	handlerChangeInputPx,
	handlerChangePriceItem,
	handlerChangeCheckedGtf,
	handlerChangeCheckboxAll,
	handlerChangeQuantityItem,
	handlerChangeCheckboxItem,

	handlerChangeAllPrices,
} = thesaurusSlice.actions;

export default thesaurusSlice.reducer;
