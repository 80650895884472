// Type
import {TypeOptionsTitle} from 'pages/request-form/type';

export const optionsTitle: TypeOptionsTitle[] = [
	{
		label: 'Mr',
		value: 'Mr',
	},
	{
		label: 'Ms',
		value: 'Ms',
	},
	{
		label: 'Miss',
		value: 'Miss',
	},
	{
		label: 'Mrs',
		value: 'Mrs',
	},
	{
		label: 'PhD',
		value: 'PhD',
	},
	{
		label: 'Dr',
		value: 'Dr',
	},
	{
		label: 'Prof',
		value: 'Prof',
	},
];

export const radioBlock: TypeOptionsTitle[] = [
	{
		label: 'pX(target A1)/pX(target A2) = 2',
		value: 'pX(target A1)/pX(target A2) = 2'
	},
	{
		label: 'pX(target A1)/pX(target A2) = 5',
		value: 'pX(target A1)/pX(target A2) = 5',
	},
	{
		label: 'pX(target A1)/pX(target A2) = 10',
		value: 'pX(target A1)/pX(target A2) = 10',
	},
	{
		label: 'pX(target A1)/pX(target A2) > 10',
		value: 'pX(target A1)/pX(target A2) > 10',
	},
	{
		label: 'Other',
		value: 'Other',
	}
];

export const packagingGroup: TypeOptionsTitle[] = [
	{
		label: '10 µL',
		value: '10 µL',
	},
	{
		label: '25 µL',
		value: '25 µL',
	},
	{
		label: '50 µL',
		value: '50 µL',
	},
	{
		label: '100 µL',
		value: '100 µL',
	},
	{
		label: 'Other',
		value: 'Other',
	},
];

export const radioBlock2: TypeOptionsTitle[] = [
	{label: '95%+', value: '95%+'},
	{label: '98%+', value: '98%+'},
	{label: 'Other', value: 'Other3'},
];

export const radioBlock3: TypeOptionsTitle[] = [
	{
		label: '=< 10 uM',
		value: '=< 10 uM',
	},
	{
		label: '=< 1 uM',
		value: '=< 1 uM',
	},
	{
		label: 'Other',
		value: 'Other4',
	},
];

export const format: TypeOptionsTitle[] = [
	{
		label: 'Dry powder',
		value: 'Dry powder',
	},
];

export const waterOptions: TypeOptionsTitle[] = [
	{ label: 'DMSO Solution', value: 'DMSO Solution' },
	{ label: 'Water Solution', value: 'Water Solution' },
	{ label: 'DMSO-d6 Solution', value: 'DMSO-d6 Solution' },
];

export const excludeWaterSolution: string[] = ['DMSO Solution', 'Water Solution', 'DMSO-d6 Solution'];

export const expandedWaterSolution: string[] = [
	...excludeWaterSolution,
	"Other",
];

export const excludeConfigPackaging: string[] = ['10 µL', '25 µL', '50 µL', '100 µL'];

export const initialStateFormat: { [index: string]: string } = {
	name: '',
	param: '',
	format: '',
	catNumber: '',
	compoundsCount: '',
};
