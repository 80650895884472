import React, {memo} from "react";

// Components
import Avatar from "components/Avatar";

// Lib
import {shallowEqual} from "react-redux";
import {useAppDispatch, useAppSelector} from "lib/hooks/useDispatch";

// Link
import {Link} from "react-router-dom";

// Config dropdown menu
import {dropdownMenu} from 'constans/categories';

// Components
import Img from "components/Img";
import Divider from "components/Divider";
import Button from "components/ui-kit/button";
import TextField from "components/ui-kit/field-text";

// Validation
import * as yup from "yup";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";

// Actions
import {logout} from 'reducers/settings';
import * as actionsCreator from 'pages/auth/reducer';

// Type
import {IAccountMenu, PropsDropdownMenu} from "./type";

// Schema
const schema = yup.object({
	password: yup.string().nullable().required(),
	email: yup.string().nullable().email().required(),
}).required();
type FormData = yup.InferType<typeof schema>;

const AccountMenu: React.FC<IAccountMenu> = ((props) => {
	const dispatch = useAppDispatch();

	const auth = useAppSelector((store) => store.auth, shallowEqual);
	const user = useAppSelector((store) => store.settings.user, shallowEqual);
	const avatar = useAppSelector((store) => store.account.avatar, shallowEqual);
	const authorized = useAppSelector((store) => store.settings.user.authorized, shallowEqual);

	const status = auth.status;
	const message = auth.message;
	const loading = auth.loadingSingIn;

	const {register, handleSubmit, formState: {errors}} = useForm<FormData>({
		resolver: yupResolver(schema)
	});

	const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
		dispatch(actionsCreator.signIn({...data}));
	};

	const handlerClickLogout = () => {
		dispatch(logout());
	};

	const handlerOnPress = () => {
		const link = document.createElement('a');

		link.href = 'https://enaminestore.com';
		link.target = '_blank';
		link.click();
	};

	const AccountView = (
		<div className='account-menu account-menu-show-profile'>
			<div className='account-menu__header flex flex-direction-column'>
				<div className='flex flex-ai-start flex-jc-between'>
					<Avatar
						isMenu
						isShowName
						avatar={avatar}
						email={user?.email || ''}
						title={user?.title || ''}
						name={user?.firstName || ''}
						lastName={user?.lastName || ''}
					/>
				</div>
				<div className="account-menu__dropdown flex flex-direction-column">
					{dropdownMenu.map((x: PropsDropdownMenu) => {
						if (!x.isShow) return null;

						if (x.isLogout) {
							return <p
								key={x.label}
								onClick={handlerClickLogout}
								className='account-menu__menu-text'
							>
								{x.label}
							</p>
						}

						return (
							<Link
								to={x.url}
								key={x.label}
								className='account-menu__menu-text'
							>
								{x.label}
							</Link>
						)
					})}
				</div>
			</div>
		</div>
	);

	const AccountSignIn = (
		<form
			autoComplete='on'
			onSubmit={handleSubmit(onSubmit)}
			className='account-menu account-menu-show-login'
		>

			<h4 className='account-menu__title'>Welcome back</h4>
			<div className='flex flex-ai-center flex-jc-between mb15'>
				<p className='account-menu__sub-title'>Please sign in using<br/> <b>Enamine Store</b> account credentials</p>
				<div onClick={handlerOnPress}>
					<Img
						alt="Enamie Logo"
						name="logoEnamineStoreFooter"
						className="account-menu__logo"
					/>
				</div>
			</div>

			<TextField
				required
				id='email'
				name='email'
				label='Email *'
				autoComplete='email'
				isError={!!errors?.email}
				placeholder='Your answer'
				msgError={errors?.email ? errors?.email.message : ''}
				register={register('email', {required: 'Email is required', pattern: /^\S+@\S+$/i})}
			/>

			<Divider size={30}/>

			<TextField
				required
				id='password'
				isShowPassword
				name='password'
				label='Password *'
				autoComplete='password'
				placeholder='Your password'
				isError={!!errors?.password}
				msgError={errors?.password ? errors?.password.message : ''}
				register={register('password', {required: true})}
			/>

			<div className="mt10 text-al-right">
				<Link className='link' to='/forgot-password'>Forgot password?</Link>
			</div>

			<Divider size={20}/>

			{status !== 200 && !!message && <div className='account-menu__error'>{message}</div>}

			<div className='flex flex-jc-center'>
				<Button
					variable='green'
					label='Sign In'
					loading={loading}
					type='submit'
				/>
			</div>

			<div className="mt10 text-align-cn account-menu__sing-up">
				Don’t have an account? <Link className='link' to='/auth?tab=sign-up'>Sign Up</Link>
			</div>
		</form>
	);

	return <div
		style={{
			right: '0',
			top: '7rem',
			width: '100vw',
			margin: '0 auto',
			transition: '0.3s',
			overflow: 'hidden',
			position: 'absolute',
			pointerEvents: 'none',
			height: 'calc(100vh - 7rem)',
		}}
	>
		{authorized ? AccountView : AccountSignIn}
	</div>;
})

export default memo(AccountMenu);