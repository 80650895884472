import React from 'react';

type DividerType = {
	size?: number,
	borderSize?: number,
};

export const Divider = (props: DividerType) => {
	const { size = 0, borderSize = 0 } = props
	return (<div style={{ height: size + 'px', borderBottom: borderSize + 'px solid #E5E5E5' }}></div>);
};

export default Divider;
