import React, {useEffect, useState} from 'react';

// Navigation
import {Navigate, Outlet} from 'react-router-dom';

// Selector
import {useAppSelector} from "lib/hooks/useDispatch";

const PrivateRoute: React.FC<any> = () => {
	const auth = useAppSelector(
		({settings}) => settings.user.authorized
	);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(false);
	}, [auth]);

	if (loading) {
		return null; // You can return a loading indicator here if needed
	}

	return auth ? <Outlet/> : <Navigate to="/"/>
};

export default PrivateRoute;
