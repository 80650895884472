// @ts-nocheck
import {ApisauceInstance} from 'apisauce';

// Url
import {url} from '../api.url';

// Type
type FormDataProps = {
	[index: string]: string
};

const profileApi = (api: ApisauceInstance) => {
	const deleteProfileAvatar = () => api.delete(url.profile);
	const updateProfile = (formData: FormDataProps) => api.put(`${url.profileUpdate}`, formData || {});
	const getProfileAvatar = () => api.get(url.profile, {}, { headers: { 'Accept': 'image/jpeg' }});
	const saveProfileAvatar = (formData: FormDataProps) => api.post(url.profile, formData || {}, {'Content-Type': 'multipart/form-data'});

	return {
		updateProfile,
		getProfileAvatar,
		saveProfileAvatar,
		deleteProfileAvatar,
	};
};

export default profileApi;
