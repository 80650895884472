import {all, call, fork, put, select, takeLatest} from "redux-saga/effects";

// Store
import {RootState} from "store";

// Saga helper
import {
	searchRequest,
	librariesRequest,
} from "./saga-helper";

// Actions
import {hideLoader} from "reducers/loader";
import * as actionsCreator from '../reducer';

// Lib
import {isEngLang} from 'lib/helpers';

function* _select<T>(fn: (state: RootState) => T) {
	const res: T = yield select(fn);
	return res;
}

export const search = (store: RootState) => store.search;

// Start Sagas
export function* searchTextSagaStart(api: any, action: any) {
	yield fork(searchTextWatcher, api);
}

/**
 *  Search Watcher Sagas starter
 *
 * @param {function} api - api object
 * @returns
 */
export function* searchTextWatcher(api: any) {
	yield takeLatest(
		actionsCreator.searchText.type,
		searchTextWorker,
		api
	);
}

/**
 *  Post Search Text Watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* searchTextWorker(api: any, action: any): Generator<any, void, any> {
	try {
		const value = action.payload.value;
		const isHistory = action.payload.isHistory;

		const state = yield* _select(search);

		const type = state?.searchDataKeyBoard?.type;
		const size = state?.searchDataKeyBoard && type && (state.searchDataKeyBoard as Record<string, any>)[type]?.size;

		const isEng = isEngLang(value);

		if (!value) {
			return;
		}

		if (isEng) {
			const formDataCompounds = {
				value: value,
				size: state?.searchDataKeyBoard?.compounds?.size,
				page: !isHistory ? state?.searchDataKeyBoard?.compounds?.number : 0,
			};

			const formDataLibraries = {
				value: value,
				size: state?.searchDataKeyBoard?.libraries?.size,
				page: !isHistory ? state?.searchDataKeyBoard?.libraries?.number : 0,
			};

			const [resCompounds, resLibraries] = yield all([
				call(searchRequest, api, formDataCompounds),
				call(librariesRequest, api, formDataLibraries),
			]);

			const ok = resLibraries.ok === resCompounds.ok;
			const status = resLibraries.status === resCompounds.status;

			const res = {
				ok: ok,
				status: status,
				dataLibraries: resLibraries.data,
				dataCompounds: resCompounds.data,
			};

			if (!res.ok) {
				yield put(actionsCreator.searchTextError({
					type: 'error',
					message: 'Error in requset search',
				}));
				yield put(hideLoader());
			} else {
				const newData = {
					type: type,
					libraries: {
						...resLibraries.data,
						size: resLibraries.data.size === 0 ? size : resLibraries.data.size,
					},
					compounds: {
						...resCompounds.data,
						size: resCompounds.data.size === 0 ? size : resCompounds.data.size,
					},
				};

				yield put(actionsCreator.searchTextSuccess(newData));
				yield put(hideLoader());
			}
		} else {
			yield put(actionsCreator.searchTextError({
				type: 'info',
				message: 'Please use Latin letters only',
			}));
			yield put(hideLoader());
		}
	} catch (e) {
		console.log('ERROR searchText', e);
	}
}
