import {call, fork, put, select, takeLatest} from "redux-saga/effects";

// Store
import {RootState} from "store";

// Saga helper
import {
	compoundsFullfragmentRequest,
	compoundsSimilarityRequest,
	compoundsSubstructureRequest,
	linkersFullfragmentRequest,
	linkersSimilarityRequest,
	linkersSubstructureRequest,
} from "./saga-helper";

// Actions
import {hideLoader} from "reducers/loader";
import * as actionsCreator from '../reducer';

function* _select<T>(fn: (state: RootState) => T) {
	const res: T = yield select(fn);
	return res;
}

export const search = (store: RootState) => store.search;

// Start Sagas
export function* structureSearchSagaStart(api: any, action: any) {
	yield fork(linkersSimilarityWatcher, api);
	yield fork(getStructureSearchWatcher, api);
	yield fork(compoundsSimilarityWatcher, api);
	yield fork(linkersFullfragmentWatcher, api);
	yield fork(linkersSubstructureWatcher, api);
	yield fork(compoundsSubstructureWatcher, api);
	yield fork(compoundsFullfragmentWatcher, api);
}

/**
 *  Search structure Sagas starter
 *
 * @param {function} api - api object
 * @returns
 */
export function* getStructureSearchWatcher(api: any) {
	yield takeLatest(
		actionsCreator.getStructureSearch.type,
		getStructureSearchWorker,
		api
	);
}

/**
 *  Search compound similarity Sagas starter
 *
 * @param {function} api - api object
 * @returns
 */
export function* compoundsSimilarityWatcher(api: any) {
	yield takeLatest(
		actionsCreator.compoundsSimilarity.type,
		compoundsSimilarityRequest,
		api
	);
}

/**
 *  Search compound substructure Sagas starter
 *
 * @param {function} api - api object
 * @returns
 */
export function* compoundsSubstructureWatcher(api: any) {
	yield takeLatest(
		actionsCreator.compoundsSubstructure.type,
		compoundsSubstructureRequest,
		api
	);
}

/**
 *  Search exact Sagas starter
 *
 * @param {function} api - api object
 * @returns
 */
export function* compoundsFullfragmentWatcher(api: any) {
	yield takeLatest(
		actionsCreator.compoundsFullfragment.type,
		compoundsFullfragmentRequest,
		api
	);
}

/**
 *  Search linkers substructure Sagas starter
 *
 * @param {function} api - api object
 * @returns
 */
export function* linkersSubstructureWatcher(api: any) {
	yield takeLatest(
		actionsCreator.linkersSubstructure.type,
		linkersSubstructureRequest,
		api
	);
}

/**
 *  Search linkers similarity Sagas starter
 *
 * @param {function} api - api object
 * @returns
 */
export function* linkersSimilarityWatcher(api: any) {
	yield takeLatest(
		actionsCreator.linkersSimilarity.type,
		linkersSimilarityRequest,
		api
	);
}

/**
 *  Search linkers fullfragment Sagas starter
 *
 * @param {function} api - api object
 * @returns
 */
export function* linkersFullfragmentWatcher(api: any) {
	yield takeLatest(
		actionsCreator.linkersFullfragment.type,
		linkersFullfragmentRequest,
		api
	);
}

/**
 *  Post structure search Watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getStructureSearchWorker(api: any, action: any): Generator<any, void, any> {
	try {
		const state = yield* _select(search);

		const keyConfigMarvin = 'configMarvin';
		const keyCurrentValueMarvin = 'currentValueMarvin';

		const form = action.payload.data.form;
		const type = form.typeStructure;
		const source = action.payload.data.source;
		const structureData = state?.structureSearch.structureData;

		let structureDataEBC = structureData.compounds;
		let structureDataLinkers = structureData.linkers;

		const linkers = form.linkers;
		const compounds = form.compounds;
		const structureType = state?.structureSearch.structureType;
		const isLinkers = structureType === 'linkers';

		const formDataChemutils = {
			format: 'SMARTS',
			structure: source,
		};

		const resConvertStructure = yield call(
			api.request,
			'chemutilsConvertStructure',
			formDataChemutils
		);

		if (!resConvertStructure.ok) {
			yield put(actionsCreator.getStructureSearchError());
			yield put(hideLoader());
			return;
		}

		const molecule = resConvertStructure.data;

		yield put(actionsCreator.historyList({
			data: molecule,
			localStoreKey: 'structureList',
			storeName: 'historyStructureSearch',
		}));

		const currentConfigMarvin = JSON.parse(localStorage.getItem(keyConfigMarvin) || '[]');
		const currentValueMarvin = localStorage.getItem(keyCurrentValueMarvin);

		const newForm = isLinkers ? currentConfigMarvin : form;

		localStorage.setItem(keyCurrentValueMarvin, molecule);
		localStorage.setItem(keyConfigMarvin, JSON.stringify(newForm));

		structureDataEBC = {
			...structureData.compounds,
			number: currentValueMarvin === molecule ? structureData.compounds.number : 0,
		};

		structureDataLinkers = {
			...structureData.linkers,
			number: currentValueMarvin === molecule ? structureData.linkers.number : 0,
		};

		const filter: { [index: string]: number | string } = {
			hbaGte: newForm.hbaGte,
			hbaLte: newForm.hbaLte,
			hbdGte: newForm.hbdGte,
			hbdLte: newForm.hbdLte,
			tpsaGte: newForm.tpsaGte,
			tpsaLte: newForm.tpsaLte,
			fsp3Gte: newForm.fsp3Gte,
			fsp3Lte: newForm.fsp3Lte,
			logPGte: newForm.logPGte,
			logPLte: newForm.logPLte,
			heavyAtomCountGte: newForm.heavyAtomCountGte,
			heavyAtomCountLte: newForm.heavyAtomCountLte,
			molecularWeightGte: newForm.molecularWeightGte,
			molecularWeightLte: newForm.molecularWeightLte,
			rotatableBondCountGte: newForm.rotatableBondCountGte,
			rotatableBondCountLte: newForm.rotatableBondCountLte,
		};

		const similarityThreshold = +form.searchSimilarity;

		const nonEmptyValues = Object.fromEntries(
			Object.entries(filter)
				.filter(
					([key, value]) => value !== '' && value !== undefined
				)
		);

		const similarity: { [index: string]: string | number } = type === 'similarity'
			? {similarityThreshold: similarityThreshold}
			: {};

		const isFilter = Object.values(nonEmptyValues).filter(Boolean).length
			? nonEmptyValues
			: {};

		for (const key in isFilter) {
			isFilter[key] = +isFilter[key];
		}

		const formDataEBC: { [index: string]: string | {} | undefined } = {
			...similarity,
			filter: isFilter,
			molecule: molecule,
		};

		const formDataLinkers: { [index: string]: string | {} | undefined } = {
			...similarity,
			molecule: molecule
		};

		// Radio btn substructure / checkbox EBC/Linkers
		if (
			compounds &&
			type === 'substructure'
		) {
			yield put(actionsCreator.compoundsSubstructure({data: formDataEBC, urlParam: structureDataEBC}));
		}

		if (
			linkers &&
			type === 'substructure'
		) {
			yield put(actionsCreator.linkersSubstructure({data: formDataLinkers, urlParam: structureDataLinkers}));
		}

		// Radio btn similarity / checkbox EBC/Linkers
		if (
			compounds &&
			type === 'similarity'
		) {
			yield put(actionsCreator.compoundsSimilarity({data: formDataEBC, urlParam: structureDataEBC}));
		}

		if (
			linkers &&
			type === 'similarity'
		) {
			yield put(actionsCreator.linkersSimilarity({data: formDataLinkers, urlParam: structureDataLinkers}))
		}

		// Radio btn exact / checkbox EBC/Linkers
		if (
			compounds &&
			type === 'exact'
		) {
			yield put(actionsCreator.compoundsFullfragment({data: formDataEBC, urlParam: structureDataEBC}));
		}

		if (
			linkers &&
			type === 'exact'
		) {
			yield put(actionsCreator.linkersFullfragment({data: formDataLinkers, urlParam: structureDataLinkers}));
		}

		yield put(hideLoader());
		yield put(actionsCreator.getStructureSearchSuccess());
	} catch (e) {
		console.log('CATCH ERROR in getStructureSearchWorker: ', e);
	}
}
