import {ApisauceInstance} from 'apisauce';

// Url
import {url} from '../api.url';

// Type
type TFormData = {
	[index: string]: number | string
};

const libraries = (api: ApisauceInstance) => {
	const getLibAll = () => api.get(url.getLibAll);
	const getCompoundsCount = () => api.get(url.getCompoundsCount);
	const getHighlightCarousel = () => api.get(`${url.getHighlightCarousel}`);
	const getHighlightLib = (id?: string) => api.get(`${url.getHighlightLib}${id}`);
	const getLibFullDetails = (id?: string) => api.get(`${url.getLibFullDetails}${id}`);
	const getCompoundById = (id: string) => api.get(`${url.getSearchCompoundById}${id}`);
	const getBaDataMainActivity = (id: string) => api.get(`${url.baDataMainActivity}?ebcId=${id}`);
	const getLibCompoundsIds = (id: string) => api.get(`${url.getLibAll}/${id}/compounds?onlyStockInfo=true`);
	const getLibCompounds = (formData: TFormData) =>
		api.get(`${url.getLibAll}/${formData.id}/compounds?page=${formData.page}&size=${formData.size}&availability=${formData.accessibility}`);
	const searchLibCompounds = (formData: {[index: string]: string | number}, id: string) =>
		api.get(`${url.getLibAll}/${id}/compounds/search?size=${formData.size}&page=${formData.page}&query=${formData.value}${formData.isFilter ? `&onStockOnly=${formData.isFilter}` : ''}`);

	return {
		getLibAll,
		getCompoundById,
		getHighlightLib,
		getLibCompounds,
		getLibFullDetails,
		getCompoundsCount,
		searchLibCompounds,
		getLibCompoundsIds,
		getHighlightCarousel,
		getBaDataMainActivity,
	};
};

export default libraries;
