import {call, delay, fork, put, select, take} from "redux-saga/effects";

import {RootState} from "store";

// Lib
import {filteredDataPrices, optionsPrices} from "lib/helpers";
import {convertDataByKey, getClearedArrayDublicate, sortedArray} from "lib/thesaurus";

// Actions
import {showMessage} from "reducers/messages";
import * as actionsCreatorThesaurus from "./reducer";
import { getTimeSuccess } from "pages/timer/reducer";
import * as actionsCreator from 'pages/thesaurus/reducer';

// Type
import {TChildren, TBaData} from "./reducer";
import {TypeTarget} from "pages/thesaurus/type";
type TMap = {
	[index: string]: string | number | boolean
}
type TOptions = {
	label?: string;
	value?: string;
};


// Start Sagas
export function* thesaurusSagaStart(api: any, action: any) {
	yield fork(getThesaurusByIdWatcher, api, action);
	yield fork(getBaDataForTargetsWatcher, api, action);
	yield fork(getTargetDetailsByIdWatcher, api, action);
}

function* _select<T>(fn: (state: RootState) => T) {
	const res: T = yield select(fn);
	return res;
}

export const thesaurus = (store: RootState) => store.thesaurus;

/**
 * Get thesaurus by id Watcher
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getThesaurusByIdWatcher(api: any, action: any): Generator<any, void, any> {
	while (true) {
		try {
			let newData: TChildren[] = [];
			const actions: any = yield take(
				actionsCreator.getThesaurusById.type
			);

			const {pos, resolve, familyId} = actions.payload;

			const res = yield call(
				api.request,
				'getThesaurusById',
				{id: familyId || 0}
			);

			if (!res?.ok) {
				yield put(actionsCreator.getThesaurusByIdError());
			} else {
				if (!!familyId) {
					const state = yield* _select(thesaurus);
					const initState: TChildren[] = structuredClone(state.data);

					let currentElement: any = initState;
					pos.forEach((posIndex: string, i: number) => {
						if (i === 0) {
							return;
						}
						currentElement = Array.isArray(currentElement)
							? currentElement[+posIndex]
							: currentElement?.children[+posIndex];
					});

					currentElement.children = res?.data?.children;

					newData = initState;
				} else {
					newData = res.data.children;
				}

				yield put(actionsCreator.getThesaurusByIdSuccess(newData));
				if (resolve) {
					resolve('');
				}
			}
		} catch (e) {
			console.log('CATCH ERROR getThesaurusByIdWatcher: ', e);
		}
	}
}

/**
 * Get Ba data for targets Watcher
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getBaDataForTargetsWatcher(api: any, action: any): Generator<any, void, any> {
	while (true) {
		try {
			yield take(
				actionsCreator.getBaDataForTargets.type
			);

			yield put(getTimeSuccess({ data: 'start', key: 'time' }));

			const state = yield* _select(thesaurus);
			const px = state.px;
			const tags = state.tags;

			const targetIds = tags.map((item: TypeTarget) => item.targetId);

			const formData = {
				px: px ? +px : 0,
				targetIds: targetIds,
			};

			const res = yield call(api.request, 'getBaDataTargets', formData);

			yield put(delay(300));

			if (!res.ok) {
				yield put(actionsCreator.getBaDataForTargetsError());
				yield put(getTimeSuccess({ data: 'stop', key: 'time' }));
			} else {
				const data = res?.data;

				if (!data.length) {
					yield put(actionsCreator.handlerChangeNoData(true));
					const uniqId = crypto.randomUUID();
					yield put(showMessage({
						type: 'info',
						uniqId: uniqId,
						text: 'No data available',
					}));
				}

				const convertObject = (item: TMap) => {
					return {
						value: 'poa',
						mg: item.mg || '',
						label: `POA/${item.mg}mg`,
					}
				};

				const result = data.map((item: TBaData) => {
					let titlePOA = '';
					let countPrice = 0;

					const countAvailable = item.prices?.filter(
						(item: any) => !item.available
					)?.length;

					const optionsPr = item?.prices?.length
					? filteredDataPrices(item?.prices)?.map((item: TMap) =>
						optionsPrices(item)
					) : [];
					let updateOptions: TMap[] = optionsPr;

					updateOptions = optionsPr.map((item: TMap) => {
						if (item.value === undefined) {
							countPrice += 1;

							return {
								...item,
								...convertObject(item),
							}
						}
						return item;
					});

					if (updateOptions.length === countPrice) {
						updateOptions = updateOptions.map((item: TMap) => convertObject(item));
						titlePOA = 'Price on asking';
					}

					return {
						...item,
						quantity: 1,
						selected: false,
						currentPrice: [],
						titlePOA: titlePOA,
						optionsPrices: updateOptions,
						px: item?.tarPx?.match(/\d+\.\d+/g),
						isPOA: updateOptions.length === countPrice,
						isAvailablePrices: countAvailable === item?.prices?.length,
					}
				});

				const filteredTarAction = result.map((item: any) => item.tarAction);
				const cleanedArray: any = [...new Set(
					filteredTarAction
						.filter((item: string) => !item.includes("-"))
						.flatMap((item: string) => item.split(" | "))
				)];

				const optionsTarPx: TOptions[] = convertDataByKey(result, 'tarPx');
				const optionsTarAction: TOptions[] = cleanedArray?.map((item: string) => ({
					label: item,
					value: item,
				})) || [];

				const filteredOptionsDublicateTarPx = sortedArray({
					data: getClearedArrayDublicate(optionsTarPx, 'value'),
				});
				const filteredOptionsDublicateTarAction = sortedArray({
					isAlphabet: true,
					data: optionsTarAction
					// data: getClearedArrayDublicate(optionsTarAction, 'value'),
				});

				const optionsAvailability: TOptions[] = [
					actionsCreatorThesaurus.initialStateSelectors,
					{label: 'In Stock/POA', value: 'ON_STOCK_POA'},
					{label: 'In Stock', value: 'ON_STOCK'},
					{label: 'Out of stock', value: 'OUT_OF_STOCK'}
				];

				filteredOptionsDublicateTarPx.unshift(actionsCreatorThesaurus.initialStateSelectors);
				filteredOptionsDublicateTarAction.unshift(actionsCreatorThesaurus.initialStateSelectors);

				yield put(actionsCreator.getBaDataForTargetsSuccess({
					data: result,
					optionsAvailability: optionsAvailability,
					optionsTarPx: filteredOptionsDublicateTarPx,
					optionsTarAction: filteredOptionsDublicateTarAction,
				}));
				yield put(getTimeSuccess({ data: 'stop', key: 'time' }));
				yield put(actionsCreator.handlerChangeAllPrices([]));
			}
		} catch (e) {
			console.log('CATCH ERROR getBaDataForTargetsWatcher: ', e)
		}
	}
}

/**
 * Get target details by id Watcher
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getTargetDetailsByIdWatcher(api: any, action: any): Generator<any, void, any> {
	while (true) {
		try {
			const actions: any = yield take(
				actionsCreator.getTargetDetailsById.type
			);

			const res: any = yield call(api.request, 'getTargetDetailsById', actions.payload);

			yield put(delay(400));

			if(!res.ok) {
				yield put(actionsCreator.getTargetDetailsByIdError());
			} else {
				yield put(actionsCreator.getTargetDetailsByIdSuccess(res.data));
			}
		} catch (e) {
			console.log('CATCH ERROR getTargetDetailsByIdWatcher: ', e)
		}
	}
}
