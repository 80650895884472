import {call, fork, put, take,} from "redux-saga/effects";

// Actions
import * as actionsCreator from "./reducer";

// Type
import {ApiResponse, UnitLibraries} from './type';

export function* librariesSagaStart(api: any, action: any) {
	yield fork(getCompoundsCountWatcher, api, action);
	yield fork(getAllLibrariesChangeWatcher, api, action);
}

/**
 *  Get All libraries watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getAllLibrariesChangeWatcher(api: any, action: any) {
	while (true) {
		try {
			yield take(actionsCreator.getAlllibraries.type);

			const res: ApiResponse<UnitLibraries[]> = yield call(api.request, "getLibAll");

			if (!res.ok) {
				yield put(actionsCreator.getAlllibrariesError());
			} else {
				yield put(actionsCreator.getAlllibrariesSuccess(res?.data ? res.data : []));
			}
		} catch (e) {
			console.log("CATCH ERROR: getAllLibrariesChangeWatcher: ", e);
		}
	}
}

/**
 *  Get All libraries watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getCompoundsCountWatcher(api: any, action: any) {
	while (true) {
		try {
			yield take(actionsCreator.getCompoundsCount.type);

			const res: ApiResponse<UnitLibraries[]> = yield call(api.request, "getCompoundsCount");

			if (!res.ok) {
				yield put(actionsCreator.getCompoundsCountError());
			} else {
				yield put(actionsCreator.getCompoundsCountSuccess(res?.data ? res.data : []));
			}
		} catch (e) {
			console.log("CATCH ERROR: getCompoundsCountWatcher: ", e);
		}
	}
}

