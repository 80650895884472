import {call, delay, fork, put, take,} from "redux-saga/effects";

// Actions
import {modalClose} from "reducers/modal";
import * as actionsCreator from "../reducer";
import {showMessage} from "reducers/messages";
import * as actionsLoader from "reducers/loader";

// Type
export type ApiResponse<Data extends unknown> = {
	data?: Data;
	ok?: boolean;
	error?: string;
	status?: number;
};
type PayloadTypeSingUp = {
	title?: string,
	country?: string,
	lastName?: string,
	position?: string,
	firstName?: string,
	companyName?: string,
};
type ActionSingUpType = {
	type?: string,
	payload?: PayloadTypeSingUp,
};
type ActionUpdateAvatarType = {
	type?: string,
	payload?: any,
}

export function* sagaProfileStart(api: any, action: any) {
	yield fork(getAvatarWatcher, api, action);
	yield fork(updateAvatarWatcher, api, action);
	yield fork(updateProfileWatcher, api, action);
	yield fork(deleteAvatarUserWatcher, api, action);
}

/**
 *  Update profile data in watcher Sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* updateProfileWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionSingUpType = yield take(actionsCreator.updateProfile.type);

			const keyAuth = 'auth';
			const auth = localStorage.getItem(keyAuth) || '';
			const parsedAuth = JSON.parse(auth);

			const formData = {
				title: actions.payload?.title,
				country: actions.payload?.country,
				position: actions.payload?.position,
				lastName: actions.payload?.lastName,
				firstName: actions.payload?.firstName,
				organization: actions.payload?.companyName,
			};

			const res: ApiResponse<any> = yield call(api.request, 'updateProfile', formData);

			if (!res.ok) {
				yield put(actionsCreator.updateProfileError());
			} else {
				const updatedFormDataAuth = {
					...parsedAuth,
					...formData,
				};
				localStorage.setItem(keyAuth, JSON.stringify(updatedFormDataAuth));
				yield delay(300);
				yield put(actionsCreator.updateProfileSuccess());
				yield put(showMessage({
					type: 'info',
					text: 'The profile was updated successfully',
				}))
			}
		} catch (e) {
			console.log("CATCH ERROR in updateProfileWatcher: ", e);
		}
	}
}

/**
 *  Get avatar user watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getAvatarWatcher(api: any, action: any) {
	while (true) {
		try {
			yield take(actionsCreator.getAvatarUser.type);

			const res: ApiResponse<any> = yield call(api.request, 'getProfileAvatar');

			if (!res.ok) {
				yield put(actionsCreator.getAvatarUserError());
			} else {
				const result = !!res.data.size ? res.data : '';
				yield put(actionsCreator.getAvatarUserSuccess(result));
			}
		} catch (e) {
			console.log("CATCH ERROR in getAvatarWatcher: ", e);
		}
	}
}

/**
 *  Update avatar user watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* updateAvatarWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionUpdateAvatarType = yield take(actionsCreator.updateAvatarUser.type);

			const imgForm = new FormData();

			imgForm.append('avatar', actions.payload);

			const res: ApiResponse<any> = yield call(api.request, 'saveProfileAvatar', imgForm);

			if (!res.ok) {
				yield put(actionsCreator.updateAvatarUserError());
			} else {
				yield put(actionsCreator.updateAvatarUserSuccess(res.data));
				yield put(actionsCreator.getAvatarUser());
				yield put(showMessage({
					type: 'info',
					text: 'The avatar was updated successfully',
				}));
				yield put(modalClose());
			}
		} catch (e) {
			console.log("CATCH ERROR in updateAvatarWatcher: ", e);
		}
	}
}

/**
 *  Delete avatar user watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* deleteAvatarUserWatcher(api: any, action: any) {
	while (true) {
		try {
			yield take(actionsCreator.deleteAvatarUser.type);

			yield put(actionsLoader.showLoader());

			const res: ApiResponse<any> = yield call(api.request, 'deleteProfileAvatar');

			if(!res.ok) {
				yield put(actionsCreator.deleteAvatarUserError());
				yield put(actionsLoader.hideLoader());
			} else {
				yield put(actionsCreator.deleteAvatarUserSuccess());
				yield put(actionsCreator.getAvatarUser());
				yield put(actionsLoader.hideLoader());
			}
		} catch (e) {
			console.log("CATCH ERROR in updateAvatarWatcher: ", e);
		}
	}
}
