import React, {useEffect} from "react";

// Type
import {ILayout} from "./type";

export const Layout: React.FC<ILayout> = ({children}) => {
	const handlerError = (e: any) => {
		if (/Loading chunk [\d]+ failed/.test(e.message)) {
			window.location.reload();
		}
	};

	useEffect(() => {
		window.addEventListener('error', handlerError);

		return () => {
			window.removeEventListener("error", handlerError);
		};
	}, []);

	return (
		<main>
			{children}
		</main>
	);
};
