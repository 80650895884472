import {ApisauceInstance} from 'apisauce';

// Url
import {url} from '../api.url';

// Type
type PropsFormDataPostProject = {
	[index: string]: string | number
};
type PropsFormDataUpdate = {
	userComment: string,
	shippingAddressId: number,
}

const orders = (api: ApisauceInstance) => {
	const getAllOrders = () => api.get(url.getOrders);
  const getOrderById = (id: number) => api.get(`${url.getOrders}/${id}`);
  const postProject = (formData: PropsFormDataPostProject) => api.post(url.getOrders, formData || {});
	const updateOrder = (formData: PropsFormDataUpdate, id: number) => api.put(`${url.getOrders}/${id}`, formData || {});

	return {
		updateOrder,
		postProject,
		getAllOrders,
    getOrderById,
	};
};

export default orders;
