import {
  ApisauceInstance
} from 'apisauce';

// Url
import { url } from '../api.url';

const contact = (api: ApisauceInstance) => {
  const contactUs = (formData: any) => api.post(url.contactUs, formData);

  return {
    contactUs,
  };
};

export default contact;
