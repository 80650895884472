import {createSlice} from '@reduxjs/toolkit';

// Type
export type TPrices = {
	mg?: string;
	value?: string;
	priceUsd?: number;
	priceEur?: number;
	available?: boolean;
}

export type ItemSolubility = {
	conc?: number,
	unit?: string,
	cdId?: number,
	solvent?: string,
	measure?: string,
	salt?: string,
	comment?: string,
}

export const mainActivity = {
	effects: '',
	assayType: '',
	bioSpecies: '',
	mainTarAllPx: '',
	activityValAll: '',
	mainTarAllRefs: '',
	actionOnTargets: '',
}

const initialState = {
	data: {
		id: "",
		qed: 0,
		cas: "",
		wqed: 0,
		dbId: "",
		name: "",
		prices: [],
		pharmacopoeia: [],
		cxSmiles: "",
		synonyms: "",
		iupacName: "",
		onStock: null,
		mdlNumber: "",
		descriptor: {
			qed: 0,
			hba: 0,
			hbd: 0,
			tpsa: 0,
			fsp3: 0,
			wqed: 0,
			logP: 0,
			heavyAtomCount: 0,
			molecularWeight: 0,
			rotatableBondCount: 0,
		},
		impForDrug: [],
		drugForImp: [],
		description: "",
		fdaApproved: false,
	},
	count: 1,
	price: 0,
	cans: [],
	loading: false,
	disabled: false,
	currentPrice: {
		mg: '',
		type: '',
		label: '',
		value: '',
	},
	mainActivity: mainActivity,

	loadingSolubilities: false,
	dataSolubilities: [],
};

export const moleculeProductSlice = createSlice({
	name: 'moleculeProduct',
	initialState: initialState,
	reducers: {
		incriment: (state, action) => {
			state.count = state.count + 1;
		},
		decrement: (state, action) => {
			if (state.count - 1) {
				state.count = state.count - 1;
			}
		},
		handlerChangePrice: (state, action) => {
			state.price = action.payload.price;
			state.currentPrice = action.payload;
			state.count = 1;
		},

		getMoleculeById: (state, action) => {
			state.loading = true;
		},
		getMoleculeByIdError: (state) => {
			state.loading = false;
		},
		getMoleculeByIdSuccess: (state, action) => {
			state.loading = false;
			state.cans = action.payload.cans;
			state.data = action.payload.data;
			state.disabled = action.payload.disabled;
			state.mainActivity = action.payload.mainActivity;
		},

		handlerClearState: (state) => {
			state.data = initialState.data;
			state.count = initialState.count;
			state.price = initialState.price;
			state.loading = initialState.loading;
			state.disabled  = initialState.disabled;
			state.currentPrice = initialState.currentPrice;
		},

		getSolubilitiesByEbcId: (state, action) => {
			state.loadingSolubilities = true;
		},
		getSolubilitiesByEbcIdError: (state) => {
			state.loadingSolubilities = false;
		},
		getSolubilitiesByEbcIdSuccess: (state, action) => {
			state.loadingSolubilities = false;
			state.dataSolubilities = action.payload;
		},
	},
});

export const {
	incriment,
	decrement,
	getMoleculeById,
	handlerClearState,
	handlerChangePrice,
	getMoleculeByIdError,
	getMoleculeByIdSuccess,

	getSolubilitiesByEbcId,
	getSolubilitiesByEbcIdError,
	getSolubilitiesByEbcIdSuccess,
} = moleculeProductSlice.actions;

export default moleculeProductSlice.reducer;
