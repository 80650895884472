// @ts-nocheck
// Apisauce
import apisauce, {ApisauceInstance} from 'apisauce';

import Config from "lib/config";

// Api
import cansApi from "./cans/cans.api";
import authApi from "./auth/auth.api";
import searchApi from "./search/search.api";
import ordersApi from "./orders/orders.api";
import addressApi from "./address/address.api";
import contactApi from "./contact/contact.api";
import profileApi from "./profile/profile.api";
import projectsApi from './projects/projects.api';
import librariesApi from "./libraries/libraries.api";
import chemutilsApi from "./chem-utils/chem-utils.api";
import resendMail from "./resend-mail/resend-mail.api";
import solubilitiesApi from "./solubilities/solubilities.api";
import drugImpurityEbc from "./drug-impurity/drug-impurity.api";
import resetPassword from "./reset-password/reset-password.api";
import downloadFileApi from "./download-file/download-file.api";
import bioInfoRequest from "./bio-info-request/bio-info-request";

// Actions
import {logout, updateSettingsUser} from 'reducers/settings';

// Constants
import url from "./api.url";

// API error codes
// 400 - неверные данные со стороны клиента, response body - json
// 401 - ошибка аутентификации
// 403 - ошибка авторизации
// 500 - случилась необработанная ошибка
// 583 - обработанная бизнес-ошибка по причине неверной параметризации
// приложения либо сторонних систем(содержит только код, без описания) - response body - json

/**
 * API Provider Example
 *
 * @param {object} apiConfig - Config object
 * @param {object} store - The redux store
 * @param {function} [requestTransform] - Before request object transform
 * @param {function} [responseTransform] - After request response transformation
 * @returns {object} List on all api calls
 */

let isRefreshing = false;
let refreshSubscribers = [];
const addHeaders = {
  'Access-Control-Allow-Origin': window.location.origin,
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onRefreshes(accessToken) {
  refreshSubscribers.map(
    (cb) => cb(accessToken)
  );
}

const create = (
  apiConfig,
  store,
  requestTransform,
  responseTransform
) => {
  // ------
  // STEP 1
  // ------

  //
  // Create and configure an apisauce-based api object.
  //
  const apiMain: ApisauceInstance = apisauce.create({
    ...apiConfig,
    baseURL: Config.api.apiUrl,
    headers: {
      ...apiConfig.addHeaders,
      ...addHeaders,
    },
  });

  apiMain?.axiosInstance?.interceptors?.response.use(
    response => response,
    async function (err) {
      const originalRequest = err?.config;
      const status = err?.response?.status;

      const getAuth = localStorage.getItem('auth');

      const refreshStorage = JSON.parse(getAuth)?.refresh || '';

        if (status === 401 || status === 403) {
          if (!isRefreshing) {
            isRefreshing = true;
            fetch(`${Config.api.apiUrl}${url.refresh}`, {
              mode: 'cors', // no-cors, *cors, same-origin
              method: 'POST', // *GET, POST, PUT, DELETE, etc.
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              credentials: 'same-origin', // include, *same-origin, omit
              headers: {
                ...apiConfig.addHeaders,
                'Content-Type': 'application/json',
              },
              redirect: 'follow', // manual, *follow, error
              referrerPolicy: 'no-referrer', // no-referrer, *client
              body: JSON.stringify({
                refreshToken: refreshStorage,
              }) // body data type must match "Content-Type" header
            })
              .then(data => data.json())
              .then((res) => {
                if (res?.accessToken) {
                  isRefreshing = false;

                  const getAuth = localStorage.getItem('auth');
                  let auth;
                  if (getAuth) {
                    auth = JSON.parse(getAuth);
                  }

                  const formData = {
                    ...auth,
                    token: res.accessToken,
                  };

                  localStorage.setItem('auth', JSON.stringify(formData));

                  store.dispatch(updateSettingsUser(formData));
                  onRefreshes(res.accessToken);
                } else {
                  store.dispatch(logout());
                }
              })
              .catch((e) => {
                store.dispatch(logout());
              });
          }
          return new Promise((resolve, reject) => {
            subscribeTokenRefresh((accessToken) => {
              originalRequest.headers["Authorization"] = "Bearer " + accessToken;
              resolve(apiMain.axiosInstance(originalRequest));
            });
          }).finally(() => {
            refreshSubscribers = [];
          });
        } else {
          return Promise.reject(err);
        }
      return Promise.reject(err);
    });

  apiMain.addRequestTransform((req) => {
    if (requestTransform && typeof requestTransform === "function") {
      requestTransform(req, "before");
    }
    const urlPattern1 = /\/libs\/.+\/files\/.+/;
    const urlPattern2 = /\/api\/highlights\/\d+\/image/;

    // Custom transforms here!
    // for all pdf requests set blob response
    if (
      req.url.includes("libs/media/download/") ||
      req.url.includes("/chemutils/smiles-image") ||
      urlPattern1.test(req.url) ||
      urlPattern2.test(req.url) ||
      (req.url.includes('profile/users/current/avatar') && req.method === 'get')
    ) {
      req.responseType = "blob";
    }

    if (requestTransform && typeof requestTransform === "function") {
      requestTransform(req, "after");
    }
  });

  apiMain.addResponseTransform((res) => {
    if (responseTransform && typeof responseTransform === "function") {
      responseTransform(res, "before");
    }
  });

  // ------
  // Return scope of Calls
  // ------

  return {
    ...cansApi(apiMain),
    ...authApi(apiMain),
    ...ordersApi(apiMain),
    ...searchApi(apiMain),
    ...resendMail(apiMain),
    ...addressApi(apiMain),
    ...profileApi(apiMain),
    ...contactApi(apiMain),
    ...projectsApi(apiMain),
    ...librariesApi(apiMain),
    ...chemutilsApi(apiMain),
    ...resetPassword(apiMain),
    ...bioInfoRequest(apiMain),
    ...drugImpurityEbc(apiMain),
    ...solubilitiesApi(apiMain),
    ...downloadFileApi(apiMain),
  };
};

// let's return back our create method as the default.
export default {
  create,
};
