import { createSlice } from "@reduxjs/toolkit";

// Type
type UnitHightLight = {
  img?: string | undefined,
  rank?: number | undefined,
  cdid?: string | undefined,
  prodId?: string | undefined,
  smiles?: string | undefined,
  description?: string | undefined,
};

// Interface
interface IInitialState {
  loading?: boolean;
  data?: UnitHightLight[];
}

const initialState: IInitialState = {
  data: [],
  loading: false,
};

export const librariesProductSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    getHighlightCarousel: (state) => {
      state.loading = true;
    },
    getHighlightCarouselError: (state) => {
      state.loading = false;
    },
    getHighlightCarouselSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const {
  getHighlightCarousel,
  getHighlightCarouselError,
  getHighlightCarouselSuccess,
} = librariesProductSlice.actions;

export default librariesProductSlice.reducer;
