import React from "react";

// Styles
import "./assets/css/index.scss";

// Components
import { Layout } from "./components/layout";

// Routes
import Routes from "./routes";

const App: React.FC = () => <Layout children={Routes} />;

export default App;
