import {call, fork, put, take,} from "redux-saga/effects";

// Actions
import * as actionsCreator from "./reducer";
import {modalClose, openModal} from "reducers/modal";
import {changeAuthorized, updateSettingsUser} from 'reducers/settings';

// Type
import {ActionSingUpType, ActionType, ApiResponse} from "./type";

export function* authSagaStart(api: any, action: any) {
	yield fork(singUpWatcher, api, action);
	yield fork(signInWatcher, api, action);
}

/**
 *  Sing in watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* signInWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionType = yield take(actionsCreator.signIn.type);

			const navigate = actions?.payload?.navigate;
			const isBiosearch = actions?.payload?.isBiosearch === 'isBiosearch';

			const formData = {
				email: actions?.payload?.email,
				password: actions?.payload?.password,
			};

			const res: ApiResponse<any> = yield call(api.request, "signIn", formData);

			if (!res.ok) {
				yield put(actionsCreator.signInError({status: res?.data?.status, data: res.data}));
			} else {
				localStorage.setItem('auth', JSON.stringify(res.data));
				// localStorage.setItem('storage', JSON.stringify({
				// 	logout: false,
				// }));

				yield put(actionsCreator.signInSuccess(res.data));
				yield put(updateSettingsUser(res.data));
				yield put(changeAuthorized(true));

				if (navigate) {
					navigate(isBiosearch ? '/search?tab=bio-search' : '/');
				}
			}
		} catch (e) {
			console.log("CATCH ERROR: signInWatcher: ", e);
		}
	}
}

/**
 *  Sing Up watcher Sagas starter
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* singUpWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionSingUpType = yield take(actionsCreator.singUp.type);

			const navigate = actions.payload?.navigate;

			const formData = {
				title: actions.payload?.title,
				email: actions.payload?.email,
				phone: actions.payload?.phone,
				password: actions.payload?.password,
				lastName: actions.payload?.lastName,
				position: actions.payload?.position,
				firstName: actions.payload?.firstName,
				organization: actions.payload?.companyName,
				advertConsent: actions.payload?.advertConsent
			};

			const res: ApiResponse<any> = yield call(api.request, "singUp", formData);

			if (!res.ok) {
				yield put(actionsCreator.singUpError(res?.data?.error || res?.data));
			} else {
				yield put(openModal({
					type: 'success',
					navigate: navigate,
					actionClose: modalClose,
					title: 'Thank you for the registration!'
				}));

				yield put(actionsCreator.singUpSuccess());
			}
		} catch (e) {
			console.log("CATCH ERROR: singUpWatcher: ", e);
		}
	}
}
