import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
	showLoader: false,
	isTransparent: false,
};

export const loaderSlice = createSlice({
	name: 'loader',
	initialState: initialState,
	reducers: {
		showLoader: {
			reducer: (state, action: PayloadAction<boolean>) => {
				state.showLoader = true;
				state.isTransparent = action.payload;
			},
			prepare: (isTransparent: boolean = false) => ({ payload: isTransparent }),
		},
		hideLoader: {
			reducer: (state) => {
				state.showLoader = false;
				state.isTransparent = false;
			},
			prepare: () => ({ payload: undefined }),
		},
	},
});

export const { showLoader, hideLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
