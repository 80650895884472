import {
	ApisauceInstance
} from 'apisauce';

// Url
import { url } from '../api.url';

// Type
type TypeFormData = {
	format: string,
	smiles: string,
};

const Chemutils = (api: ApisauceInstance) => {
	const chemutils = (formData: TypeFormData) => api.post(url.chemutils, formData || {});
	const chemutilsSmilesImage = (formData: string) => api.get(`${url.chemutils}?smiles=${formData}&format=png:-a,w400,h400,noantialias,H_hetero`, {}, { headers: { 'Accept': 'image/jpeg' }});

	return {
		chemutils,
		chemutilsSmilesImage,
	};
};

export default Chemutils;
