import {createSlice, PayloadAction} from "@reduxjs/toolkit";

// Type
import { PropsProject } from './type';

interface IInitialState {
	loading?: boolean;
	currentProject: string,
	dataProjects?: PropsProject[];
	loadingCreateProject?: boolean;
	loadingUpdateProject?: boolean;
	loadingDeleteProject?: boolean;
	loadingAddItemToProject?: boolean;
	loadingDeleteItemProject?: boolean;
	loadingMoveItemToProject?: boolean;
}

const localStorageKey: string = 'projects';
const localStorageValue = localStorage.getItem(localStorageKey);
const projects = localStorageValue ? JSON.parse(localStorageValue) : [];

const localStorageValueCurrentProject = localStorage.getItem('currentProject');
const currentProject = localStorageValueCurrentProject ? JSON.parse(localStorageValueCurrentProject) : null;

const initialState: IInitialState = {
	loading: false,
	dataProjects: projects,
	loadingDeleteProject: false,
	loadingCreateProject: false,
	loadingUpdateProject: false,
	loadingAddItemToProject: false,
	loadingDeleteItemProject: false,
	loadingMoveItemToProject: false,
	currentProject: currentProject || '',
};

export const projectsSlice = createSlice({
	name: "projects",
	initialState,
	reducers: {
		handlerChangeCurrentProject: (state, action) => {
			localStorage.setItem('currentProject', ''+action.payload);
			state.currentProject = ''+action.payload;

			const formData = {
				data: action.payload,
				type: "UPDATE_CURRENT_PROJECT",
			};

			localStorage.setItem('synchronizationTab', JSON.stringify(formData));
		},

		getListProjects: (state, action) => {
			if(action.payload !== 'stop-loading') {
				state.loading = true;
			}
		},
		getListProjectsError: (state) => {
			state.loading = false;
		},
		getListProjectsSuccess: (state, action) => {
			state.loading = false;
			state.dataProjects = action.payload;
		},

		clearListProjects: (state) => {
			state.dataProjects = [];
		},

		createProject: (state, action) => {
			state.loadingCreateProject = true;
		},
		createProjectError: (state) => {
			state.loadingCreateProject = false;
		},
		createProjectSuccess: (state) => {
			state.loadingCreateProject = false;
		},

		addItemToProject: (state, action) => {
			state.loadingAddItemToProject = true;
		},
		addItemToProjectError: (state) => {
			state.loadingAddItemToProject = false;
		},
		addItemToProjectSuccess: (state) => {
			state.loadingAddItemToProject = false;
		},

		updateProject: (state, action) => {
			state.loadingCreateProject = true;
		},
		updateProjectError: (state) => {
			state.loadingCreateProject = false;
		},
		updateProjectSuccess: (state, action: PayloadAction<PropsProject[]>) => {
			state.loadingCreateProject = false;
			state.dataProjects = action.payload;
		},

		deleteProject: (state, action) => {
			state.loadingDeleteProject = true;
		},
		deleteProjectError: (state) => {
			state.loadingDeleteProject = false;
		},
		deleteProjectSuccess: (state, action) => {
			state.loadingDeleteProject = false;
			state.dataProjects = action.payload;
		},

		deleteItemProject: (state, action) => {
			state.loadingDeleteItemProject = true;
		},
		deleteItemProjectError: (state) => {
			state.loadingDeleteItemProject = false;
		},
		deleteItemProjectSuccess: (state, action) => {
			state.loadingDeleteItemProject = false;
			state.dataProjects = action.payload;
		},

		moveItemToProject: (state, action) => {
			state.loadingMoveItemToProject = true;
		},
		moveItemToProjectError: (state) => {
			state.loadingMoveItemToProject = false;
		},
		moveItemToProjectSuccess: (state) => {
			state.loadingMoveItemToProject = false;
		},

		handlerCount: (state, action) => {
		},
		handlerCountError: (state, action) => {
		},
		handlerCountSuccess: (state, action) => {
			state.dataProjects = action.payload;
		},

		handlerSelectItem: (state, action) => {
		},
		handlerSelectItemError: () => {
		},
		handlerSelectItemSuccess: (state, action) => {
			state.dataProjects = action.payload;
		},

		handlerChangeColumn: (state, action) => {
			state.dataProjects = action.payload;
		},

		postProject: (state, action) => {},
		postProjectError: () => {},
		postProjectSuccess: () => {},

		orderCopy: (state, action) => {},
		orderCopyError: () => {},
		orderCopySuccess: () => {},
	},
});

export const {
	clearListProjects,

	orderCopy,
	postProject,
	handlerCount,
	createProject,
	updateProject,
	deleteProject,
	orderCopyError,
	getListProjects,
	orderCopySuccess,
	addItemToProject,
	postProjectError,
	handlerSelectItem,
	deleteItemProject,
	handlerCountError,
	moveItemToProject,
	deleteProjectError,
	createProjectError,
	updateProjectError,
	postProjectSuccess,
	handlerCountSuccess,
	handlerChangeColumn,
	getListProjectsError,
	createProjectSuccess,
	updateProjectSuccess,
	deleteProjectSuccess,
	addItemToProjectError,
	handlerSelectItemError,
	moveItemToProjectError,
	deleteItemProjectError,
	getListProjectsSuccess,
	addItemToProjectSuccess,
	moveItemToProjectSuccess,
	deleteItemProjectSuccess,
	handlerSelectItemSuccess,
	handlerChangeCurrentProject,
} = projectsSlice.actions;

export default projectsSlice.reducer;
