import React, {memo} from "react";

// Redirect
import {Link} from "react-router-dom";

// Lib
import {sanitize} from "dompurify";
import {decodeHtmlEntity} from "lib/helpers";

// Type
import {IColumn} from "../type";
import {UnitLibraries} from "pages/libraries/type";

// Components
import Img from "components/Img";

const Column: React.FC<IColumn> = memo((props) => {
	const {
		alt,
		src,
		data,
		title,
		ulClass,
		imgClass,
		linkClass,
		titleClass,
		calcNumber,
		wrapperClass,
		imgArrorClass,
		children = {},
	} = props;

	return (
		<div className={wrapperClass}>
			<img
				src={src}
				alt={alt}
				className={imgClass}
			/>

			<Link to='/libraries'>
				<h4
					className={titleClass}
					dangerouslySetInnerHTML={{__html: sanitize(title || '')}}
				/>
			</Link>

			<ul className={ulClass}>
				{data && data.map((x: UnitLibraries, j: number) => {
					return (
						<Link
							className={linkClass}
							to={`/libraries-product/${x.catNumber}`}
							key={`${x.name}__` + (j * (calcNumber || 0))}
						>
              <span dangerouslySetInnerHTML={{
	              __html: sanitize(decodeHtmlEntity(x.name || '') || '')
              }}/>
							{imgArrorClass && <Img
                alt='Arrow icon'
                name='arrowRight'
                className={imgArrorClass}
              />}

						</Link>
					)
				})}
			</ul>

			{!!Object.keys(children)?.length && <div className={wrapperClass}>
        <img
          src={children.src}
          alt={children.alt}
          className={imgClass}
        />

        <Link to='/libraries'>
          <h4
            className={titleClass}
            dangerouslySetInnerHTML={{__html: sanitize(children.title || '')}}
          />
        </Link>
        <ul className={`${ulClass} mt10`}>
					{!!children?.data && children?.data?.map((x: UnitLibraries, j: number) => {
						return (
							<Link
								className={linkClass}
								to={`/libraries-product/${x.catNumber}`}
								key={`${x.name}__` + (j * (calcNumber || 0))}
							>
		           <span dangerouslySetInnerHTML={{
			           __html: sanitize(decodeHtmlEntity(x.name || '') || '')
		           }}/>
								{imgArrorClass && <Img
	                alt='Arrow icon'
	                name='arrowRight'
	                className={imgArrorClass}
	              />}
							</Link>
						)
					})}
        </ul>
      </div>}
		</div>
	);
});

export default Column;
