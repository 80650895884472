import {createSlice} from "@reduxjs/toolkit";

// Constants
import {optionsTitle} from "constans/request-form";
import {amplitudeInstance, events} from "constans/cookie";

// Lib
import Cookies from "js-cookie";

// Type
import {IInitialState} from "./type";

const formSigIn = {
	role: '',
	user: '',
	token: '',
	userId: '',
	refresh: '',
}

const formSignUp = {
	email: '',
	title: '',
	country: '',
	password: '',
	lastName: '',
	position: '',
	firstName: '',
	companyName: '',
	confirmEmail: '',
	advertConsent: true,
	confirmPassword: '',
}

const initialState: IInitialState = {
	index: 0,
	status: '',
	message: '',
	screen: 'login',
	signIn: formSigIn,
	signUp: formSignUp,
	loadingSingUp: false,
	loadingSingIn: false,
	optionsTitle: optionsTitle,
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		signIn: (state, action) => {
			state.loadingSingIn = true;
		},
		signInError: (state, action) => {
			state.loadingSingIn = false;
			state.status = action?.payload?.status;
			state.message = action?.payload?.data?.error;

			const isCookie = Cookies.get('cookieName');
			if (isCookie) {
				amplitudeInstance.logEvent(events.login_failure);
			}
		},
		signInSuccess: (state, action) => {
			state.loadingSingIn = false;

			const formData = {
				data: action.payload,
				type: "UPDATE_SIGN_IN",
			};

			localStorage.setItem('synchronizationTab', JSON.stringify(formData));

			const isCookie = Cookies.get('cookieName');
			if (isCookie) {
				amplitudeInstance.logEvent(events.login_success);
			}
		},

		singUp: (state, action) => {
			state.loadingSingUp = true;
			const isCookie = Cookies.get('cookieName');
			if (isCookie) {
				amplitudeInstance.logEvent(events.sign_up, {start: true});
			}
		},
		singUpError: (state, action) => {
			state.loadingSingUp = false;
			state.message = action.payload;
			const isCookie = Cookies.get('cookieName');
			if (isCookie) {
				amplitudeInstance.logEvent(events.sign_up, {complete: false});
			}
		},
		singUpSuccess: (state) => {
			state.loadingSingUp = false;
			state.index = 0;
			const isCookie = Cookies.get('cookieName');
			if (isCookie) {
				amplitudeInstance.logEvent(events.sign_up, {complete: true});
			}
		},

		clearMessage: (state) => {
			state.message = '';
			state.status = '';
		},
	},
});

export const {
	signIn,
	singUp,
	singUpError,
	signInError,
	clearMessage,
	signInSuccess,
	singUpSuccess,
} = authSlice.actions;

export default authSlice.reducer
