import React, {useEffect} from "react";

// Images
import SuccessImg from 'assets/img/success.png';

// Lib
import {sanitize} from 'dompurify';
import {useAppDispatch} from "lib/hooks/useDispatch";

// Components
import Button from "../ui-kit/button";

// Actions
import {modalClose} from "reducers/modal";

import {NavigateFunction} from "react-router-dom";

type PropsType = {
	modalProps?: UnitType
};

type UnitType = {
	text?: string,
	title?: string,
	subTitle?: string,
	actionClose?: any,
	navigate?: any,
};

const SuccessModal: React.FC<PropsType> = (props) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		document.body.classList.add('wrapper-success');

		return () => document.body.classList.remove('wrapper-success');
	}, []);

	const handlerOnPress = () => {
		dispatch(modalClose());
		const timeout = setTimeout(() => {
			props?.modalProps?.navigate('/auth?tab=sign-in');
		}, 0);

		clearTimeout(timeout)
	};

	return <div className='success-modal flex flex-jc-center flex-ai-center flex-direction-column'>
		<img src={SuccessImg} alt="Success image" className='success-modal__img'/>
		{!!props?.modalProps?.title &&
      <h1 className='success-modal__title' dangerouslySetInnerHTML={{__html: sanitize(props.modalProps.title || '')}}/>}
		{!!props?.modalProps?.subTitle && <p className='success-modal__sub-title'
                                         dangerouslySetInnerHTML={{__html: sanitize(props?.modalProps.subTitle || '')}}/>}
		{!!props?.modalProps?.text &&
      <p className='success-modal__text' dangerouslySetInnerHTML={{__html: sanitize(props?.modalProps.text || '')}}/>}

		{!!props?.modalProps?.navigate && <Button label='Log In' variable='info' onPress={handlerOnPress}/>}
	</div>;
};

export default SuccessModal;
