import React from 'react';

// Lib
import {sanitize} from 'dompurify';

// Clsx
import clsx from 'clsx';

// Components
import Button from 'components/ui-kit/button';

const Confirm: React.FC<any> = (props) => {
	const {modalProps, handlerClose} = props;
	const {
		data,
		text,
		title,
		color,
		textClose,
		innerClass,
		textSubmit,
		actionSubmit,
		innerCallback,
		btnClose = false,
		hiddenTitle = false,
		isCallActionSubmitOnClose = false,
	} = modalProps;

	const handlerSubmit = () => {
		if (data) {
			actionSubmit && actionSubmit(data);
		} else {
			actionSubmit && actionSubmit();
		}
	};

	const handlerClick = () => {
		handlerClose();

		handlerSubmit();
	};

	const handlerCloseModal = () => {
		handlerClose();

		if(isCallActionSubmitOnClose) {
			handlerSubmit()
		}

		if(innerCallback) {
			innerCallback();
		}
	};

	const parseText = (text: string) => {
		return <span dangerouslySetInnerHTML={{__html: sanitize(text)}}/>;
	};

	const cn = clsx(
		'confirm flex flex-direction-column flex-ai-center flex-jc-center',
		innerClass ? innerClass : '',
	);

	return (
		<div className={cn}>
			{!hiddenTitle && <h4 className="confirm__title mb10">
				{title ? parseText(title) : 'Are you sure?'}
			</h4>}

			{!!text && <p className="confirm__text mb10">{parseText(text) || ''}</p>}

			<div className="flex flex-jc-center">
				{btnClose ? (
					<Button variable="outline" className="mr10" onPress={handlerClose}>
						{textClose ? textClose : 'Close'}
					</Button>
				) : (
					<>
						<Button
							color={color}
							variable="outline"
							className="mr10"
							onPress={handlerCloseModal}
						>
							{textClose ? textClose : 'Close'}
						</Button>
						<Button
							variable="outline"
							onPress={handlerClick}
						>
							{textSubmit ? textSubmit : 'Submit'}
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

export default Confirm;
