import {StylesConfig} from 'react-select';

// Interface
export interface ColourOption {
	readonly value: string;
	readonly label: string;
	readonly color: string;
	readonly isFixed?: boolean;
	readonly isDisabled?: boolean;
}

const selectStyle: StylesConfig<ColourOption> = {
	indicatorsContainer: (provided: any, state: any) => ({
		...provided,
		boxShadow: 'none',
		borderColor: 'none',
		transition: "all .2s ease",
		transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0deg)"
	}),
	dropdownIndicator: (provided: any) => ({
		...provided,
		color: "#2F3533",
	}),
	clearIndicator: (provided: any) => ({
		...provided,
		cursor: 'pointer',
	}),
	valueContainer: (provided: any) => ({
		...provided,
		'&:hover, &:focus': {
			boxShadow: 'none',
			borderColor: 'none',
		},
		color: '#060606',
		fontWeight: '500',
		fontSize: '1.4rem',
		fontStyle: 'normal',
		lineHeight: '2.4rem',
		letterSpacing: '0.8px',
		fontFamily: 'Poppins',

		"@media only screen and (max-width: 1024px)": {
			...provided["@media only screen and (max-width: 1024px)"],
			maxWidth: "100%",
		},
	}),
	menu: (provided: any, state: any) => ({
		...provided,
		'&:hover, &:focus, &:active': {
			boxShadow: 'none',
			borderColor: 'unset',
			backgroundColor: '#fff',
		},

		color: '#060606',
		fontWeight: '500',
		fontSize: '1.4rem',
		fontStyle: 'normal',
		lineHeight: '2.4rem',
		letterSpacing: '0.8px',
		backgroundColor: '#fff',
		fontFamily: 'Poppins',
	}),
	option: (provided: any, state: any) => ({
		...provided,
		'&:hover, &:focus, &:active': {
			boxShadow: 'none',
			borderColor: 'none',
			backgroundColor: '#F2F2F2',
		},

		margin: '0 auto',
		borderRadius: '4px',
		padding: '10px 8px',
		width: 'calc(100% - 8px)',

		color: '#060606',
		fontWeight: '500',
		cursor: 'pointer',
		fontSize: '1.4rem',
		fontStyle: 'normal',
		lineHeight: '2.4rem',
		letterSpacing: '0.8px',
		fontFamily: 'Poppins',
		backgroundColor: 'transparent',
	}),
	container: (provided: any, state: any) => ({
		...provided,
		'&:hover, &:focus': {
			boxShadow: 'none',
			borderColor: 'none',
		},
		width: '100%',
		height: '4.4rem',
		color: '#060606',
		maxWidth: '40rem',
		fontWeight: '500',
		fontSize: '1.4rem',
		fontStyle: 'normal',
		minHeight: '4.4rem',
		lineHeight: '2.4rem',
		letterSpacing: '0.8px',
		fontFamily: 'Poppins',

		"@media only screen and (max-width: 1024px)": {
			...provided["@media only screen and (max-width: 1024px)"],
			maxWidth: "100%",
		},
	}),
	control: (provided: any, state: any) => ({
		...provided,
		'&:hover, &:focus': {
			boxShadow: 'none',
			borderColor: 'unset',
		},
		width: '100%',
		border: 'unset',
		height: '4.4rem',
		minHeight: '4.4rem',
		color: '#060606',
		boxShadow: 'none',
		maxWidth: '40rem',
		cursor: 'pointer',
		fontWeight: '500',
		fontSize: '1.4rem',
		fontStyle: 'normal',
		borderStyle: 'unset',
		lineHeight: '2.4rem',
		borderRadius: 'unset',
		letterSpacing: '0.8px',
		fontFamily: 'Poppins',
		backgroundColor: 'transparent',
		borderBottom: '1px solid rgba(6, 6, 6, 0.25)',

		"@media only screen and (max-width: 1024px)": {
			...provided["@media only screen and (max-width: 1024px)"],
			maxWidth: "100%",
		},
	}),
	placeholder: (provided: any) => ({
		...provided,
		outline: 'none',
		'&:hover, &:focus': {
			boxShadow: 'none',
			borderColor: 'none',
		},
		color: '#9B9B9B',
		fontWeight: '400',
		fontSize: '1.4rem',
		paddingLeft: '0px',
		fontStyle: 'normal',
		lineHeight: '2.4rem',
		letterSpacing: '0.8px',
		fontFamily: 'Poppins',
		backgroundColor: 'transparent',
	}),
	indicatorSeparator: (provided: any, state: any) => ({
		...provided,
		width: 0,
		marginRight: '2px',
	}),
};

export {
	selectStyle,
};
