import React from "react";

// Classnames
import clsx from "clsx";

// Lib
import {compareSort} from "../helpers";

// Type
import {TBaData, TSelectors} from "pages/thesaurus/reducer";
type TTitle = {
	name?: string,
	navKey?: string,
	targetId?: number,
	synonyms?: string,
	className: string,
	familyId?: string,
	targetCount?: number,
	matchSearch?: boolean,
}
type TStyle = {
	[index: string]: string
}
type TSortedArray = {
	data?: any,
	isAlphabet?: boolean,
};

const arrowPath = 'M0.664062 0.666504L3.9974 3.99984L7.33073 0.666504H0.664062Z';

const getSvgIcon = (
	path: string,
	iStyle: TStyle = {},
	style: TStyle = {},
) => {
	return (
		<svg className="tree__wrapper-arrow-svg" width="8" height="4" viewBox="0 0 8 4"
		     style={{verticalAlign: '-.125em', ...style}} fill="none"
		     xmlns="http://www.w3.org/2000/svg">
			<path d={path} fill="#191919"/>
		</svg>
	)
};

export const tableSymbol = [
	{ key: '&Fnof;', convertKey: '&#402;' },
	{ key: '&Alpha;', convertKey: '&#913;' },
	{ key: '&Beta;', convertKey: '&#914;' },
	{ key: '&Gamma;', convertKey: '&#915;' },
	{ key: '&Delta;', convertKey: '&#916;' },
	{ key: '&Epsilon;', convertKey: '&#917;' },
	{ key: '&Zeta;', convertKey: '&#918;' },
	{ key: '&Theta;', convertKey: '&#920;' },
	{ key: '&Iota;', convertKey: '&#921;' },
	{ key: '&Kappa;', convertKey: '&#922;' },
	{ key: '&Lambda;', convertKey: '&#923;' },
	{ key: '&Mu;', convertKey: '&#924;' },
	{ key: '&Nu;', convertKey: '&#925;' },
	{ key: '&Xi;', convertKey: '&#926;' },
	{ key: '&Pi;', convertKey: '&#928;' },
	{ key: '&Rho;', convertKey: '&#929;' },
	{ key: '&Sigma;', convertKey: '&#931;' },
	{ key: '&Tau;', convertKey: '&#932;' },
	{ key: '&Upsilon;', convertKey: '&#933;' },
	{ key: '&Phi;', convertKey: '&#934;' },
	{ key: '&Chi;', convertKey: '&#935;' },
	{ key: '&Psi;', convertKey: '&#936;' },
	{ key: '&Omega;', convertKey: '&#937;' },
	{ key: '&alpha;', convertKey: '&#945;' },
	{ key: '&beta;', convertKey: '&#946;' },
	{ key: '&gamma;', convertKey: '&#947;' },
	{ key: '&delta;', convertKey: '&#948;' },
	{ key: '&epsilon;', convertKey: '&#949;' },
	{ key: '&zeta;', convertKey: '&#950;' },
	{ key: '&eta;', convertKey: '&#951;' },
	{ key: '&theta;', convertKey: '&#952;' },
	{ key: '&iota;', convertKey: '&#953;' },
	{ key: '&kappa;', convertKey: '&#954;' },
	{ key: '&lambda;', convertKey: '&#955;' },
	{ key: '&mu;', convertKey: '&#956;' },
	{ key: '&nu;', convertKey: '&#957;' },
	{ key: '&xi;', convertKey: '&#958;' },
	{ key: '&omicron;', convertKey: '&#959;' },
	{ key: '&pi;', convertKey: '&#960;' },
	{ key: '&rho;', convertKey: '&#961;' },
	{ key: '&sigmaf;', convertKey: '&#962;' },
	{ key: '&sigma;', convertKey: '&#963;' },
	{ key: '&tau;', convertKey: '&#964;' },
	{ key: '&upsilon;', convertKey: '&#965;' },
	{ key: '&phi;', convertKey: '&#966;' },
	{ key: '&chi;', convertKey: '&#967;' },
	{ key: '&psi;', convertKey: '&#968;' },
	{ key: '&omega;', convertKey: '&#969;' },
	{ key: '&thetasy;', convertKey: '&#977;' },
	{ key: '&upsih;', convertKey: '&#978;' },
	{ key: '&piv;', convertKey: '&#982;' },
];

/**
 * Encode UTF8 to html entity
 * @param {string} text - object option
 * @returns string
 */
export const encodeUTF8toHtmlEntity = (text: any) => {
	return text?.length && text.replace(/[\u00A0-\u9999<>\&]/g, function (i: string) {
		return '&#' + i.charCodeAt(0) + ';';
	});
};

export const switcherIcon = (obj: any) => {
	const customStyle = {
		width: '8px',
		height: '4px',
	}
	if (obj.isLeaf) {
		return getSvgIcon(
			arrowPath,
			{cursor: 'pointer', backgroundColor: 'transparant', ...customStyle},
			{display: 'none', transform: 'translate(-50%, -50%) rotate(270deg)', ...customStyle},
		);
	}
	return getSvgIcon(
		arrowPath,
		{cursor: 'pointer', backgroundColor: 'transparant', ...customStyle},
		{transform: `translate(-50%, -50%) rotate(-${obj.expanded ? 90 : 0}deg)`, ...customStyle},
	);
};

const getTitle = (item: TTitle) => {
	const cn = clsx(
		'tree__family-text flex flex-ai-center',
		item.className === 'leaf' ? 'tree__family-text--active' : '',
	);
	return <p
		className={cn}
		family-id={item?.familyId || ''}
		dangerouslySetInnerHTML={{__html: `${item?.name}${item?.targetCount ? ` <span class="ml5">(${item?.targetCount} items)</span>` : ''}` || ''}}
	/>
};

const caclTargets = (data: any) => {
	return data &&
	data.length
		? data?.filter((item: any) => item.className === 'leaf').length
		: '';
};

export const getExpand = (result: any, item: any) => {
	let key = 'key';

	const splitItem = item?.split('_');

	splitItem.forEach((segment: string, i: number) => {
		if (i > 0) {
			key += '_' + segment;
			!result.includes(key) && result.push(key);
		}
	});

	return result;
};

export const checkElement = (element: any, searchValue: string, isParentFind = false) => {
	const text = document.createElement('div');

	text.innerHTML = element?.name || '';
	const name = text?.innerText || '';

	let arrElem = [];
	let isFind = isParentFind || (!!name && name?.toLowerCase().includes(searchValue.toLowerCase()));

	if (element.children && element.children.length) {
		if (isFind) {
			arrElem = element.children;
		} else {
			arrElem = element.children.reduce((result: any, item: any) => {
				const arr = checkElement(item, searchValue, isFind);
				if (arr) {
					result.push(arr);
				}

				return result;
			}, []);
		}
	}

	if (isFind) {
		return { ...element, children: arrElem };
	}

	if (arrElem.length) {
		return { ...element, children: arrElem };
	}

	return false;
};

export const findElement = (data: any, searchValue: string) => {
	return data.reduce((result: any, item: any) => {
		const val = checkElement(item, searchValue);

		if (val) {
			result.push(val);
		}

		return result;
	}, []);
};

export const listTree = (props: any) => {
	const {
		key,
		data,
		targetBox,
		dynamicKeys,
		checkedKeys,
		inputValue = '',
		targetCount = ''
	} = props;

	return (
		(data?.length &&
			data.map((item: any, i: number) => {
				const text = document.createElement('div');

				text.innerHTML = item?.name || '';
				const name = text.innerText;

				text.innerHTML = item?.synonyms || '';
				const synonyms = text.innerText;

				if (
					inputValue &&
					filterFn(name, synonyms, inputValue)
				) {
					dynamicKeys.push(`${key}_${i}`);
				}

				if (
					item.children &&
					item.children.length !== 0
				) {
					return {
						extra: item,
						key: key + '_' + i,
						navKey: item.navKey,
						value: key + '_' + i,
						showLabel: item?.name,
						title: getTitle({
							...item,
							targetCount: caclTargets(item.children),
						}),
						filterName: name ? name : '',
						filterSyn: synonyms ? synonyms : '',
						targetCount: caclTargets(item.children),
						children: listTree(
							{
								key: key + '_' + i,
								data: item.children,
								targetBox: targetBox,
								inputValue: inputValue,
								dynamicKeys: dynamicKeys,
								checkedKeys: checkedKeys,
								targetCount: targetCount,
							}
						),
					};
				}

				if (
					targetBox &&
					targetBox.find((elem: any) => elem.name === item.name)
				) {
					checkedKeys?.push(key + '_' + i);
				}

				return {
					extra: item,
					key: key + '_' + i,
					navKey: item.navKey,
					value: key + '_' + i,
					title: getTitle(item),
					showLabel: item?.name,
					targetCount: targetCount,
					filterName: name ? name : '',
					isLeaf: item.className === 'leaf',
					filterSyn: synonyms ? synonyms : '',
				};
			})) ||
		[]
	);
};

export const messageNotEmptyValue = {
	type: 'info',
	// text: 'Value shouldn\'t be null',
	text: 'Enter text for search Name, EBC ID, CAS, etc.'
}

export const sortedArray = ({data = [], isAlphabet = false}: TSortedArray) =>
	isAlphabet
		? data?.sort(compareSort('value'))
		: data?.sort((a: any, b: any) => +a.value - +b?.value);

export const replacePx = (str: string) => str.replace(/[^\d|.]/g, '');

export const isObjectEqual = (obj1: any, obj2: any, key: string) => obj1[key] === obj2[key];

export const getClearedArrayDublicate = (data: TSelectors[], key: string) => data?.filter((item: TSelectors, index: number, array: any) =>
	array.findIndex((i: number) => isObjectEqual(i, item, key)) === index
);

export const convertDataByKey = (data: TBaData[] | string[], key: string) => {
	return data.map((item: any) => ({
		value: key === 'tarPx' ? replacePx(item[key]) : item[key],
		label: key === 'tarPx' ? `${replacePx(item[key])} px` : item[key],
	}));
};

const availabilityConfig: { [index: string]: boolean } = {
	'ALL': true,
	'ON_STOCK_POA': true,
	'ON_STOCK': true,
	'OUT_OF_STOCK': false,
};

export const filteredDBData = (args: {
	tarAction?: string,
	availability?: string,
	tarPx?: number | string,
}) => (item: TBaData) => {
	const {tarPx, tarAction, availability} = args;
	// const isTarAction: boolean = tarAction ? item?.tarAction === tarAction : true;
	const isTarAction: boolean = tarAction ? !!item?.tarAction?.includes(tarAction) : true;
	const isPOA = availability === 'ON_STOCK_POA' ? (item?.onStock && !!item?.isPOA) : item?.onStock;
	const isAvailability: boolean = availability ? isPOA === availabilityConfig[availability] : true;
	const isTarPx: boolean | undefined = tarPx ? item?.px?.some((number: string) => +number >= +tarPx) : true;

	return isTarPx && isTarAction && isAvailability;
};

export const filterFn = (name: string, synonyms: string, inputValue: string) => {
	let resultVal;
	let resultValSyn;

	const findElem = tableSymbol.filter(
		(item) => name && name.includes(item.key)
	);
	const findElemSyn = tableSymbol.filter(
		(item) => synonyms && synonyms.includes(item.key)
	);

	if (findElem) {
		resultVal = name && name.replace(findElem[0]?.key, findElem[0]?.convertKey);
	}

	if (findElemSyn) {
		resultValSyn =
			synonyms &&
			synonyms.replace(findElemSyn[0]?.key, findElemSyn[0]?.convertKey);
	}

	const inputLowerCase = inputValue && inputValue.toLowerCase();
	const resultValLoweCase = resultVal && resultVal.toLowerCase();
	const resultSynValLoweCase = resultValSyn && resultValSyn.toLowerCase();

	return !!((inputLowerCase &&
			resultValLoweCase &&
			resultValLoweCase?.indexOf(encodeUTF8toHtmlEntity(inputLowerCase)) >
			-1) ||
		(inputLowerCase &&
			resultSynValLoweCase &&
			resultSynValLoweCase?.indexOf(encodeUTF8toHtmlEntity(inputLowerCase)) >
			-1));
};