// Lib
import Excel from 'exceljs';
import FileSaver from 'file-saver';

import {PropsProject} from "pages/projects/type";

// Config
import config from "lib/config";

// Type
type PropsAddresses = {
	[index: string]: string | number | boolean;
}

export const patternRxpCheckEng = /^[A-Za-z0-9 !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/;

export const isEngLang = (value: string) => {
	let isEng: boolean = false;
	if (!value) {
		isEng = true;
	} else {
		isEng = patternRxpCheckEng.test(value);
	}

	return isEng;
};

export const filteredDataByKey = ({data, key}: {data?: string[], key?: string}) => {
	return data?.length ? data.filter((item: string) => item === key) : [];
};

export const hexEncode = (
	instr: string,
	withoutZiro: boolean = false
) => {
	let hex, i;
	let result = '';

	if (instr) {
		for (i = 0; i < instr.length; i++) {
			hex = instr.charCodeAt(i).toString(16);
			result += (!withoutZiro ? '000' : '' + hex).slice(-4);
		}
	}

	return result;
};

export const decodeHtmlEntity = (text: string) => {
	if (text && typeof text === 'string') {
		return text.replace(/&#(\d+);/g, function (match: string, dec) {
			return String.fromCharCode(dec);
		});
	} else {
		return text
	}
};

export const percentage = (partialValue: number, maxValue: number) =>
	(100 * partialValue) / maxValue;

export function generateUserId(): string {
	const timestamp = Date.now().toString();
	const randomString = Math.random().toString(36).substring(2, 8);
	return `${timestamp}-${randomString}`;
}

export const compareSort = (keyName: string = '') => (a: any, b: any) => {
	const nameA = a[keyName]?.replace(/(<([^>]+)>)/gi, "")?.toUpperCase(); // ignore upper and lowercase
	const nameB = b[keyName]?.replace(/(<([^>]+)>)/gi, "")?.toUpperCase(); // ignore upper and lowercase

	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	// names must be equal
	return 0;
};

/**
 * Return formated amount.
 *
 * @param {String} amount - amount
 * @param {String} currency - currency symbol (optional)
 * @param {number} minimumFractionDigits - count minimum digits ni number
 * @returns {String} - formated string
 */
export function amountFormat(amount: number, currency: string = 'usd', minimumFractionDigits: number = 2) {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: currency.toUpperCase(),
		minimumFractionDigits: minimumFractionDigits,
	});
	return formatter.format(amount);
}

export function numberFormat(props: {
	number: number;
	divisor: number;
	minimumFractionDigits: number
}) {
	const {
		number,
		divisor = 1000,
		minimumFractionDigits = 2
	} = props;
	const formatter = new Intl.NumberFormat('en-US', {
		minimumFractionDigits: minimumFractionDigits,
	});
	return formatter.format(number / divisor);
}

export const isNumber = (n: any) => {
	return !isNaN(parseFloat(n)) && !isNaN(n - 0);
};

/**
 * Return convert to array.
 *
 * @returns {array}
 * @param data
 */
export const convertedToArray = (data: any) => Array.isArray(data)
	? data
	: Object.values(data)

/**
 * Return count item in current project.
 *
 * @returns {number} - count in current project
 * @param data
 * @param currentProject
 */
export const calcCountItems = (data: any, currentProject: number) => {
	if(!currentProject) return 0;

	const convertedProjects = Array.isArray(data) ? data : convertedToArray(data);

	const findCurrProject = convertedProjects.find(
		(project: PropsProject) => project?.id === currentProject
	);

	if(!findCurrProject) return 0;

	const projectItemLen = findCurrProject.items.filter(
		(item: any) => item.selected
	).filter(Boolean);

	return projectItemLen.length;
};

/**
 * Return formated amount.
 *
 * @param {String} inputDate - date
 * @returns {String} - formated date string
 */
export function formatDate(inputDate?: string) {
	const date = new Date(inputDate || '');

	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');
	const seconds = date.getSeconds().toString().padStart(2, '0');

	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Month is 0-based

	const year = date.getFullYear();

	return `${hours}:${minutes}:${seconds}, ${day}.${month}.${year}`;
}

/**
 * Convert BASE64 to BLOB
 * @param base64Image Pass Base64 image data to convert into the BLOB
 */
export const convertBase64ToBlob = (base64Image: string) => {
	// Split into two parts
	const parts = base64Image.split(';base64,');

	// Hold the content type
	const imageType = parts[0].split(':')[1];

	// Decode Base64 string
	const decodedData = window.atob(parts[1]);

	// Create UNIT8ARRAY of size same as row data length
	const uInt8Array = new Uint8Array(decodedData.length);

	// Insert all character code into uInt8Array
	for (let i = 0; i < decodedData.length; ++i) {
		uInt8Array[i] = decodedData.charCodeAt(i);
	}

	// Return BLOB image after conversion
	return new Blob([uInt8Array], {type: imageType});
}

/**
 * Convert data addresses by type
 * @param data
 * @param type
 */
export const convertDataAddresses = (data: PropsAddresses[], type: string) =>
	data.length
		? data.filter((x: PropsAddresses) => x?.type === type)
		: [];

/**
 * Convert data addresses by type
 * @param data
 * @param type
 * @param key
 */
export const foundDataAddresses = (data: PropsAddresses[], key: string, type: string | number) =>
	data.length ? data.find((item: PropsAddresses) => item[key] === type) : {};

/**
 * Convert data prices
 * @param data
 */
export const convertDataPrices = (data: any) => {
	return data.length ? data.map((item: any) => ({
		mg: item.mg,
		available: item.available,
		value: `price${item.mg}mg`,
		priceUsd: item?.priceUsd ? item?.priceUsd : null,
		priceEur: item?.priceEur ? item?.priceEur : null,
	})) : [];
};

/**
 * Convert img
 * @param {string} item
 * @param {object} param
 * @returns {String} - String img
 */
export const getImg = (
	item: string,
	param?: { [index: string]: string | number }
) => {
	let src = '';

	if (item) {
		const smilesHex = hexEncode(item, true);

		src = `${
			config.api.apiUrl
		}chemutils/smiles-image?smiles=${smilesHex}&format=png:w${param?.w || 90},h${
			param?.h || 90
		},noantialias,H_hetero`;
	}

	return src;
};

/**
 * Convert array prices
 * @param {array} data - prices array
 * @returns {array} - New array
 */
export const filteredDataPrices = (data: { [index: string]: string | number | boolean }[]) => {
	return data?.length ? data?.filter(
		(x: { [index: string]: string | number | boolean }) => x.mg !== 15 && x.mg !== 100
	) : []
};

/**
 * Convert projects array to options
 * @param {array} data - Data projects array
 * @returns {array} - New array convert options
 */
export const convertProjectsToOptions = (data: any) => data?.length ? data?.map(
	(x: any) => ({label: x?.name, value: x?.id})
) : [];

/**
 * Convert options prices
 * @param {object} item - object option
 * @returns {object} - new format option
 */
export const optionsPrices = (item: { [index: string]: string | number | boolean }) => {
	return {
		label: `$${item.priceUsd}/${item.mg}mg`,
		value: item.priceUsd,
		mg: item.mg,
	}
};

export const truncate = (str: string, max: number) => {
	return str.length > max ? str.substring(0, max) + '...' : str;
};

export const generateXLSTableSave = async (args: {data: any, headers: any, cb?: any}) => {
	const {data, headers, cb} = args;

	const workbook = new Excel.Workbook();
	const worksheet = workbook.addWorksheet('EBC', {
		properties: { defaultRowHeight: 15 },
		pageSetup: {
			paperSize: 9,
			orientation: 'portrait',
			fitToPage: true,
			fitToWidth: 1,
			fitToHeight: 0,
		},
		views: [{ zoomScale: 85, zoomScaleNormal: 100 }],
	});
	const columns = [];

	for (let i = 0; i < headers.length; i++) {
		const name = headers[i].data;
		columns.push({ header: name, key: name, width: 32 });
	}

	worksheet.columns = columns;
	worksheet.getRow(1).font = { size: 14, bold: true };
	const totalRecords = data.length;
	const s = document.createElement('p');

	for (let n = 0; n < data.length; n++) {
		const row = n + 2;
		let element = {};
		for (let i = 0; i < headers.length; i++) {
			const name = headers[i].data;
			s.innerHTML = data[n][headers[i].data] ? decodeHtmlEntity(data[n][headers[i].data]) : '';
			// @ts-ignore
			element[name] = s.textContent;
		}

		worksheet.addRow(element);
		const w = Math.floor(((n + 1) * 100) / totalRecords);
		cb && cb(w);

		worksheet.getRow(row).height = 20;
		worksheet.getRow(row).font = { size: 14 };
		worksheet.getRow(row).alignment = { wrapText: true };
	}

	const date = formatDate(new Date().toString());

	workbook.xlsx
		.writeBuffer()
		.then((buffer) =>
			FileSaver.saveAs(new Blob([buffer]), `${date}_POA.xlsx`)
		)
		.catch((err: any) => console.log('Error writing excel export', err));
};