import { createSlice } from "@reduxjs/toolkit";

// Type
interface IInitialState {
  message?: string;
  loading?: boolean;
  status?: number | null,
};

const initialState: IInitialState = {
  status: null,
  message: '',
  loading: false,
};

export const forgotePasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    sendForgotPassword: (state, action) => {
      state.loading = true;
    },
    sendForgotPasswordError: (state, action) => {
      state.loading = false;
      state.message = action.payload.data;
      state.status = action.payload.status;
    },
    sendForgotPasswordSuccess: (state) => {
      state.loading = false;
    },
  },
});

export const {
  sendForgotPassword,
  sendForgotPasswordError,
  sendForgotPasswordSuccess,
} = forgotePasswordSlice.actions;

export default forgotePasswordSlice.reducer;
