const apiUrl: string = process.env.REACT_APP_API_BASE || '';

const config = {
  defaultLang: "en",

  api: {
    apiUrl: apiUrl,
    apiAuth: "cGZwLXdlYjp0ZXN0Q2xpZW50U2VjcmV0",
    timeout: 30000,
    addHeaders: {},
  },
  oauth: {
    response_type: "token",
    client_id: "devconsole",
    state: "xyz",
    redirect_uri: "",
    scope: "ALL",
  },
};

export default config;
