import {ApisauceInstance} from 'apisauce';

// Url
import {url} from '../api.url';

const drugImpurityEbc = (api: ApisauceInstance) => {
	const drugImpurity = (id: string) => api.get(`${url.drugImpurity}/${id}`);

	return {
		drugImpurity
	};
};

export default drugImpurityEbc;
