import React, { useEffect } from "react";

// Redux connect
import {
  useAppDispatch,
} from "../../lib/hooks/useDispatch";

// Components
import Button from "../ui-kit/button";
import Loader from "../ui-kit/loader";

// Actions
import * as actionsCreator from 'reducers/modal';

const RedirectModal: React.FC<any> = (props) => {
  const { modalProps } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    const timerRedirect = setTimeout(() => {
      const link = document.createElement('a');

      link.href = modalProps.url;
      link.target = '_blank';
      link.click();

      handlerClick();
    }, 3000);

    return () => clearTimeout(timerRedirect);
  }, [modalProps.url]);

  const handlerClick = () => {
    dispatch(actionsCreator.modalClose());
  };

  return <div className='redirect-modal flex flex-ai-center flex-jc-center flex-direction-column'>
    <Loader type='small' />
    <p>Please wait, you will be redirected to the Enamine website</p>
    <Button
      label='Cancel'
      variable='outline'
      onPress={handlerClick}
    />
  </div>;
};

export default RedirectModal;
