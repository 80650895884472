import {ApisauceInstance} from 'apisauce';

// Url
import {url} from '../api.url';

// Type
type FormDataProps = {
	[index: string]: string | number | boolean
};

const addressApi = (api: ApisauceInstance) => {
	const getAllAddresses = () => api.get(url.addresses);
	const deleteAddressesById = (id: number) => api.delete(`${url.addresses}/${id}`);
	const sendAddresses = (formData: FormDataProps) => api.post(url.addresses, formData || {});
	const updateAddresses = (formData: FormDataProps, id: number) => api.put(`${url.addresses}/${id}`, formData || {});

	return {
		sendAddresses,
		getAllAddresses,
		updateAddresses,
		deleteAddressesById,
	};
};

export default addressApi;
