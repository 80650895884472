import React from 'react';

// Classnames
import clsx from 'clsx';

// Type
type TUnitArray = {
	id?: number,
	label?: string,
	children?: JSX.Element,
	value?: string | number,
}

interface IFieldRadio {
	id?: string,
	errors?: any,
	name?: string,
	label?: string,
	register?: any,
	subLabel?: string,
	msgError?: string,
	isError?: boolean,
	className?: string,
	disabled?: boolean,
	isColumn?: boolean,
	options?: TUnitArray[],
	placeholder?: string,
	children?: JSX.Element,
	value?: string | number,
	classNameLabel?: string,
	simpleRadioBtn?: boolean,
	classNameWrapper?: string,
	selectedValue?: string | number,
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export const FieldGroup: React.FC<IFieldRadio> = (props) => {
	const {
		name,
		label,
		options,
		isError,
		msgError,
		register,
		isColumn,
		disabled,
		onChange,
		className,
		classNameLabel,
		simpleRadioBtn,
		classNameWrapper
	} = props;

	const cnMainWrapper = clsx(
		'field-radio flex full-width',
		classNameWrapper ? classNameWrapper : '',
		isColumn ? 'field-radio__column flex-direction-column' : '',
		simpleRadioBtn ? label ? 'field-radio__pl' : '' : 'full-width field-radio__pl',
	);

	const renderElement = (item: TUnitArray) => {
		const cnText = clsx(
			'flex flex-ai-center field-radio__wrapper',
			className ? className : ''
		);
		const cnLabel = clsx(
			'relative',
			classNameLabel ? classNameLabel : '',
		);
		const cnBorderWrapper = clsx(
			'flex flex-jc-between',
			isColumn ? 'flex-ai-start flex-direction-column' : 'flex-ai-center'
		);

		const uniqId = crypto.randomUUID();

		return (<React.Fragment key={item.value}>
			<div className={cnBorderWrapper}>
				<p className={cnText}>
					<input
						{...register}

						name={name}
						type="radio"
						id={uniqId}
						value={item.value}
						disabled={disabled}
						className="field-radio__input"
						onChange={onChange ? onChange : {}}
					/>
					<label
						htmlFor={uniqId}
						className={cnLabel}
					>
						{item.label || ''}
					</label>
				</p>
				{item.children}
			</div>
			{isError && <div className="field-radio__hint-radio">
				{msgError}
      </div>}
		</React.Fragment>);
	};

	return (<div className={cnMainWrapper}>
		{options?.map(
			(item) => renderElement(item)
		)}
	</div>);
};

export default FieldGroup;
