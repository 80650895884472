import {createSlice} from "@reduxjs/toolkit";

// Interface
import {IInitialState, TypeForm} from './type';

// Constants
import {
	format,
	optionsTitle,
	radioBlock,
	radioBlock2,
	radioBlock3,
	waterOptions,
	packagingGroup,
} from 'constans/request-form';

const auth = JSON.parse(localStorage.getItem('auth') || '{}');

const initialState: IInitialState = {
	form: {
		target: '',
		format: '',
		setSize: '',
		inquiry: '',
		solution: '',
		selectivity: '',
		allCompounds: '',
		activityRange: '',
		compoundsPurity: '',
		email: auth?.email || '',
		lastName: auth?.lastName || '',
		firstName: auth?.firstName || '',
		companyName: auth?.organization || '',
	},
	status: '',
	message: '',
	loading: false,
	format: format,
	radioBlock: radioBlock,
	radioBlock2: radioBlock2,
	radioBlock3: radioBlock3,
	optionsTitle: optionsTitle,
	waterOptions: waterOptions,
	packagingGroup: packagingGroup,
};

export const requestFormSlice = createSlice({
	name: "requestForm",
	initialState,
	reducers: {
		sendForm: (state, action) => {
			state.loading = true;
		},
		sendFormError: (state, action) => {
			const payload = action?.payload;
			const message = typeof payload.data === 'object'
				? payload.data?.error
				: payload.data;
			const status = payload?.data.status
				? payload?.data.status
				: payload.status;

			state.loading = false;
			state.status = status;
			state.message = message;
		},
		sendFormSuccess: (state, action) => {
			state.loading = false;
			state.message = action.payload.data;
			state.status = action.payload.status;
		},

		// handlerChangeForm: (state, action) => {
		// 	const name: string = action?.payload?.name as string;
		//
		// 	state.form[name as keyof TypeForm] = action?.payload?.value as string;
		// },
	},
});

export const {
	sendForm,
	sendFormError,
	sendFormSuccess,
	// handlerChangeForm,
} = requestFormSlice.actions;

export default requestFormSlice.reducer;
