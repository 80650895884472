import {
  ApisauceInstance
} from 'apisauce';

// Url
import { url } from '../api.url';

const downloadFile = (api: ApisauceInstance) => {
  // const download = (id: string) => api.get(`${url.download}/${id}`);
  const downloadLib = (formData: any) => api.get(url.downloadLib({catNumber: formData.catNumber, type: formData.type}), {});

  return {
    downloadLib
    // download,
  };
};

export default downloadFile;
