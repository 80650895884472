import {ApisauceInstance} from 'apisauce';

// Url
import {url} from '../api.url';

// Type
type searchParamType = {
	page?: number,
	size?: number,
	value?: string,
};
type PropsFormDataType = { [index: string]: string | {} };

const search = (api: ApisauceInstance) => {
	// Name search
	const search = (formData: searchParamType) =>
		api.get(`${url.search}?query=${formData.value}&page=${formData.page}&size=${formData.size}`);

	const searchLib = (formData: searchParamType) =>
		api.get(`${url.searchLib}?query=${formData.value}&page=${formData.page}&size=${formData.size}`);

	// Structure search
	const compoundsSimilarity = (formData: any) => api.post(`${url.compoundsSimilarity}?page=${formData.urlParam.number}&size=${formData.urlParam.size}`, formData.data || {});
	const compoundsSubstructure = (formData: any) => api.post(`${url.compoundsSubstructure}?page=${formData.urlParam.number}&size=${formData.urlParam.size}`, formData.data || {});
	const compoundsFullfragment = (formData: any) => api.post(`${url.compoundsFullfragment}?page=${formData.urlParam.number}&size=${formData.urlParam.size}`, formData.data || {});

	const linkersSimilarity = (formData: any) => api.post(`${url.linkersSimilarity}?page=${formData.urlParam.number}&size=${formData.urlParam.size}`, formData.data || {});
	const linkersFullfragment = (formData: any) => api.post(`${url.linkersFullfragment}?page=${formData.urlParam.number}&size=${formData.urlParam.size}`, formData.data || {});
	const linkersSubstructure = (formData: any) => api.post(`${url.linkersSubstructure}?page=${formData.urlParam.number}&size=${formData.urlParam.size}`, formData.data || {});

	const chemutilsConvertStructure = (formData: PropsFormDataType) => api.post(`${url.chemutilsConvertStructure}`, formData || {});

	// Bio search
	const getThesaurusById = ({id}: any) => api.get(`${url.getThesaurusById}?rootFamily=${id}`);
	const searchTesaurus = (formData: string) => api.get(`${url.searchTesaurus}?searchText=${Object.values(formData).length ? formData : ''}`);
	const getBaDataTargets = (formData: any) => api.get(`${url.getBaDataTargets}?minPx=${formData?.px}&targetIds=${formData?.targetIds}`);

	// Target details
	const getTargetDetailsById = (id: number) => api.get(`${url.getTargetDetailsById}${id}`,);

	return {
		search,
		searchLib,

		linkersSimilarity,
		linkersFullfragment,
		linkersSubstructure,

		compoundsSimilarity,
		compoundsFullfragment,
		compoundsSubstructure,

		chemutilsConvertStructure,

		searchTesaurus,
		getThesaurusById,
		getBaDataTargets,

		getTargetDetailsById
	};
};

export default search;
