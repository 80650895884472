// @ts-nocheck
import React, {memo, useEffect, useState} from "react";

// ClassNames
import clsx from "clsx";

// Components
import Img from "components/Img";

// Type
import {AvatarType} from './type';

const DefaultUserView: React.FC<{ [index: string]: any }> = (props) => {
	const {
		name,
		title,
		email,
		avatar,
		isMenu,
		lastName,
		isShowName
	} = props
	const [result, setResult] = useState<string | ArrayBuffer>({});

	useEffect(() => {
		if(avatar) {
			setResult(URL.createObjectURL(avatar));
		}
	}, [avatar]);

	if(!avatar) return;

	const cnFullName = clsx(
		'wrapper-avatar__name',
		isMenu ? 'wrapper-avatar__menu' : ''
	);

	return <div className="flex flex-ai-center">
		<img
			src={result}
			alt='Avatar user'
			className='avatar'
		/>
		{isShowName &&
      <div className='flex flex-ai-start flex-direction-column'>
				{<p className={cnFullName}>
					{title ? `${title}.` : ''} {lastName} {name ? `${name}` : ''}
				</p>}
				{isMenu &&
          <p className="wrapper-avatar__email">
						{email}
          </p>}
      </div>}
	</div>
};

const NameUserView: React.FC<any> = (props) => {
	const cnName = clsx(
		'wrapper-avatar__avatar flex flex-jc-center flex-ai-center',
		props.name ? 'wrapper-avatar__avatar--active' : ''
	);

	const cnFullName = clsx(
		'wrapper-avatar__name',
		props.isMenu ? 'wrapper-avatar__menu' : ''
	);

	return (
		<div className='flex flex-ai-center'>
			<div className={cnName}>
				{!!props.name && props.name[0].toUpperCase()}
			</div>
			{props.isShowName &&
        <div className='flex flex-ai-start flex-direction-column'>
					{<p className={cnFullName}>
						{props.title ? `${props.title}.` : ''} {props.lastName} {props.name ? `${props.name}` : ''}
					</p>}
					{props.isMenu &&
            <p className="wrapper-avatar__email">
							{props.email}
            </p>}
        </div>}
		</div>
	);
};

const NameUserEmpty: React.FC<any> = (props) => {
	const cn = clsx(
		'wrapper-avatar flex-ai-center flex-jc-center',
		props.className ? props.className : '',
	);

	return (
		<div
			className={cn}
			onClick={props.onClick}
		>
			{props.isClose ?
				<Img isSvg name="iconCross" alt="Avatar" className="wrapper-avatar__cross"/>
				:
				<Img
					isSvg
					name='userSvg'
					alt='Default user avatar'
					className='wrapper-avatar__avatar'
					onError={() => props.setShowAvatar(false)}
				/>}
		</div>
	);
};

const Avatar: React.FC<AvatarType> = (props) => {
	const {name, avatar, title, email, isMenu, isClose, onClick, lastName, className, isShowName} = props;

	const [showAvatar, setShowAvatar] = useState<boolean>(false);

	useEffect(() => {
		setShowAvatar(!!avatar);
	}, [avatar]);

	if (showAvatar) {
		return <DefaultUserView avatar={avatar || ''} {...{
			name,
			title,
			email,
			avatar,
			isMenu,
			lastName,
			isShowName
		}}/>;
	}

	if (name) {
		return <NameUserView {...{name, title, email, isMenu, isShowName, lastName, className}} />;
	}

	return <NameUserEmpty {...{onClick, isClose, className, setShowAvatar}} />;
};

export default memo(Avatar);
