import {
	ApisauceInstance
} from 'apisauce';

// Url
import { url } from '../api.url';

const solubilities = (api: ApisauceInstance) => {
	const getSolubilitiesByEbcId = (id?: string) => api.get(url.getSolubilitiesByEbcId(id || ''));

	return {
		getSolubilitiesByEbcId,
	};
};

export default solubilities;
