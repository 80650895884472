import React from "react";

// Router
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

// Components
import Modal from './components/modals';
import Cookies from './components/cookie';
import Messages from "./components/messages";
import Loader from 'components/ui-kit/loader';
import SmartLoader from 'components/SmartLoader';
import {Header} from './components/layout/header';
import {Footer} from './components/layout/footer';
import PrivateRoute from "./components/PrivateRouter";

// Lib
import AmplitudeInitialTrigger from './lib/amplitude-initial-trigger';

// Pages
const Auth = React.lazy(() =>
	import("./pages/auth").then(({Auth}) => ({default: Auth}))
);

const Terms = React.lazy(() =>
	import("./pages/terms").then(({Terms}) => ({default: Terms}))
);

const Main = React.lazy(() =>
	import("./pages/main").then(({Main}) => ({default: Main}))
);

const Policy = React.lazy(() =>
	import("./pages/policy").then(({Policy}) => ({default: Policy}))
);

const Projects = React.lazy(() =>
	import("./pages/projects").then(({Projects}) => ({default: Projects}))
);

const Account = React.lazy(() =>
	import("./pages/account").then(({Account}) => ({default: Account}))
);

const AboutUs = React.lazy(() =>
	import("./pages/about-us").then(({AboutUs}) => ({default: AboutUs}))
);

const Checkout = React.lazy(() =>
	import("./pages/checkout").then(({Checkout}) => ({default: Checkout}))
);

const NotFound = React.lazy(() =>
	import("./pages/not-found").then(({NotFound}) => ({default: NotFound}))
);

const Libraries = React.lazy(() =>
	import("./pages/libraries").then(({Libraries}) => ({default: Libraries}))
);

const ContactUs = React.lazy(() =>
	import("./pages/contact-us").then(({ContactUs}) => ({default: ContactUs}))
);

const ServicesScreen = React.lazy(() =>
	import("./pages/services").then(({ServicesScreen}) => ({default: ServicesScreen}))
);

const ForgotPassword = React.lazy(() =>
	import("./pages/forgot-password").then(({ForgotPassword}) => ({default: ForgotPassword}))
);

const MoleculeProduct = React.lazy(() =>
	import("./pages/molecule-product").then(({MoleculeProduct}) => ({default: MoleculeProduct}))
);

const SearchScreen = React.lazy(
	() => import("./pages/search").then(({SearchScreen}) => ({default: SearchScreen}))
);

const LibrariesProduct = React.lazy(() =>
	import("./pages/libraries-product").then(({LibrariesProduct}) => ({default: LibrariesProduct}))
);

const RequestForm = React.lazy(() =>
	import("./pages/request-form").then(({RequestForm}) => ({default: RequestForm}))
);

const LibrariesCompounds = React.lazy(() =>
	import("./pages/libraries-compounds").then(({LibrariesCompounds}) => ({default: LibrariesCompounds}))
);

export default (
	<Router>
		<React.Suspense fallback={<Loader type='global'/>}>
			<Modal/>
			<Messages/>
			<Header/>
			<Routes>
				<Route element={<PrivateRoute/>}>
					<Route path="/account" element={<Account/>}/>
					<Route path="/projects" element={<Projects/>}/>
					<Route path="/checkout" element={<Checkout/>}/>
				</Route>

				<Route path="/" element={<Main/>}/>
				<Route path="/auth" element={<Auth/>}/>
				<Route path="/about-us" element={<AboutUs/>}/>
				<Route path="/search" element={<SearchScreen/>}/>
				<Route path="/libraries" element={<Libraries/>}/>
				<Route path="/contact-us" element={<ContactUs/>}/>
				<Route path="/privacy-policy" element={<Policy/>}/>
				<Route path="/terms-of-service" element={<Terms/>}/>
				<Route path="/services" element={<ServicesScreen/>}/>
				<Route path="/request-form" element={<RequestForm/>}/>
				<Route path="/forgot-password" element={<ForgotPassword/>}/>
				<Route path="/molecule-product/:id" element={<MoleculeProduct/>}/>
				<Route path="/libraries-product/:id" element={<LibrariesProduct/>}/>
				<Route path="/libraries-compounds/:id" element={<LibrariesCompounds />} />

				<Route path="*" element={<NotFound/>}/>
			</Routes>
			<Cookies/>
			<SmartLoader />
			<AmplitudeInitialTrigger/>
			<Footer/>
		</React.Suspense>
	</Router>
);