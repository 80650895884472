import {all, call, fork, put, select, take} from "redux-saga/effects";

// Actions
import * as actionsCreator from "./reducer";
import {showMessage} from "reducers/messages";
import * as actionsLoader from "reducers/loader";

// Constants
import {configAddress} from "constans/checkout";

// Sagas
import {billingRequest, shippingRequest} from "./saga-helpers";

// Helper
import {RootState} from "store";

// Type
import {
	ActionAddresse,
	ActionAddresseUpdate,
	ActionSendAddressInitForm,
	ActionSendAddressShipping,
	ActionTypeGetOrderById,
	ApiResponse,
	ListAddressesProps,
	PlaceOrderProps
} from "./type";

// Selector store
function* _select<T>(fn: (state: RootState) => T) {
	const res: T = yield select(fn);
	return res;
}

// Store
export const checkout = (store: RootState) => store.checkout;

// Start Sagas
export function* checkoutSagaStart(api: any, action: any) {
	yield fork(getOrderByIdWatcher, api, action);
	yield fork(getAllAddressWatcher, api, action);
	yield fork(updateAddressesWatcher, api, action);
	yield fork(submitPlaceOrderWatcher, api, action);
	yield fork(deleteAddressesByIdWatcher, api, action);
	yield fork(sendShippingAddressesWatcher, api, action);
	yield fork(sendAddressesInitialFormWatcher, api, action);
}

/**
 *  Get order by id watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getOrderByIdWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionTypeGetOrderById = yield take(actionsCreator.getOrderById.type);

			const res: ApiResponse<any> = yield call(api.request, 'getOrderById', actions.payload);

			if (!res.ok) {
				yield put(actionsCreator.getOrderByIdError());
			} else {
				yield put(actionsCreator.getOrderByIdSuccess(res.data));
			}
		} catch (e) {
			console.log("CATCH ERROR: getMoleculeProductWatcher: ", e)
		}
	}
}

/**
 *  Get all addresses watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* getAllAddressWatcher(api: any, action: any) {
	while (true) {
		try {
			yield take(actionsCreator.getAddress.type);

			const res: ApiResponse<any> = yield call(api.request, 'getAllAddresses');

			if (!res.ok) {
				yield put(actionsCreator.getAddressError());
			} else {
				yield put(actionsCreator.getAddressSuccess(res.data || []));
			}
		} catch (e) {
			console.log("CATCH ERROR in getAllAddressWatcher: ", e)
		}
	}
}

/**
 *  Send addresses shipping watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* sendShippingAddressesWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionSendAddressShipping = yield take(actionsCreator.sendAddressShipping.type);

			const formDataShipping = {
				type: "SHIPPING",
				city: actions?.payload?.cityShipAddress,
				phone: actions?.payload?.phoneShipAddress,
				postalCode: actions?.payload?.zipShipAddress,
				country: actions?.payload?.countryShipAddress,
				addressLine1: actions?.payload.streetShipAddress,
			};

			const res: ApiResponse<any> = yield call(api.request, 'sendAddresses', formDataShipping);

			if (!res.ok) {
				yield put(actionsCreator.sendAddressShippingError());
			} else {
				yield put(actionsCreator.sendAddressShippingSuccess());
				yield put(showMessage({
					type: 'info',
					text: 'Address was added',
				}))
				yield put(actionsCreator.getAddress());
				yield put(actionsCreator.handlerShowForm(false));
			}
		} catch (e) {
			console.log("CATCH ERROR in sendShippingAddressesWatcher: ", e)
		}
	}
}

/**
 *  Update addresses watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* updateAddressesWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionAddresseUpdate = yield take(actionsCreator.updateAddresses.type);

			let formData = {};
			let updatedFormData: ListAddressesProps[] = [];

			const id = actions?.payload?.id;
			const type = actions.payload.type;
			const typeAddress = actions.payload.typeAddress;

			const state = yield* _select(checkout);
			const listAddresses = state.listAddresses || [];
			const defaultObject = {
				city: '',
				phone: '',
				country: '',
				main: false,
				postalCode: '',
				addressLine1: '',
				type: configAddress.shipping,
			};

			const convertData = (array1: ListAddressesProps[], currentObject: ListAddressesProps) => {
				return array1?.map((item: ListAddressesProps) => {
					let obj = {};
					if (item.id === id) {
						obj = {
							id: id,
							type: item.type,
							main: currentObject.main,
							city: currentObject.cityShipAddress || currentObject.city,
							phone: currentObject.phoneShipAddress || currentObject.phone,
							country: currentObject.countryShipAddress || currentObject.country,
							postalCode: currentObject.zipShipAddress || currentObject.postalCode,
							addressLine1: currentObject.streetShipAddress || currentObject.addressLine1,
						}
					} else {
						obj = {
							...item,
							main: false,
						};
					}
					return obj;
				});
			};

			if (type === 'update-form') {
				const updateFormData = actions.payload.updateFormData;
				formData = {
					main: typeAddress === configAddress.shipping,
					city: updateFormData?.cityShipAddress || updateFormData?.city,
					phone: updateFormData?.phoneShipAddress || updateFormData?.phone,
					postalCode: updateFormData?.zipShipAddress || updateFormData?.zip,
					country: updateFormData?.countryShipAddress || updateFormData?.country,
					addressLine1: updateFormData?.streetShipAddress || updateFormData?.streetAddress,
					type: typeAddress === configAddress.shipping ? configAddress.shipping : configAddress.billing || '',
				};
				updatedFormData = convertData(listAddresses, formData);
				if (typeAddress === configAddress.billing) {
					let addressData: any = {};
					const keyAddressLocalStore = 'address';
					const addressLocalStore = localStorage.getItem(keyAddressLocalStore) || '';

					if (addressLocalStore) {
						addressData = JSON.parse(addressLocalStore);
					}
					const formDataBilling = {
						...addressData,
						zip: updateFormData?.zip,
						city: updateFormData?.city,
						phone: updateFormData?.phone,
						country: updateFormData?.country,
						streetAddress: updateFormData?.streetAddress,
					}
					localStorage.setItem(keyAddressLocalStore, JSON.stringify(formDataBilling))
				}
			}

			if (type === 'update-current-project') {
				const result = listAddresses?.length ? listAddresses?.find(
					(x: { [index: string]: string | number | boolean }) => x.id === id
				) : defaultObject;

				formData = {
					main: true,
					city: result?.city || '',
					phone: result?.phone || '',
					country: result?.country || '',
					type: configAddress.shipping || '',
					postalCode: result?.postalCode || '',
					addressLine1: result?.addressLine1 || '',
				};
				updatedFormData = convertData(listAddresses, formData)
			}

			const res: ApiResponse<any> = yield call(api.request, 'updateAddresses', formData, id);

			if (!res.ok) {
				yield put(actionsCreator.updateAddressesError());
			} else {
				yield put(actionsCreator.updateAddressesSuccess(updatedFormData));

				if (type === 'update-form') {
					yield put(showMessage({
						type: 'info',
						text: 'The address was updated',
					}))
				}
			}
		} catch (e) {
			console.log("CATCH ERROR in updateAddressesWatcher: ", e)
		}
	}
}

/**
 *  Delete addresses by ID shipping/billing watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* deleteAddressesByIdWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionAddresse = yield take(actionsCreator.deleteAddressesById.type);

			const id = actions.payload;
			const state = yield* _select(checkout);
			const listAddresses = state.listAddresses || [];
			const result = listAddresses?.length ? listAddresses?.filter(
				(x: ListAddressesProps) => x.id !== id
			) : [];

			const res: ApiResponse<any> = yield call(api.request, 'deleteAddressesById', id);

			if (!res.ok) {
				yield put(actionsCreator.deleteAddressesByIdError());
			} else {
				yield put(actionsCreator.deleteAddressesByIdSuccess(result));
				yield put(showMessage({
					type: 'info',
					text: 'Address was deleted',
				}));
				yield put(actionsCreator.handlerShowForm(false));
				yield put(actionsCreator.handlerChangeCurrentIdShippingAddress(''));
			}
		} catch (e) {
			console.log("CATCH ERROR in deleteAddressesByIdWatcher: ", e)
		}
	}
}

/**
 *  Place order watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
export function* submitPlaceOrderWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: PlaceOrderProps = yield take(actionsCreator.submitPlaceOrder.type);

			const id = actions.payload.id || '';
			const orderId = actions?.payload?.orderId;
			const navigate = actions.payload.navigate;
			const projectId = actions.payload?.projectId;
			const userComment = actions?.payload?.notes || '';

			yield put(actionsLoader.showLoader());

			const formData = {
				shippingAddressId: id,
				userComment: userComment,
			};

			const res: ApiResponse<any> = yield call(api.request, 'updateOrder', formData, orderId);

			if (!res.ok) {
				yield put(actionsCreator.submitPlaceOrderError());
				yield put(actionsLoader.hideLoader());
			} else {
				yield put(actionsCreator.submitPlaceOrderSuccess());

				if (navigate) {
					navigate('/checkout', {
						state: {
							id: orderId,
							screen: 'success',
							projectId: projectId,
						}
					});
				}

				yield put(actionsLoader.hideLoader());
			}
		} catch (e) {
			console.log("CATCH ERROR in submitPlaceOrderWatcher: ", e)
		}
	}
}

/**
 *  Send addresses initial form watcher sagas
 *
 * @param {function} api - api object
 * @param {object} action - action from dispatch
 * @returns
 */
// @ts-ignore
export function* sendAddressesInitialFormWatcher(api: any, action: any) {
	while (true) {
		try {
			const actions: ActionSendAddressInitForm = yield take(actionsCreator.sendAddressInitForm.type);

			const isShipping = actions?.payload?.shipAddress || '';

			const orderId = actions?.payload?.orderId || '';
			const navigate = actions?.payload?.navigate;

			const formDataBilling = {
				type: "BILLING",
				city: actions?.payload?.city || '',
				phone: actions?.payload?.phone || '',
				postalCode: actions?.payload?.zip || '',
				country: actions?.payload?.country || '',
				addressLine1: actions?.payload?.streetAddress || '',
			}

			const formDataShipping = {
				type: "SHIPPING",
				city: actions?.payload?.cityShipAddress || '',
				phone: actions?.payload?.phoneShipAddress || '',
				postalCode: actions?.payload?.zipShipAddress || '',
				country: actions?.payload?.countryShipAddress || '',
				addressLine1: actions?.payload?.streetShipAddress || '',
			}

			yield put(actionsLoader.showLoader());

			if (!isShipping) {
				const [resBilling, resShipping] = yield all([
					call(billingRequest, api, formDataBilling),
					call(shippingRequest, api, formDataShipping),
				]);
				const res = {
					state: 200,
					ok: resBilling.res === resShipping.res,
				}

				if (!res.ok) {
					yield put(actionsCreator.sendAddressInitFormError());
					yield put(actionsLoader.hideLoader());
				} else {
					yield put(actionsCreator.sendAddressInitFormSuccess());
					yield put(actionsCreator.getAddress());
					navigate?.(`/checkout`, {
						state: {
							id: orderId,
							screen: 'confirm',
						}
					});
					yield put(actionsLoader.hideLoader());
				}
			} else {
				const res: ApiResponse<any> = yield call(api.request, 'sendAddresses', formDataBilling);

				if (!res.ok) {
					yield put(actionsCreator.sendAddressInitFormError());
					yield put(actionsLoader.hideLoader());
				} else {
					yield put(actionsCreator.sendAddressInitFormSuccess());
					yield put(actionsCreator.getAddress());

					navigate?.(`/checkout`, {
						state: {
							id: orderId,
							screen: 'confirm',
						}
					});
					yield put(actionsLoader.hideLoader());
				}
			}
		} catch (e) {
			console.log("CATCH ERROR in sendAddressWatcher: ", e)
		}
	}
}

